import React, { createContext, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { CURRENT_APPS, KUBERNETES } from "../helpers/constants";

const SharedPropsContext = createContext();

export const SharedPropsProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [currentApp, setCurrentApp] = useState(
    CURRENT_APPS.find((it) => location.pathname.includes(it.to))
  );
  const [currentGroup, setCurrentGroup] = useState(
    JSON.parse(sessionStorage.getItem("appGroupId"))
  );

  const onSetGroup = (el) => {
    if (el?.id !== currentGroup?.id) {
      setCurrentGroup(el);
      sessionStorage.setItem("appGroupId", JSON.stringify(el));
    } else if (el?.id === currentGroup?.id) {
      setCurrentGroup(null);
      sessionStorage?.removeItem("appGroupId");
    }
  };

  const onSelectApp = (_, value) => {
    setCurrentApp(value);

    if (value?.id !== KUBERNETES) {
      sessionStorage.removeItem("appGroupId");
      setCurrentGroup(null);
    }

    if (value?.to) {
      navigate(value?.to);
    }
  };

  return (
    <SharedPropsContext.Provider
      value={{ currentApp, onSetGroup, onSelectApp, currentGroup }}
    >
      {children}
    </SharedPropsContext.Provider>
  );
};

export const useSharedProps = () => useContext(SharedPropsContext);
