import * as React from "react";

import { Box, Button, Modal } from "@mui/material";

const style = (item) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  ...(item ? { width: { xs: "90vw", md: "50em", lg: "70em" } } : {}),
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  overflow: "auto",
  maxHeight: "95vh",
});

const margins = { pt: 2, px: 4, pb: 3 };

export default function ModalDialog(props) {
  const { open, handleClose, title, content, closeLabel, onSave, saveLabel } =
    props;

  const showSave = saveLabel || false;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={{ ...style(content) }}>
        <Box
          sx={{
            background: "aliceblue",
            position: "sticky",
            top: 0,
            zIndex: 10,
          }}
        >
          <h2
            style={{ padding: "1rem 2rem", margin: 0 }}
            id="child-modal-title"
          >
            {title}
          </h2>
        </Box>
        <Box sx={{ ...margins }}>{content}</Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            gridColumnGap: "1rem",
            position: "sticky",
            bottom: 0,
            background: "#fff",
            padding: "1rem",
            zIndex: 10,
            ...margins,
          }}
        >
          <Button
            sx={{ width: { md: "15em" }, padding: "0.485rem" }}
            variant="outlined"
            onClick={handleClose}
          >
            {closeLabel}
          </Button>
          { 
            showSave && <Button
              sx={{
                marginLeft: "auto",
                width: { md: "15em" },
                padding: "0.485rem",
              }}
              variant="contained"
              onClick={onSave}
            >
              {saveLabel}
            </Button>
          }
        </Box>
      </Box>
    </Modal>
  );
}
