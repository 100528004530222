import React, { useState, useEffect } from "react";

import withErrorHandling from "../../HOC/ErrorHandling";
import { useSharedProps } from "../../HOC/SharedProps";

import {
  Box,
  Button,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CodeIcon from "@mui/icons-material/Code";
import HistoryIcon from "@mui/icons-material/History";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { CopyBlock, dracula } from "react-code-blocks";

import t from "../../helpers/en";

import {
  EDIT_ITEM,
  DELETE_ITEM,
  JSON_ITEM,
  NEW_ITEM,
  ROWS_PARAMS_LOGS,
  PAGE_SIZE,
} from "../../helpers/constants";
import {
  deleteSecrets,
  getGitSecrets,
  newGitSecrets,
  updateGitSecrets,
  getk8sLogs,
} from "../../helpers/utils";
import { filterRecords, changeTxt } from "../../helpers/selectors";
import { styleIconBtn } from "../../GeneralStyles";

import Table from "../../components/Table/Table";
import Modal from "../../components/Modal/Modal";

const AUDIT_LOGS = "AUDIT_LOGS";

const rowsParams = (data) => ({
  header: [t.name, t.username, t.type, t.resourceVersion, t.createdOn],
  body: data,
  bodyCount: data,

  bodyParams: (val) => [
    { value: val?.metadata?.displayName },
    { value: val?.data?.username },
    { value: val?.type },
    { value: val?.metadata?.resourceVersion },
    { value: val?.metadata?.creationTimestamp },
  ],
});

const GitSecrets = (props) => {
  const { showError } = props;

  const [data, setData] = useState({
    data: [],
    filtered: [],
  });
  const [filters, setFilters] = useState({});
  const [form, setForm] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [pressedBtn, setPressedBtn] = useState(null);

  const { currentGroup } = useSharedProps();

  const fetchData = (cb, onError) => {
    getGitSecrets((result, err) => {
      if (!err) {
        result && setData({ data: result, filtered: result });

        cb && cb(result);
      } else {
        onError(err);
      }
    });
  };

  useEffect(() => {
    const callback = (cb) => {
      fetchData(cb, showError);
    };

    setIsLoading(true);
    callback(() => setIsLoading(false));
  }, [showError, currentGroup]);

  const onOpen = (evt, row) => {
    const { id } = evt.target;
    let modalData = {};

    if (id === AUDIT_LOGS) {
      new Promise((resolve) => {
        getk8sLogs(
          {
            objectName: row?.metadata?.name,
            pageNumber: 0,
            pageSize: PAGE_SIZE,
          },
          (result, err) => {
            if (!err) {
              resolve({ ...row, logs: [...(result.content ?? [])] });
            } else {
              showError(err);
            }
          }
        );
      }).then((res) => setForm(res));
    } else {
      modalData = {
        ...row,
        ...(id === EDIT_ITEM
          ? {
              displayName: row?.metadata?.displayName,
              name: row?.metadata?.name,
              resourceVersion: row?.metadata?.resourceVersion,
              username: row?.data?.username,
            }
          : {}),
      };

      setForm(modalData);
    }

    setPressedBtn(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setForm({});
  };

  const onDelete = (evt, row) => {
    deleteSecrets(row.metadata.name, (result) => {
      triggerUpdate();
    });
  };

  const triggerUpdate = () => {
    fetchData((result) => {
      onFilterRecords(result, filters);
    }, showError);
    handleClose();
  };

  const onSave = (action) => {
    action({ ...form }, (result, err) => {
      if (!err) {
        triggerUpdate();
      } else {
        showError(err);
      }
    });
  };

  const onChangeRowsPerPage = (evt) => {
    setRowsPerPage(parseInt(evt.target.value, 10));
    setPage(0);
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onFilterRecords = (result, currentFilters) => {
    const res = filterRecords(result, currentFilters, (item) => [
      item.metadata?.displayName,
    ]);

    setData(res);
  };

  const onKeyPressSearch = (evt) => {
    evt.preventDefault();

    if (evt.key === "Enter") {
      onFilterRecords(data?.data, filters);
      setPage(0);
    }
  };

  const onChangeTxt = (evt) => {
    const result = changeTxt(evt, form);

    setForm(result);
  };

  const onChangeSearchTxt = (evt) => {
    const { value } = evt.target;

    setFilters({ ...filters, searchedValue: value });
  };

  const onClearSearchTxt = () => {
    const copyFilters = { ...filters, searchedValue: null };

    setFilters(copyFilters);
    onFilterRecords(data?.data, copyFilters);
  };

  const onToggleShowPassword = () => {
    let cpy = { ...form };

    cpy.isPwdVisible = !cpy.isPwdVisible;

    setForm(cpy);
  };

  const formElement = () => (
    <Box sx={{ display: "grid", gridRowGap: "1rem" }}>
      <TextField
        id={`outlined-displayName`}
        label={t.name}
        name="displayName"
        value={form?.displayName || ""}
        onChange={onChangeTxt}
        InputProps={{
          readOnly: !!form?.resourceVersion,
        }}
      />
      <TextField
        id={`outlined-username`}
        label={t.username}
        name="username"
        value={form?.username || ""}
        onChange={onChangeTxt}
      />
      <TextField
        id={`outlined-password`}
        label={t.password}
        name="password"
        type={form?.isPwdVisible ? "text" : "password"}
        value={form?.password || ""}
        onChange={onChangeTxt}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={onToggleShowPassword}
                edge="end"
              >
                {form?.isPwdVisible ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );

  let title, content, saveLabel, onConfirmModalAction;

  switch (pressedBtn) {
    case JSON_ITEM:
      title = t.json;
      content = (
        <Box>
          <CopyBlock
            customStyle={{ height: "550px", width: "100%", overflow: "scroll" }}
            text={JSON.stringify(form, null, 4)}
            language={"en"}
            showLineNumbers={true}
            theme={dracula}
          />
        </Box>
      );
      break;
    case NEW_ITEM:
      title = t.create;
      content = formElement();
      onConfirmModalAction = () => onSave(newGitSecrets);
      saveLabel = t.save;
      break;
    case EDIT_ITEM:
      title = t.update;
      content = formElement();
      onConfirmModalAction = () => onSave(updateGitSecrets);
      saveLabel = t.save;
      break;
    case DELETE_ITEM:
      title = t.delete(t.gitSecrets);
      onConfirmModalAction = (e) => onDelete(e, form);
      saveLabel = t.confirm;
      break;
    case AUDIT_LOGS:
      title = t.logs;
      content = (
        <Table
          rows={{
            ...ROWS_PARAMS_LOGS(form.logs ?? []),
            body: form?.logs ?? [],
          }}
          selectedRow={() => false}
          hideTableHeader={true}
        />
      );
      break;
    default:
  }

  return (
    <Box>
      <Button
        sx={{ mb: 2 }}
        variant="contained"
        onClick={(evt) => onOpen(evt, null)}
        id={NEW_ITEM}
        disabled={currentGroup?.id && !currentGroup?.isContributor2Group}
      >
        {t.create}
      </Button>

      <Table
        rows={{
          ...rowsParams(data?.filtered),
          body: data?.filtered?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          ),
        }}
        selectedRow={(item) => item.id === form.id}
        {...{ onChangeSearchTxt, onKeyPressSearch, onClearSearchTxt }}
        searchedValue={filters?.searchedValue}
        {...{
          page,
          rowsPerPage,
          onChangePage,
          onChangeRowsPerPage,
          isLoading,
        }}
        actionBtns={(item) => (
          <>
            <IconButton
              sx={{ ...styleIconBtn, mr: 2 }}
              title={t.json}
              id={JSON_ITEM}
              onClick={(evt) => onOpen(evt, item)}
            >
              <CodeIcon sx={{ pointerEvents: "none" }} />
            </IconButton>
            <IconButton
              sx={{ ...styleIconBtn, mr: 2 }}
              title={t.logs}
              id={AUDIT_LOGS}
              onClick={(evt) => onOpen(evt, item)}
            >
              <HistoryIcon sx={{ pointerEvents: "none" }} />
            </IconButton>
            {!item?.metadata?.labels?.default && (
              <>
                <IconButton
                  sx={{ ...styleIconBtn, mr: 2 }}
                  title={t.update}
                  id={EDIT_ITEM}
                  onClick={(evt) => onOpen(evt, item)}
                  disabled={
                    currentGroup?.id && !currentGroup?.isContributor2Group
                  }
                >
                  <EditIcon sx={{ pointerEvents: "none" }} />
                </IconButton>
                <IconButton
                  sx={styleIconBtn}
                  title={t.destroy}
                  id={DELETE_ITEM}
                  onClick={(evt) => onOpen(evt, item)}
                  disabled={
                    currentGroup?.id && !currentGroup?.isContributor2Group
                  }
                >
                  <DeleteIcon sx={{ pointerEvents: "none" }} />
                </IconButton>
              </>
            )}
          </>
        )}
      />

      <Modal
        {...{ open }}
        handleClose={handleClose}
        title={title}
        closeLabel={t.close}
        onSave={onConfirmModalAction}
        {...{ content, saveLabel }}
      />
    </Box>
  );
};

export default withErrorHandling(GitSecrets);
