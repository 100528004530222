import React, { useState, useEffect, Fragment } from "react";

import withErrorHandling from "../HOC/ErrorHandling";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

import { Box, Button, IconButton, TextField, Divider } from "@mui/material";

import {
  getTemplates,
  createTemplates,
  updateTemplates,
  deleteTemplates,
  getCategories,
  getQuestions,
  updateQuestion,
} from "../helpers/utils";
import {
  filterRecords,
  changeTxt,
  selectOption,
  addAnswerContainer,
  removeAnswerContainer,
  dragEnd,
  changeSubTxt,
  selectMultipleSubOption,
} from "../helpers/selectors";
import t from "../helpers/en";
import {
  INDUSTRIES,
  INITIAL_STATE,
  EDIT_QUESTIONS,
} from "../helpers/constants";

import Table from "../components/Table/Table";
import Modal from "../components/Modal/Modal";
import Select from "../components/Inputs/Select";
import DragDrop from "../components/DragDrop";
import QuestionForm from "../components/QuestionForm";

import { styleIconBtn, styleInputsWrapper } from "../GeneralStyles";

const EDIT_TEMPLATE = "EDIT_TEMPLATE";
const DELETE_TEMPLATE = "DELETE_TEMPLATE";
const ADD_TEMPLATE = "ADD_TEMPLATE";

const Templates = (props) => {
  const { showError } = props;

  const [data, setData] = useState({ all: [], filtered: [] });
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState({});
  const [pressedBtn, setPressedBtn] = useState(null);
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [categories, setCategories] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [collapsible, setCollapsible] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const onOpen = (evt, row) => {
    const { id } = evt.target;
    let modalData;

    if (id === ADD_TEMPLATE) {
      modalData = { ...row, questions: [INITIAL_STATE] };
    } else if (id === EDIT_TEMPLATE) {
      modalData = {
        ...row,
        questions: [
          ...(row?.questions?.length > 0
            ? [...row.questions]
            : [INITIAL_STATE]),
        ],
      };
    } else if (id === DELETE_TEMPLATE) {
      modalData = { ...row };
    } else if (id === EDIT_QUESTIONS) {
      const findQuestion = questions.find((it) => it.id === row.questionId);

      modalData = {
        ...findQuestion,
        answers: [
          ...(findQuestion?.answers?.length > 0
            ? [...findQuestion.answers]
            : [INITIAL_STATE]),
        ],
      };
    }

    setForm(modalData);
    setPressedBtn(id);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setForm({});
  };

  const onChangeTxt = (evt) => {
    const result = changeTxt(evt, form);

    setForm(result);
  };

  const fetchData = (cb, onError) => {
    getTemplates((result, err) => {
      if (!err) {
        setData({ all: result, filtered: result });

        cb && cb(result);
      } else {
        onError(err);
      }
    });
  };

  const onFilterRecords = (result, currentFilters) => {
    const res = filterRecords(result, currentFilters, (item) => [item.name]);

    setData(res);
  };

  useEffect(() => {
    setIsLoading(true);
    getCategories((result, err) => {
      if (!err) {
        setCategories(result);
      } else {
        showError(err);
      }
    });
    getQuestions((result, err) => {
      if (!err) {
        setQuestions(result);
      } else {
        showError(err);
      }
    });
    fetchData(() => setIsLoading(false), showError);
  }, [showError]);

  const rows = {
    header: [t.name, t.industries, t.questions],
    body: data?.filtered,
    bodyCount: data?.filtered,
    bodyParams: (val) => [
      { value: val.name },
      { value: INDUSTRIES.find((it) => it.id === val.industryId)?.name },
      { value: val?.questions?.length, isCollapsible: true },
    ],
    subheader: [t.question, t.category],
    subBody: (val) => val?.questions,
    subbodyParams: (val) => [
      questions.find((it) => val.questionId === it.id)?.question,
      categories.find((it) => val.categoryId === it.id)?.name,
    ],
  };

  const onSave = (evt) => {
    evt.preventDefault();

    const onUpdateResult = (res, err) => {
      if (!err) {
        fetchData((result) => {
          onFilterRecords(result, filters);
        }, showError);
        setOpen(false);
        setForm({});
      } else {
        showError(err);
      }
    };

    if (form.id) {
      if (pressedBtn === EDIT_TEMPLATE) {
        updateTemplates(form, (result, err) => {
          onUpdateResult(result, err);
        });
      } else if (pressedBtn === DELETE_TEMPLATE) {
        deleteTemplates(form, (result, err) => {
          onUpdateResult(result, err);
        });
      } else if (pressedBtn === EDIT_QUESTIONS) {
        updateQuestion(form, (result, err) => {
          if (!err) {
            getQuestions((result) => {
              setQuestions(result);
              setOpen(false);
              setForm({});
            });
          } else {
            showError(err);
          }
        });
      }
    } else {
      createTemplates(form, (result, err) => {
        onUpdateResult(result, err);
      });
    }
  };

  const onChangeSearchTxt = (evt) => {
    const { value } = evt.target;

    setFilters({ ...filters, searchedValue: value });
  };

  const onClearSearchTxt = () => {
    const copyFilters = { ...filters, searchedValue: null };

    setFilters(copyFilters);
    onFilterRecords(data?.all, copyFilters);
  };

  const onKeyPressSearch = (evt) => {
    evt.preventDefault();

    if (evt.key === "Enter") {
      onFilterRecords(data?.all, filters);
      setPage(0);
    }
  };

  const onChangeRowsPerPage = (evt) => {
    setRowsPerPage(parseInt(evt.target.value, 10));
    setPage(0);
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onCollapse = (item) => {
    let copy = { ...collapsible };
    copy[item.id] = !copy[item.id];

    setCollapsible(copy);
  };

  const onSelectOption = (_, value, param) => {
    const result = selectOption(_, value, param, form);
    setForm(result);
  };

  const onSelectMultipleSubOption = (_, value, param, idx, item) => {
    let result = selectMultipleSubOption(
      _,
      value,
      param,
      idx,
      form,
      "questions"
    );

    if (param === "questionId") {
      result.questions[idx] = {
        ...result.questions[idx],
        categoryId: item?.categoryId,
      };
    }

    setForm(result);
  };

  const onAddAnswerContainer = () => {
    const result = addAnswerContainer(form, "questions");
    setForm(result);
  };

  const onRemoveAnswerContainer = (idx) => {
    const result = removeAnswerContainer(form, idx, "questions");
    setForm(result);
  };

  function onDragEnd(result) {
    const res = dragEnd(form, result, "questions");

    if (res) {
      setForm(res);
    }
  }

  const onChangeSubTxt = (evt, idx) => {
    const result = changeSubTxt(evt, form, idx, "answers");
    setForm(result);
  };

  let title, content;

  if (pressedBtn === ADD_TEMPLATE) {
    title = t.add(t.template);
  } else if (pressedBtn === EDIT_TEMPLATE) {
    title = t.edit(t.template);
  } else if (pressedBtn === DELETE_TEMPLATE) {
    title = t.delete(t.template);
  } else if (pressedBtn === EDIT_QUESTIONS) {
    title = t.edit(t.question);
    content = (
      <QuestionForm
        {...{
          categories,
          form,
          onChangeTxt,
          onChangeSubTxt,
          onSelectOption,
          onDragEnd,
          onAddAnswerContainer,
          onRemoveAnswerContainer,
        }}
      />
    );
  }

  if (pressedBtn === ADD_TEMPLATE || pressedBtn === EDIT_TEMPLATE) {
    content = (
      <Box
        sx={{
          display: "grid",
          rowGap: "1rem",
        }}
      >
        {[
          [
            { label: t.name, name: "name" },
            {
              select: true,
              label: t.industry,
              options: INDUSTRIES,
              option: "name",
              param: "industryId",
              key: "id",
            },
          ],
          { label: t.description, name: "description", multiline: true },
        ].map((item, itemIdx) => {
          const input = (currentItem) => (
            <Fragment key={itemIdx}>
              {!currentItem.select ? (
                <TextField
                  id={`outlined-basic-${currentItem.name}`}
                  label={currentItem.label}
                  name={currentItem.name}
                  value={form[currentItem.name] || ""}
                  onChange={onChangeTxt}
                  multiline={currentItem.multiline}
                />
              ) : (
                <Select
                  options={currentItem.options}
                  onChange={(evt, val) =>
                    onSelectOption(
                      evt,
                      val?.[currentItem?.key],
                      currentItem.param
                    )
                  }
                  label={currentItem.label}
                  item={currentItem.option}
                  value={
                    currentItem.options.find(
                      (it) => form?.[currentItem.param] === it[currentItem.key]
                    ) || ""
                  }
                />
              )}
            </Fragment>
          );

          return Array.isArray(item) ? (
            <Box sx={{ ...styleInputsWrapper(item) }} key={itemIdx}>
              {item.map((item2, item2Idx) => (
                <Fragment key={item2Idx}>{input(item2)}</Fragment>
              ))}
            </Box>
          ) : (
            input(item)
          );
        })}
        <Divider />
        <DragDrop
          {...{ onDragEnd }}
          data={form?.questions}
          content={(question, questionIdx) => (
            <Fragment key={questionIdx}>
              {[
                {
                  select: true,
                  label: t.question,
                  options: questions,
                  option: "question",
                  param: "questionId",
                  key: "id",
                },
                {
                  select: true,
                  label: t.category,
                  options: categories,
                  option: "name",
                  param: "categoryId",
                  key: "id",
                },
              ].map((item, itIdx) => (
                <Fragment key={itIdx}>
                  <Select
                    options={item.options}
                    onChange={(evt, val) =>
                      onSelectMultipleSubOption(
                        evt,
                        val?.[item?.key],
                        item.param,
                        questionIdx,
                        val
                      )
                    }
                    label={item.label}
                    item={item.option}
                    value={
                      item.options.find(
                        (it) => question[item.param] === it[item.key]
                      ) || ""
                    }
                  />
                </Fragment>
              ))}
              <IconButton
                onClick={() => onRemoveAnswerContainer(questionIdx)}
                sx={{ ...styleIconBtn }}
              >
                <RemoveIcon sx={{ pointerEvents: "none" }} />
              </IconButton>
            </Fragment>
          )}
          droppableTopContent={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              {t.questions}:
              <IconButton
                onClick={onAddAnswerContainer}
                sx={{
                  ...styleIconBtn,
                  marginLeft: "auto",
                  display: "flex",
                  width: "5em",
                }}
              >
                <AddIcon sx={{ pointerEvents: "none" }} />
              </IconButton>
            </div>
          }
        />
      </Box>
    );
  }

  return (
    <Box>
      <Modal
        {...{ open }}
        onSave={onSave}
        handleClose={onClose}
        {...{ title }}
        closeLabel={t.close}
        saveLabel={t.save}
        {...{ content }}
      />

      <Button
        id={ADD_TEMPLATE}
        sx={{ mb: 2 }}
        variant="contained"
        onClick={onOpen}
      >
        {t.addNew}
      </Button>
      <Table
        rows={{
          ...rows,
          body: data.filtered.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          ),
        }}
        selectedRow={(item) => item.id === form.id}
        actionBtns={(item) => (
          <>
            <IconButton
              sx={{ ...styleIconBtn, mr: 2 }}
              title={t.edit(t.template)}
              id={EDIT_TEMPLATE}
              onClick={(evt) => onOpen(evt, item)}
            >
              <EditIcon sx={{ pointerEvents: "none" }} />
            </IconButton>
            <IconButton
              sx={styleIconBtn}
              title={t.delete(t.template)}
              id={DELETE_TEMPLATE}
              onClick={(evt) => onOpen(evt, item)}
            >
              <DeleteIcon sx={{ pointerEvents: "none" }} />
            </IconButton>
          </>
        )}
        subActionBtns={(item) => (
          <>
            <IconButton
              sx={{ ...styleIconBtn, mr: 2 }}
              title={t.edit(t.question)}
              id={EDIT_QUESTIONS}
              onClick={(evt) => onOpen(evt, item)}
            >
              <EditIcon sx={{ pointerEvents: "none" }} />
            </IconButton>
          </>
        )}
        {...{ onChangeSearchTxt, onKeyPressSearch, onClearSearchTxt }}
        searchedValue={filters?.searchedValue}
        {...{
          page,
          rowsPerPage,
          onChangePage,
          onChangeRowsPerPage,
          collapsible,
          onCollapse,
          isLoading,
        }}
      />
    </Box>
  );
};

export default withErrorHandling(Templates);
