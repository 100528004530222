import React, { Fragment, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  ListItemSecondaryAction,
  Collapse,
  Divider,
  List,
  ListItem,
  IconButton,
} from "@mui/material";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const styleListItemBtn = {
  "&.active": {
    background: (theme) => theme.palette.primary.main,
    color: (theme) => theme.palette.primary.text,
  },
  "&.active .MuiListItemIcon-root": {
    color: "#fff",
  },
};

export default function NavItems(props) {
  const { openedSideMenu, navItems } = props;

  const location = useLocation();
  const [open, setOpen] = useState(false);
  const onOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      {navItems.map((item, itemIdx) => (
        <Fragment key={itemIdx}>
          <ListItem sx={{ pl: 0, pr: 0 }} key={itemIdx}>
            <ListItemButton
              component={NavLink}
              sx={location.pathname === item.to ? styleListItemBtn : undefined}
              to={item.to}
              key={itemIdx}
            >
              {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
              <ListItemText primary={item.label} />
            </ListItemButton>
            {item.subItems && (
              <ListItemSecondaryAction>
                <IconButton
                  sx={{
                    color: () =>
                      item.subItems &&
                      location.pathname.includes(item.to) &&
                      "#fff",
                    right: 0,
                    ...(!openedSideMenu ? { marginRight: "-1rem" } : {}),
                  }}
                  onClick={onOpen}
                >
                  {open ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </ListItem>
          {(item.subItems || item.hasDivider) && <Divider />}
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List sx={{ pl: 4 }} component="div" disablePadding>
              {item.subItems?.map((item, itemIdx) => (
                <ListItemButton
                  component={NavLink}
                  sx={styleListItemBtn}
                  to={item.to}
                  key={itemIdx}
                >
                  {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                  <ListItemText primary={item.label} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </Fragment>
      ))}
    </>
  );
}
