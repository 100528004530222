import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Collapse,
  Box,
} from "@mui/material";

export default function CollapsibleTable(props) {
  const { data, open, subheader, subbody, subActionBtns } = props;

  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1.5 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {subheader.map((row, rowIdx) => (
                    <TableCell key={rowIdx} sx={{ fontWeight: 600 }}>
                      {row}
                    </TableCell>
                  ))}
                  {subActionBtns && <TableCell />}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, rowIdx) => (
                  <TableRow key={rowIdx}>
                    {subbody(row).map((item, itemIdx) => (
                      <TableCell key={itemIdx} component="th" scope="row">
                        {item}
                      </TableCell>
                    ))}
                    {subActionBtns && (
                      <TableCell>{subActionBtns(row)}</TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
}
