import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import withErrorHandling from "../../HOC/ErrorHandling";

import { CopyBlock, dracula } from "react-code-blocks";

import CodeIcon from "@mui/icons-material/Code";

import { ReactComponent as Copy } from "../../assets/imgs/copy.svg";
import { ReactComponent as Copied } from "../../assets/imgs/copied.svg";

import { Box, IconButton } from "@mui/material";

import t from "../../helpers/en";

import { getBuilds, getImages } from "../../helpers/utils";
import { filterRecords } from "../../helpers/selectors";

import Table from "../../components/Table/Table";
import Modal from "../../components/Modal/Modal";

import { styleIconBtn, styleEllipsis } from "../../GeneralStyles";

const rowsParams = (data) => ({
  header: [
    t.name,
    t.buildNum,
    t.latestBuildReason,
    t.latestImage,
    t.status,
    t.createdOn,
  ],
  body: data,
  bodyCount: data,
  bodyParams: (val) => [
    {
      value: val?.metadata?.displayName ?? val?.metadata?.name,
    },
    { value: val?.metadata?.labels?.["image.kpack.io/buildNumber"] },
    { value: val?.metadata?.annotations?.["image.kpack.io/reason"] },
    {
      value: val?.status?.latestImage,
      hasToolip: true,
      style: { maxWidth: "10em" },
      specialDetails: {
        hasTooltip: true,
        ellipsis: { maxWidth: "10em", ...styleEllipsis },
      },
    },
    {
      value: val?.status?.conditions?.[0]?.status,
    },
    {
      value: val?.metadata?.creationTimestamp,
    },
  ],
});

const ImageDetails = (props) => {
  const { showError } = props;

  const [data, setData] = useState({
    data: [],
    filtered: [],
  });
  const [form, setForm] = useState({});
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [copied, setCopied] = useState({});

  const { id } = useParams();

  const fetchData = (cb, onError, param) => {
    const data1 = new Promise((resolve, reject) => {
      getBuilds({ imageName: param }, (result, err) => {
        if (!err) {
          resolve(result);
        } else {
          reject(err);
        }
      });
    });
    const data2 = new Promise((resolve, reject) => {
      getImages({ name: param }, (result, err) => {
        if (!err) {
          resolve(result);
        } else {
          reject(err);
        }
      });
    });

    Promise.all([data1, data2])
      .then(([result1, result2]) => {
        setData({ data: result1, filtered: result1, parent: result2 });

        cb && cb(result1);
      })
      .catch((err) => {
        onError(err);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    const callback = (cb) => {
      fetchData(cb, showError, id);
    };

    callback(() => setIsLoading(false));
  }, [showError, id]);

  const onChangeRowsPerPage = (evt) => {
    setRowsPerPage(parseInt(evt.target.value, 10));
    setPage(0);
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onFilterRecords = (result, currentFilters) => {
    const res = filterRecords(result, currentFilters, (item) => [
      item.metadata?.displayName,
    ]);

    setData(res);
  };

  const onKeyPressSearch = (evt) => {
    evt.preventDefault();

    if (evt.key === "Enter") {
      onFilterRecords(data?.data, filters);
      setPage(0);
    }
  };

  const onChangeSearchTxt = (evt) => {
    const { value } = evt.target;

    setFilters({ ...filters, searchedValue: value });
  };

  const onClearSearchTxt = () => {
    const copyFilters = { ...filters, searchedValue: null };

    setFilters(copyFilters);
    onFilterRecords(data?.data, copyFilters);
  };

  const onOpen = (evt, row) => {
    let modalData = {};

    modalData = { ...row };

    setForm(modalData);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setForm({});
  };

  const copy2Clipboard = async (el) => {
    try {
      await navigator.clipboard.writeText(el.value);
      setCopied((prevState) => ({
        ...prevState,
        ...el,
        status: true,
      }));
    } catch {}
  };

  return (
    <Box>
      <Modal
        {...{ open }}
        handleClose={handleClose}
        title={t.json}
        closeLabel={t.close}
        content={
          <Box>
            <CopyBlock
              customStyle={{
                height: "550px",
                width: "100%",
                overflow: "scroll",
              }}
              text={JSON.stringify(form, null, 4)}
              language={"en"}
              showLineNumbers={true}
              theme={dracula}
            />
          </Box>
        }
      />

      <Box
        style={{
          boxShadow:
            "0px 2px 10px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
          display: "grid",
          gap: "0.5rem",
          padding: "0.5rem",
          margin: "0 0 1rem 0",
        }}
      >
        {[
          { label: t.name, value: data?.parent?.metadata?.displayName },
          {
            label: t.latestImage,
            value: data?.parent?.status?.latestImage,
            specificDetails: { hasCopy: true },
          },
          {
            label: t.url,
            value: data?.parent?.spec?.source?.git?.url,
            specificDetails: { hasCopy: true, hasUrl: true },
          },
          {
            label: t.status,
            value: data?.parent?.status?.conditions?.[0]?.status,
          },
        ].map((el, elIdx) => (
          <span key={elIdx}>
            <span
              style={{
                opacity: "0.5",
                fontWeight: "500",
                marginRight: "0.2rem",
              }}
            >
              {el.label}:
            </span>
            <span>
              {!el.specificDetails?.hasUrl ? (
                el.value
              ) : (
                <a href={el.value} target="_blank" rel="noopener noreferrer">
                  {el.value}
                </a>
              )}
            </span>
            <IconButton
              onClick={() =>
                copy2Clipboard({
                  value: el?.value,
                  elIdx,
                })
              }
            >
              {el.specificDetails?.hasCopy &&
                (copied.status && copied.elIdx === elIdx ? (
                  <Copied />
                ) : (
                  <Copy />
                ))}
            </IconButton>
          </span>
        ))}
      </Box>
      <Table
        rows={{
          ...rowsParams(data?.filtered),
          body: data?.filtered?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          ),
        }}
        selectedRow={(item) => false}
        {...{ onChangeSearchTxt, onKeyPressSearch, onClearSearchTxt }}
        searchedValue={filters?.searchedValue}
        {...{
          page,
          rowsPerPage,
          onChangePage,
          onChangeRowsPerPage,
          isLoading,
        }}
        actionBtns={(item) => (
          <>
            <IconButton
              sx={{ ...styleIconBtn, mr: 2 }}
              title={t.json}
              onClick={(evt) => onOpen(evt, item)}
            >
              <CodeIcon sx={{ pointerEvents: "none" }} />
            </IconButton>
          </>
        )}
      />
    </Box>
  );
};

export default withErrorHandling(ImageDetails);
