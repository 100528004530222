import React, { useState, useEffect } from "react";

import withErrorHandling from "../../HOC/ErrorHandling";
import { useSharedProps } from "../../HOC/SharedProps";

import { Box, Skeleton, Button, IconButton, TextField } from "@mui/material";

import { Delete as DeleteIcon, Info as InfoIcon } from "@mui/icons-material";
import CodeIcon from "@mui/icons-material/Code";
import HistoryIcon from "@mui/icons-material/History";

import { CopyBlock, dracula } from "react-code-blocks";

import t from "../../helpers/en";

import {
  DELETE_ITEM,
  JSON_ITEM,
  NEW_ITEM,
  ROWS_PARAMS_LOGS,
  PAGE_SIZE,
  JSON_ADD_VARIABLES,
} from "../../helpers/constants";
import {
  deleteService,
  getServices,
  newService,
  getk8sLogs,
  getServiceAccounts,
  getConfigsAndSecrets,
  getImages,
  getSecret,
  getMetrics,
} from "../../helpers/utils";
import {
  filterRecords,
  changeTxt,
  changeSubTxt,
  convert2MB,
  configsAndSecretsShowPwd,
  setConfigsAndSecrets,
  selectOption,
  setJsonVariable2Table,
} from "../../helpers/selectors";
import { styleIconBtn } from "../../GeneralStyles";

import Select from "../../components/Inputs/Select";
import Table from "../../components/Table/Table";
import Modal from "../../components/Modal/Modal";
import SetConfigsAndSecrets from "../../components/SetConfigsAndSecrets";

const AUDIT_LOGS = "AUDIT_LOGS";
const SERVICE_METRICS = "SERVICE_METRICS";
const INITIAL_STATE_FORM = {
  variables: [{ key: null, value: null }],
  secrets: [{ key: null, value: null, isPwdVisible: false }],
  configs_values: [],
};

const rowsParams = (data) => ({
  header: [t.name, t.generation, t.status, t.resourceVersion, t.createdOn],
  body: data,
  bodyCount: data,

  bodyParams: (val) => [
    { value: val?.metadata?.name },
    { value: val?.metadata?.generation },
    { value: val?.status?.conditions?.[0]?.status },
    { value: val?.metadata?.resourceVersion },
    { value: val?.metadata?.creationTimestamp },
  ],
});

const Services = (props) => {
  const { showError } = props;

  const [data, setData] = useState({
    data: [],
    filtered: [],
  });
  const [filters, setFilters] = useState({});
  const [form, setForm] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [imagesList, setImagesList] = useState([]);
  const [serviceAccounts, setServiceAccounts] = useState([]);
  const [configs, setConfigs] = useState([]);
  const [pressedBtn, setPressedBtn] = useState(null);

  const { currentGroup } = useSharedProps();

  const fetchData = (cb, onError, setFuntion) => {
    getServices((result, err) => {
      if (!err) {
        setData({ data: result, filtered: result });

        cb && cb(result);
      } else {
        onError(err);
      }

      setFuntion && setFuntion();
    });
  };

  useEffect(() => {
    setIsLoading(true);

    const callback = (cb) => {
      fetchData(null, showError, cb);

      getServiceAccounts((result, err) => {
        if (Array.isArray(result)) {
          const updateResult = result.map((it) => ({
            displayName: it?.metadata?.displayName,
            name: it?.metadata?.name,
          }));
          setServiceAccounts(updateResult);
        } else {
          showError(err);
        }
      });

      getConfigsAndSecrets((result, err) => {
        if (Array.isArray(result)) {
          const updateResult = result.map((it) => {
            return {
              displayName: it?.displayName,
              name: it?.name,
            };
          });

          setConfigs(updateResult);
        } else {
          showError(err);
        }
      });

      getImages(null, (result, err) => {
        if (Array.isArray(result)) {
          const updateResult = result.map((it) => ({
            displayName: it?.metadata?.displayName,
            name: it?.status?.latestImage,
          }));
          setImagesList(updateResult);
        } else {
          showError(err);
        }
      });
    };

    callback(() => setIsLoading(false));
  }, [showError, currentGroup]);

  useEffect(() => {
    let interval;

    if (form?.targetID === SERVICE_METRICS) {
      interval = setInterval(() => {
        getMetrics({ name: form?.metadata?.name }, (result, err) => {
          if (result) {
            let cpy = { ...form };
            cpy.metrics = {
              ...result,
              cpu: `${Math.round((result.cpu + Number.EPSILON) * 100) / 100}%`,
              memory: `${convert2MB(result.memory)} MB`,
              isLoading: false,
            };

            setForm(cpy);
          }
        });
      }, 5000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [form, currentGroup]);

  const onOpen = (evt, row) => {
    const { id } = evt.target;
    let modalData = {};

    if (id === AUDIT_LOGS) {
      new Promise((resolve) => {
        getk8sLogs(
          {
            objectName: row?.metadata?.name,
            pageNumber: 0,
            pageSize: PAGE_SIZE,
          },
          (result) => {
            resolve({ ...row, logs: [...(result.content ?? [])] });
          }
        );
      }).then((res) => setForm(res));
    } else {
      modalData = {
        ...row,
        tabVal: 0,
        ...INITIAL_STATE_FORM,
        targetID: id,
        metrics: { isLoading: true },
      };

      setForm(modalData);
    }

    setPressedBtn(id);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setPressedBtn(null);
    setForm({});
  };

  const onDelete = (evt, row) => {
    deleteService(row.metadata.name, (result, err) => {
      if (!err) {
        triggerUpdate();
      } else {
        showError(err);
      }
    });
  };

  const triggerUpdate = () => {
    fetchData((result) => {
      onFilterRecords(result, filters);
    }, showError);
    onClose();
  };

  const onChangeRowsPerPage = (evt) => {
    setRowsPerPage(parseInt(evt.target.value, 10));
    setPage(0);
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onFilterRecords = (result, currentFilters) => {
    const res = filterRecords(result, currentFilters, (item) => [
      item.metadata?.name,
    ]);

    setData(res);
  };

  const onKeyPressSearch = (evt) => {
    evt.preventDefault();

    if (evt.key === "Enter") {
      onFilterRecords(data?.data, filters);
      setPage(0);
    }
  };

  const onChangeSearchTxt = (evt) => {
    const { value } = evt.target;

    setFilters({ ...filters, searchedValue: value });
  };

  const onClearSearchTxt = () => {
    const copyFilters = { ...filters, searchedValue: null };

    setFilters(copyFilters);
    onFilterRecords(data?.data, copyFilters);
  };

  const onSelectOption = (_, value, param) => {
    let result = selectOption(_, value, param, form);

    setForm(result);
  };

  const onSave = (action) => {
    action(form, (result, err) => {
      if (!err) {
        triggerUpdate();
      } else {
        showError(err);
      }
    });
  };

  const onChangeTxt = (evt) => {
    const result = changeTxt(evt, form);

    setForm(result);
  };

  const onChangeSubTxt = (evt, val, valIdx, param) => {
    const result = changeSubTxt(evt, form, valIdx, param);

    setForm(result);
  };

  const onSelectMultipleOptions = (event, values, param) => {
    let cpy = [...(form?.[param] ?? [])];
    cpy = values;
    setForm({ ...form, [param]: cpy });
  };

  const onOpenSecond = (evt, param) => {
    const { id } = evt.target;
    let cpy = { ...form };
    const envParams = form?.[param]?.reduce((acc, currentVal) => {
      return {
        ...acc,
        ...(currentVal.key ? { [currentVal.key]: currentVal.value } : {}),
      };
    }, {});

    cpy = {
      ...cpy,
      secondModal: {
        open: true,
        ...(id === JSON_ADD_VARIABLES
          ? { value: JSON.stringify(envParams, null, 2) }
          : {}),
      },
    };

    setForm(cpy);
  };

  const onHideModalSecond = () => {
    let cpy = { ...form };

    cpy.secondModal = null;

    setForm(cpy);
  };

  const onChangeTab = (_, val) => {
    setForm((prev) => ({ ...prev, tabVal: val }));
  };

  const onShowPwd = async (e, elem) => {
    const result = await configsAndSecretsShowPwd(e, {
      ...elem,
      showError,
      getSecret,
    });

    setForm(result);
  };

  const onSetConfigsAndSecrets = (evt, elem) => {
    const result = setConfigsAndSecrets(evt, elem);

    setForm(result);
  };

  const onChangeTxtJson = (e) => {
    const value = e.target.value;

    setForm((prev) => ({
      ...prev,
      ...{ secondModal: { ...prev.secondModal, value } },
    }));
  };

  const onSetJsonVariable2Table = () => {
    const result = setJsonVariable2Table(form);

    setForm(result);
  };

  let title, content, saveLabel, onConfirmModalAction;

  const formElement = () => (
    <Box sx={{ display: "grid", gridRowGap: "1rem" }}>
      <TextField
        id={`outlined-basic-displayName`}
        label={t.name}
        name="displayName"
        value={form?.displayName || ""}
        onChange={onChangeTxt}
        InputProps={{
          readOnly: !!form?.resourceVersion,
        }}
      />
      <Select
        options={serviceAccounts}
        onChange={(evt, val) => onSelectOption(evt, val?.name, "saName")}
        label={t.serviceAccount}
        item="displayName"
        value={serviceAccounts?.find((it) => it.name === form?.saName) || ""}
      />
      <Select
        options={imagesList}
        onChange={(evt, val) => onSelectOption(evt, val?.name, "image")}
        label={t.image}
        item="displayName"
        value={imagesList?.find((it) => it.name === form?.image) || ""}
      />
      <TextField
        id="outlined-basic-containerPort"
        label={t.port}
        name="containerPort"
        value={form?.containerPort || ""}
        onChange={onChangeTxt}
      />
      <Select
        multiple
        options={configs}
        onChange={(evt, val) =>
          onSelectMultipleOptions(evt, val, "configs_values")
        }
        label={t.configsAndSecrets}
        item="displayName"
        value={form?.configs_values}
      />
      <SetConfigsAndSecrets
        {...{
          form,
          onChangeTab,
          onChangeSubTxt,
          onSetConfigsAndSecrets,
          onOpenSecond,
          onShowPwd,
          onChangeTxtJson,
          onSetJsonVariable2Table,
          onHideModalSecond,
        }}
      />
    </Box>
  );

  switch (pressedBtn) {
    case JSON_ITEM:
      title = t.json;
      content = (
        <Box>
          <CopyBlock
            customStyle={{ height: "550px", width: "100%", overflow: "scroll" }}
            text={JSON.stringify(form, null, 4)}
            language={"en"}
            showLineNumbers={true}
            theme={dracula}
          />
        </Box>
      );
      break;
    case NEW_ITEM:
      title = t.create;
      content = formElement();
      onConfirmModalAction = () => onSave(newService);
      saveLabel = t.save;
      break;
    case DELETE_ITEM:
      title = t.delete(t.service);
      onConfirmModalAction = (e) => onDelete(e, form);
      saveLabel = t.confirm;
      break;
    case AUDIT_LOGS:
      title = t.logs;
      content = (
        <Table
          rows={{
            ...ROWS_PARAMS_LOGS(form.logs ?? []),
            body: form?.logs ?? [],
          }}
          selectedRow={() => false}
          hideTableHeader={true}
        />
      );
      break;
    case SERVICE_METRICS:
      title = t.metrics;
      content = (
        <Box>
          {form.metrics?.isLoading ? (
            <Skeleton variant="text" sx={{ height: "550px", width: "100%" }} />
          ) : (
            <CopyBlock
              customStyle={{
                height: "550px",
                width: "100%",
                overflow: "scroll",
              }}
              text={JSON.stringify(form?.metrics, null, 2)}
              language={"en"}
              showLineNumbers={true}
              theme={dracula}
            />
          )}
        </Box>
      );
      break;
    default:
  }

  return (
    <Box>
      <Button
        sx={{ mb: 2 }}
        variant="contained"
        onClick={(evt) => onOpen(evt, null)}
        id={NEW_ITEM}
        disabled={currentGroup?.id && !currentGroup?.isContributor2Group}
      >
        {t.create}
      </Button>

      <Table
        rows={{
          ...rowsParams(data?.filtered),
          body: data?.filtered?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          ),
        }}
        selectedRow={(item) => item.id === form.id}
        {...{ onChangeSearchTxt, onKeyPressSearch, onClearSearchTxt }}
        searchedValue={filters?.searchedValue}
        {...{
          page,
          rowsPerPage,
          onChangePage,
          onChangeRowsPerPage,
          isLoading,
        }}
        actionBtns={(item) => (
          <>
            <IconButton
              sx={{ ...styleIconBtn, mr: 2 }}
              title={t.metrics}
              id={SERVICE_METRICS}
              onClick={(evt) => onOpen(evt, item)}
            >
              <InfoIcon sx={{ pointerEvents: "none" }} />
            </IconButton>
            <IconButton
              sx={{ ...styleIconBtn, mr: 2 }}
              title={t.json}
              id={JSON_ITEM}
              onClick={(evt) => onOpen(evt, item)}
            >
              <CodeIcon sx={{ pointerEvents: "none" }} />
            </IconButton>
            <IconButton
              sx={{ ...styleIconBtn, mr: 2 }}
              title={t.logs}
              id={AUDIT_LOGS}
              onClick={(evt) => onOpen(evt, item)}
            >
              <HistoryIcon sx={{ pointerEvents: "none" }} />
            </IconButton>
            <IconButton
              sx={styleIconBtn}
              title={t.destroy}
              id={DELETE_ITEM}
              onClick={(evt) => onOpen(evt, item)}
              disabled={currentGroup?.id && !currentGroup?.isContributor2Group}
            >
              <DeleteIcon sx={{ pointerEvents: "none" }} />
            </IconButton>
          </>
        )}
      />
      <Modal
        {...{ open, title, content, saveLabel }}
        closeLabel={t.close}
        onSave={onConfirmModalAction}
        handleClose={onClose}
      />
    </Box>
  );
};

export default withErrorHandling(Services);
