import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const DragDrop = (props) => {
  const { data, onDragEnd, content, droppableTopContent, droppableId } = props;

  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    margin: `0 0 1rem 0`,
    display: "grid",
    gap: "0.5rem",
    gridTemplateColumns: "1fr 1fr 2em",
    alignItems: "center",
    padding: "1rem",
    borderRadius: "0.5rem",
    background: isDragging ? "lightgreen" : "#f5f5f5",
    ...draggableStyle, // styles we need to apply on draggables
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: "2rem",
  });

  return (
    <DragDropContext onDragEnd={(res) => onDragEnd(res)}>
      {!enabled ? null : (
        <Droppable droppableId={`droppable_${droppableId}`}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {droppableTopContent}
              {data?.map(
                (item, itemIdx) =>
                  !item.deleted && (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={itemIdx}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          {content(item, itemIdx)}
                        </div>
                      )}
                    </Draggable>
                  )
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )}
    </DragDropContext>
  );
};

export default DragDrop;
