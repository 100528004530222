import React, { useState } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Link,
  Navigate,
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { googleLogout } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

import t from "./helpers/en";
import {
  KUBERNETES_NAV,
  VPN_NAV,
  HEALTH_CHECKS_NAV,
} from "./helpers/constants";

import CreateConfigurations from "./pages/VPN/CreateConfigurations";
import MainLayout from "./pages/Main/Main";
import MinimalLayout from "./pages/Main/MinimalLayout";
import ErrorPage from "./pages/ErrorPage";
import Reports from "./pages/Reports";
import ReportDetails from "./pages/ReportDetails";
import HealthChecks from "./pages/HealthChecks/HealthChecks";
import Categories from "./pages/Categories";
import Questions from "./pages/Questions";
import Users from "./pages/Users";
import Templates from "./pages/Templates";
import Questionnaire from "./pages/Questionnaire";
import Login from "./pages/Login";
import ConfigureBuild from "./pages/Kubernetes/ConfigureBuild";
import ProtectedRoutes from "./ProtectedRoutes";
import Builds from "./pages/Kubernetes/ViewBuilds";
import Builders from "./pages/Kubernetes/Builders";
import Wizard from "./pages/Kubernetes/Wizard";
import Images from "./pages/Kubernetes/Images";
import ImageDetails from "./pages/Kubernetes/ImageDetails";
import Services from "./pages/Kubernetes/Services";
import ServiceAccounts from "./pages/Kubernetes/ServiceAccounts";
import GitSecrets from "./pages/Kubernetes/GitSecrets";
import Logs from "./pages/Kubernetes/Logs";
import ConfigParams from "./pages/Kubernetes/ConfigParams";
import Registries from "./pages/Kubernetes/Registries";
import ClusterStacks from "./pages/Kubernetes/ClusterStacks";
import ClusterStores from "./pages/Kubernetes/ClusterStores";
import ManageApps from "./pages/Kubernetes/ManageApps";
import ManageDb from "./pages/Kubernetes/ManageDb";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#2995e7fe",
      text: "#fff",
    },
  },
});

export default function App() {
  const [currentUser, setCurrentUser] = useState(
    sessionStorage.getItem("token") &&
      jwtDecode(sessionStorage.getItem("token"))
  );

  const onLoginResponse = (response, status, cb) => {
    if (status === 200) {
      //let`s try parse JWT
      const user = jwtDecode(response);

      sessionStorage.setItem("token", response);
      sessionStorage.setItem("userData", JSON.stringify(user));
      setCurrentUser(user);

      cb && cb();
    } else if (status === 401 || status === 403) {
      onLogout();
    }
  };

  const onLogout = (cb) => {
    googleLogout();
    sessionStorage.clear();
    setCurrentUser(null);

    cb && cb();
  };

  const router = createBrowserRouter([
    {
      path: "",
      errorElement: <ErrorPage />,
      children: [
        {
          path: "",
          element: <ProtectedRoutes />,
          children: [
            {
              path: "/",
              element: <Navigate to="/health-checks" />,
            },
            {
              path: "/health-checks",
              element: (
                <MainLayout
                  navItems={HEALTH_CHECKS_NAV}
                  {...{ onLogout, currentUser }}
                />
              ),
              handle: {
                crumb: () => <Link to="/health-checks">{t.home}</Link>,
              },
              children: [
                {
                  index: true,
                  element: <>{t.home}</>,
                },
                {
                  path: "configure",
                  element: <HealthChecks />,
                  handle: {
                    crumb: () => (
                      <Link to="/health-checks/configure">
                        {t.healthChecks}
                      </Link>
                    ),
                  },
                },
                {
                  path: "reports",
                  element: <Reports />,
                  handle: {
                    crumb: () => (
                      <Link to="/health-checks/reports">{t.reports}</Link>
                    ),
                  },
                },

                {
                  path: "categories",
                  element: <Categories />,
                  handle: {
                    crumb: () => (
                      <Link to="/health-checks/categories">{t.categories}</Link>
                    ),
                  },
                },
                {
                  path: "questions",
                  element: <Questions />,
                  handle: {
                    crumb: () => (
                      <Link to="/health-checks/questions">{t.questions}</Link>
                    ),
                  },
                },
                {
                  path: "templates",
                  element: <Templates />,
                  handle: {
                    crumb: () => (
                      <Link to="/health-checks/templates">{t.templates}</Link>
                    ),
                  },
                },
              ],
            },
            {
              path: "/vpn",
              element: (
                <MainLayout navItems={VPN_NAV} {...{ onLogout, currentUser }} />
              ),
              handle: {
                crumb: () => <Link to="/vpn">{t.home}</Link>,
              },
              children: [
                {
                  index: true,
                  element: <>{t.home}</>,
                },
                {
                  path: "create_configurations",
                  element: <CreateConfigurations />,
                  handle: {
                    crumb: () => (
                      <Link to="/vpn/create_configurations">
                        {t.create} {t.configurations}
                      </Link>
                    ),
                  },
                },
                {
                  path: "view_configurations",
                  element: <>View Configurations</>,
                  handle: {
                    crumb: () => (
                      <Link to="/vpn/view_configurations">
                        {t.view} {t.configurations}
                      </Link>
                    ),
                  },
                },
              ],
            },
            {
              path: "/kubernetes",
              element: (
                <MainLayout
                  navItems={KUBERNETES_NAV}
                  {...{ onLogout, currentUser }}
                />
              ),
              handle: {
                crumb: () => <Link to="/kubernetes">{t.home}</Link>,
              },
              children: [
                {
                  index: "/kubernetes",
                  children: [
                    {
                      index: true,
                      element: <ManageApps />,
                    },
                    {
                      path: "image/:id",
                      element: <ImageDetails />,
                    },
                  ],
                },
                {
                  path: "wizard",
                  element: <Wizard />,
                  handle: {
                    crumb: () => (
                      <Link to="/kubernetes/wizard">{t.wizard}</Link>
                    ),
                  },
                },
                {
                  path: "builders",
                  element: <Builders />,
                  handle: {
                    crumb: () => (
                      <Link to="/kubernetes/builders">{t.builders}</Link>
                    ),
                  },
                },
                {
                  path: "images",
                  element: <Images />,
                  handle: {
                    crumb: () => (
                      <Link to="/kubernetes/images">{t.images}</Link>
                    ),
                  },
                },
                {
                  path: "service-accounts",
                  element: <ServiceAccounts />,
                  handle: {
                    crumb: () => (
                      <Link to="/kubernetes/service-accounts">
                        {t.serviceAccounts}
                      </Link>
                    ),
                  },
                },
                {
                  path: "registries",
                  element: <Registries />,
                  handle: {
                    crumb: () => (
                      <Link to="/kubernetes/registries">{t.registries}</Link>
                    ),
                  },
                },
                {
                  path: "git-secrets",
                  element: <GitSecrets />,
                  handle: {
                    crumb: () => (
                      <Link to="/kubernetes/git-secrets">{t.gitSecrets}</Link>
                    ),
                  },
                },
                {
                  path: "cluster-stacks",
                  element: <ClusterStacks />,
                  handle: {
                    crumb: () => (
                      <Link to="/kubernetes/cluster-stacks">
                        {t.clusterStacks}
                      </Link>
                    ),
                  },
                },
                {
                  path: "cluster-stores",
                  element: <ClusterStores />,
                  handle: {
                    crumb: () => (
                      <Link to="/kubernetes/cluster-stores">
                        {t.clusterStores}
                      </Link>
                    ),
                  },
                },
                {
                  path: "services",
                  element: <Services />,
                  handle: {
                    crumb: () => (
                      <Link to="/kubernetes/services">{t.services}</Link>
                    ),
                  },
                },
                {
                  path: "config",
                  element: <ConfigParams />,
                  handle: {
                    crumb: () => (
                      <Link to="/kubernetes/config">{t.configsAndSecrets}</Link>
                    ),
                  },
                },
                {
                  path: "manage-db",
                  element: <ManageDb />,
                  handle: {
                    crumb: () => (
                      <Link to="/kubernetes/manage-db">
                        {t.manage} {t.database}
                      </Link>
                    ),
                  },
                },
                {
                  path: "builds",
                  element: <Builds />,
                  handle: {
                    crumb: () => <Link to="builds">{t.builds}</Link>,
                  },
                },
                {
                  path: "build/new",
                  element: <ConfigureBuild />,
                  handle: {
                    crumb: () => (
                      <Link to="build/new">
                        {t.create}
                        {t.builds}
                      </Link>
                    ),
                  },
                },
                {
                  path: "logs",
                  element: <Logs />,
                  handle: {
                    crumb: () => <Link to="/kubernetes/logs">{t.logs}</Link>,
                  },
                },
              ],
            },
            {
              path: "/",
              element: <MinimalLayout />,
              children: [
                {
                  path: "questionnaire/:customerQuestionnaireId",
                  element: <Questionnaire />,
                },
                {
                  path: "report-questionnaire/:customerQuestionnaireId",
                  element: <ReportDetails />,
                },
                { path: "/users", element: <Users /> },
              ],
            },
          ],
        },
        // PUBLIC ROUTES
        {
          path: "/login",
          element: (
            <MinimalLayout
              className={{
                width: "fit-content",
                minHeight: "30em",
                display: "flex",
                alignItems: "center",
              }}
            />
          ),
          children: [
            {
              index: true,
              element: <Login {...{ onLoginResponse }} />,
            },
          ],
        },
      ],
    },
  ]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <React.StrictMode>
        <RouterProvider {...{ router }} />
      </React.StrictMode>
    </ThemeProvider>
  );
}
