import React, { useState, useEffect } from "react";

import withErrorHandling from "../HOC/ErrorHandling";

import { Box, Button, IconButton, Tabs, Tab } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  getQuestions,
  getCategories,
  createQuestion,
  updateQuestion,
  deleteQuestion,
  uploadCSV,
  downloadCSV,
} from "../helpers/utils";
import t from "../helpers/en";
import { INITIAL_STATE, EDIT_QUESTIONS } from "../helpers/constants";
import {
  addAnswerContainer,
  removeAnswerContainer,
  dragEnd,
  changeSubTxt,
  changeTxt,
  selectOption,
  filterRecords,
} from "../helpers/selectors";

import Table from "../components/Table/Table";
import Modal from "../components/Modal/Modal";
import Select from "../components/Inputs/Select";
import Dropzone from "../components/Dropzone";

import { styleIconBtn } from "../GeneralStyles";
import QuestionForm from "../components/QuestionForm";

const DELETE_QUESTIONS = "DELETE_QUESTIONS";
const ADD_QUESTIONS = "ADD_QUESTIONS";
const IMPORT_QUESTIONS = "IMPORT_QUESTIONS";

const Questions = (props) => {
  const { showError } = props;

  const [data, setData] = useState({ all: [], filtered: [] });
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState({});
  const [pressedBtn, setPressedBtn] = useState(null);
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tabValue, setTabValue] = useState(0);
  const [collapsible, setCollapsible] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const onOpen = (evt, row) => {
    const { id } = evt.target;
    let modalData = {};

    if (id === ADD_QUESTIONS) {
      modalData = { answers: [INITIAL_STATE] };
    } else if (id === EDIT_QUESTIONS) {
      modalData = {
        ...row,
        answers: [
          ...(row.answers.length > 0 ? [...row.answers] : [INITIAL_STATE]),
        ],
      };
    } else if (id === DELETE_QUESTIONS) {
      modalData = { ...row };
    } else if (id === IMPORT_QUESTIONS) {
      modalData = { categories: [] };
    }

    setForm(modalData);
    setPressedBtn(id);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setForm({});
  };

  const onChangeTxt = (evt) => {
    const result = changeTxt(evt, form);

    setForm(result);
  };

  const onChangeSubTxt = (evt, idx) => {
    const result = changeSubTxt(evt, form, idx, "answers");
    setForm(result);
  };

  const fetchData = (cb, onError) => {
    getQuestions((result, err) => {
      if (Array.isArray(result)) {
        result = result.map((val) => ({
          ...val,
          answers: [
            ...(val.type === "MULTI_SELECT" || val.type === "SINGLE_SELECT"
              ? val.answers
                  .sort((a, b) => {
                    return a.orderPosition - b.orderPosition;
                  })
                  .filter((it) => it.answer && !it.deleted)
              : []),
          ],
        }));

        setData({ all: result, filtered: result });

        cb && cb(result);
      } else {
        onError(err);
      }
    });
  };

  const onFilterRecords = (result, currentFilters) => {
    const res = filterRecords(result, currentFilters, (item) => [
      item.question,
      item.type,
    ]);

    setData(res);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData(() => {
      getCategories((resultCat, err) => {
        setIsLoading(false);
        if (resultCat) {
          setCategories(resultCat);
        } else {
          showError(err);
        }
      });
    }, showError);
  }, [showError]);

  const rows = {
    header: [t.name, t.type, t.categories, t.answers],
    body: data?.filtered,
    bodyCount: data?.filtered,
    bodyParams: (val) => [
      {
        value: val.question,
        style: { maxWidth: "10em", wordWrap: "break-word" },
      },
      { value: val.type },
      { value: categories.find((it) => it.id === val.categoryId)?.name },
      { value: val?.answers?.length, isCollapsible: true },
    ],
    subheader: [t.answers],
    subBody: (val) => val?.answers,
    subbodyParams: (val) => [val.answer],
  };

  const onSave = (evt) => {
    evt.preventDefault();

    const onUpdateResult = (res, err) => {
      if (!err) {
        fetchData((result) => {
          onFilterRecords(result, filters);
        }, showError);
        setOpen(false);
        setForm({});
      } else {
        showError(err);
      }
    };

    if (form.id || pressedBtn !== ADD_QUESTIONS) {
      if (pressedBtn === EDIT_QUESTIONS) {
        updateQuestion(form, (res, err) => {
          onUpdateResult(res, err);
        });
      } else if (pressedBtn === DELETE_QUESTIONS) {
        deleteQuestion(form, (res, err) => {
          onUpdateResult(res, err);
        });
      } else if (pressedBtn === IMPORT_QUESTIONS) {
        uploadCSV(form, (res, err) => {
          onUpdateResult(res, err);
        });
      }
    } else {
      createQuestion(form, (res, err) => {
        onUpdateResult(res, err);
      });
    }
  };

  const onChangeSearchTxt = (evt) => {
    const { value } = evt.target;

    setFilters({ ...filters, searchedValue: value });
  };

  const onClearSearchTxt = () => {
    const copyFilters = { ...filters, searchedValue: null };

    setFilters(copyFilters);
    onFilterRecords(data?.all, copyFilters);
  };

  const onKeyPressSearch = (evt) => {
    evt.preventDefault();

    if (evt.key === "Enter") {
      onFilterRecords(data?.all, filters);
      setPage(0);
    }
  };

  const onChangeRowsPerPage = (evt) => {
    setRowsPerPage(parseInt(evt.target.value, 10));
    setPage(0);
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onSelectOption = (_, value, param) => {
    const result = selectOption(_, value, param, form);
    setForm(result);
  };

  const onAddAnswerContainer = () => {
    const result = addAnswerContainer(form, "answers");
    setForm(result);
  };

  const onRemoveAnswerContainer = (idx) => {
    const result = removeAnswerContainer(form, idx, "answers");
    setForm(result);
  };

  function onDragEnd(result) {
    const res = dragEnd(form, result, "answers");

    if (res) {
      setForm(res);
    }
  }

  const onDrop = (acceptedFiles) => {
    // Ensure only one file is selected
    if (acceptedFiles.length !== 1) {
      return;
    }

    // Check if the selected file is a CSV file
    const file = acceptedFiles[0];
    if (!file.name.endsWith(".csv")) {
      return;
    }

    setForm({ ...form, file });
  };

  const onSelectMultipleOptions = (event, values) => {
    let copyCategories = [...form?.categories];
    copyCategories = values;

    setForm({ ...form, categories: copyCategories });
  };

  const onChangeTab = (_, val) => {
    setTabValue(val);
  };

  const onCollapse = (item) => {
    let cpy = { ...collapsible };
    cpy[item.id] = !cpy[item.id];

    setCollapsible(cpy);
  };

  let title, content;

  if (pressedBtn === ADD_QUESTIONS) {
    title = t.add(t.question);
  } else if (pressedBtn === EDIT_QUESTIONS) {
    title = t.edit(t.question);
  } else if (pressedBtn === DELETE_QUESTIONS) {
    title = t.delete(t.question);
  } else if (pressedBtn === IMPORT_QUESTIONS) {
    title = t.transferData;
    content = (
      <Box>
        <Tabs
          value={tabValue}
          onChange={onChangeTab}
          aria-label="import/export csv tabs"
          sx={{ mb: 2 }}
        >
          {[
            { id: 0, label: t.import },
            { id: 1, label: t.export },
          ].map((tab, tabKey) => (
            <Tab
              key={tabKey}
              label={tab.label}
              id={`simple-tab-${tab.id}`}
              aria-controls={`simple-tabpanel-${tab.id}`}
            />
          ))}
        </Tabs>
        {tabValue !== 0 ? (
          <>
            <Select
              multiple
              options={categories}
              onChange={(evt, val) => onSelectMultipleOptions(evt, val)}
              label={t.categories}
              item="name"
              value={form?.categories}
            />
            <Button
              onClick={() => downloadCSV(form)}
              variant="outlined"
              sx={{ marginTop: "1rem", width: "100%" }}
            >
              {t.downloadFile}
            </Button>{" "}
          </>
        ) : (
          <Dropzone selectedFile={form?.file} {...{ onDrop }} />
        )}
      </Box>
    );
  }

  if (pressedBtn === ADD_QUESTIONS || pressedBtn === EDIT_QUESTIONS) {
    content = (
      <QuestionForm
        {...{
          categories,
          form,
          onChangeTxt,
          onChangeSubTxt,
          onSelectOption,
          onDragEnd,
          onAddAnswerContainer,
          onRemoveAnswerContainer,
        }}
      />
    );
  }

  return (
    <Box>
      <Modal
        {...{ open }}
        onSave={onSave}
        handleClose={onClose}
        {...{ title }}
        closeLabel={t.close}
        saveLabel={t.save}
        {...{ content }}
      />
      <Button
        id={ADD_QUESTIONS}
        sx={{ mb: 2 }}
        variant="contained"
        onClick={onOpen}
      >
        {t.addNew}
      </Button>
      <Button
        id={IMPORT_QUESTIONS}
        sx={{ mb: 2, ml: 1 }}
        variant="outlined"
        onClick={onOpen}
      >
        {t.transferData}
      </Button>
      <Table
        rows={{
          ...rows,
          body: data.filtered.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          ),
        }}
        selectedRow={(item) => item.id === form.id}
        actionBtns={(item) => (
          <Box
            style={{
              display: "flex",
            }}
          >
            <IconButton
              sx={{ ...styleIconBtn, mr: 2 }}
              title={t.edit(t.question)}
              id={EDIT_QUESTIONS}
              onClick={(evt) => onOpen(evt, item)}
            >
              <EditIcon sx={{ pointerEvents: "none" }} />
            </IconButton>
            <IconButton
              sx={styleIconBtn}
              title={t.delete(t.question)}
              id={DELETE_QUESTIONS}
              onClick={(evt) => onOpen(evt, item)}
            >
              <DeleteIcon sx={{ pointerEvents: "none" }} />
            </IconButton>
          </Box>
        )}
        {...{ onChangeSearchTxt, onKeyPressSearch, onClearSearchTxt }}
        searchedValue={filters?.searchedValue}
        {...{
          page,
          rowsPerPage,
          onChangePage,
          onChangeRowsPerPage,
          collapsible,
          onCollapse,
          isLoading,
        }}
      />
    </Box>
  );
};

export default withErrorHandling(Questions);
