import React, { useState } from "react";

import { Box, TextField, Button } from "@mui/material";

import t from "../../helpers/en";

const CreateConfigurations = (props) => {
  const [form, setForm] = useState({
    name: "",
    email: "",
  });

  const onChangeTxt = (evt) => {
    const { name, value } = evt.target;
    let cpyFrom = { ...form };
    cpyFrom[name] = value;

    setForm(cpyFrom);
  };

  return (
    <Box style={{ display: "grid", gridRowGap: "1rem" }}>
      <TextField
        label={t.name}
        name="name"
        value={form.name || ""}
        onChange={onChangeTxt}
      />
      <TextField
        label={t.email}
        name="email"
        value={form.email || ""}
        onChange={onChangeTxt}
      />
      <Button variant="contained" onClick={() => {}}>
        {t.save}
      </Button>
    </Box>
  );
};

export default CreateConfigurations;
