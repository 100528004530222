import { API_URL } from "./constants";

const getHeaders = (body) => {
  var token = sessionStorage.getItem("token");

  let contentType = "application/json";

  if (body instanceof FormData) {
    contentType = null;
  }

  var headers = {
    "Content-Type": contentType,
  };

  if (token) {
    headers = {
      ...headers,
      Authorization: "Bearer " + token,
    };
  }

  return headers;
};

const setSearchParams = (elems) => {
  const params = new URLSearchParams();

  if (elems && typeof elems === "object") {
    for (let key in elems) {
      elems[key] && params.append(key, elems[key]);
    }
  }

  const appGroup = JSON.parse(sessionStorage.getItem("appGroupId"));

  if (appGroup?.id) {
    params.append("appGroupId", appGroup?.id);
  }

  return params.toString();
};

export const GET = (endpoint, params, callback, api) => {
  const searchParamsToString = setSearchParams(params);

  fetch(
    [
      api ? api : API_URL,
      endpoint,
      searchParamsToString ? "?" : "",
      searchParamsToString,
    ].join(""),
    {
      method: "GET",
      headers: getHeaders(null),
    }
  )
    .then((res) => {
      validateResponse(res);

      if (res.headers.get("Content-Type")?.match("application/json")) {
        res.json().then((data) => callback(data, res.status));
      } else if (res.headers.get("Content-Type")?.match("text/plain")) {
        res.text().then((data) => callback(data, res.status));
      } else {
        res.blob().then((data) => callback(data, res.status));
      }
    })
    .catch((error) => callback(error));
};

export const POST = (endPoint, body, callback, api) => {
  const searchParamsToString = setSearchParams();

  fetch(
    (api ? api : API_URL) +
      endPoint +
      (searchParamsToString ? `?${searchParamsToString}` : ""),
    {
      method: "POST",
      headers: getHeaders(body),
      body,
    }
  )
    .then((res) => {
      validateResponse(res);

      var contentType = res.headers.get("Content-Type");

      if (contentType && contentType.includes("application/json")) {
        res.json().then((msg) => callback(msg, res.status));
      } else {
        res.text().then((msg) => callback(msg, res.status));
      }
    })
    .catch((error) => callback(error));
};

export const PUT = (endPoint, body, callback, api) => {
  const searchParamsToString = setSearchParams();

  fetch(
    (api ? api : API_URL) +
      endPoint +
      (searchParamsToString ? `?${searchParamsToString}` : ""),
    {
      method: "PUT",
      headers: getHeaders(body),
      body,
    }
  )
    .then((res) => {
      validateResponse(res);

      try {
        if (JSON.parse(res)) {
          res.json().then((msg) => callback(msg, res.status));
        }
      } catch {
        callback("", res.status);
      }
    })
    .catch((error) => callback(error));
};

export const DELETE = (endPoint, callback, api) => {
  const searchParamsToString = setSearchParams();

  fetch(
    (api ? api : API_URL) +
      endPoint +
      (searchParamsToString ? `?${searchParamsToString}` : ""),
    {
      method: "DELETE",
      headers: getHeaders(null),
    }
  )
    .then((res) => {
      validateResponse(res);

      try {
        if (JSON.parse(res))
          res.json().then((msg) => {
            callback(msg, res.status);
          });
      } catch {
        callback("", res.status);
      }
    })
    .catch((error) => callback(error));
};

const validateResponse = (res) => {
  if (res.status === 401) {
    sessionStorage.removeItem("token");
    window.location.reload(false);
  }
};
