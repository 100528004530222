import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import withErrorHandling from "../HOC/ErrorHandling";

import { IconButton } from "@mui/material";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

import { getCustomers, getQuestionnaire, getTemplates } from "../helpers/utils";
import t from "../helpers/en";
import { filterRecords } from "../helpers/selectors";

import Table from "../components/Table/Table";

import { styleIconBtn } from "../GeneralStyles";

const Reports = (props) => {
  const { showError } = props;

  const [data, setData] = useState({ all: [], filtered: [] });
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [templates, setTemplates] = useState([]);
  const [collapsible, setCollapsible] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const fetchData = useCallback(
    (cb) => {
      const data1 = new Promise((resolve, reject) => {
        getCustomers((result1, errorMsg) => {
          if (!errorMsg) {
            resolve(result1);
          } else {
            reject(errorMsg);
          }
        });
      });
      const data2 = new Promise((resolve, reject) => {
        getQuestionnaire(null, (result2, errorMsg) => {
          if (!errorMsg) {
            resolve(result2);
          } else {
            reject(errorMsg);
          }
        });
      });

      Promise.all([data1, data2])
        .then(([result1, result2]) => {
          let finalCollapsible = {};
          const combinedResult = result1
            ? result1?.map((item) => {
                const findQuestionnaire = result2?.filter(
                  (it) => it.customerId === item.id
                );
                finalCollapsible = {
                  ...finalCollapsible,
                  ...(findQuestionnaire?.length > 0
                    ? { [item.id]: true }
                    : { findQuestionnaire }),
                };

                return {
                  ...item,
                  questionnaires: [...findQuestionnaire],
                };
              })
            : [];

          setData({ all: combinedResult, filtered: combinedResult });
          setCollapsible(finalCollapsible);
          cb && cb(combinedResult);
        })
        .catch((err) => {
          showError(err);
        });
    },
    [showError]
  );

  const onFilterRecords = (result, currentFilters) => {
    const res = filterRecords(result, currentFilters, (item) => [
      item.contactPersonName,
      item.contactPersonEmail,
      item.contactPersonPhoneNumber,
    ]);

    setData(res);
  };

  useEffect(() => {
    setIsLoading(true);

    const data1 = new Promise((resolve, reject) => {
      fetchData((result, errorMsg) => {
        if (!errorMsg) {
          resolve(result);
        } else {
          reject(errorMsg);
        }
      });
    });
    const data2 = new Promise((resolve, reject) => {
      getTemplates((result, errorMsg) => {
        if (!errorMsg) {
          setTemplates(result);
          resolve(result);
        } else {
          reject(errorMsg);
        }
      });
    });

    Promise.all([data1, data2])
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        showError(err);
        setIsLoading(false);
      });
  }, [fetchData, showError]);

  const rows = {
    header: [t.name, t.email, t.phone, t.count],
    body: data?.filtered,
    bodyCount: data?.filtered,
    bodyParams: (val) => [
      { value: val.contactPersonName },
      { value: val.contactPersonEmail },
      { value: val.contactPersonPhoneNumber },
      { value: val?.questionnaires?.length, isCollapsible: true },
    ],
    subheader: [t.healthCheck, t.status],
    subBody: (val) => val?.questionnaires,
    subbodyParams: (val) => [
      templates.find((it) => val.templateId === it.id)?.name,
      t.completed,
    ],
  };

  const onChangeSearchTxt = (evt) => {
    const { value } = evt.target;

    setFilters({ ...filters, searchedValue: value });
  };

  const onClearSearchTxt = () => {
    const copyFilters = { ...filters, searchedValue: null };

    setFilters(copyFilters);
    onFilterRecords(data?.all, copyFilters);
  };

  const onKeyPressSearch = (evt) => {
    evt.preventDefault();

    if (evt.key === "Enter") {
      onFilterRecords(data?.all, filters);
      setPage(0);
    }
  };

  const onChangeRowsPerPage = (evt) => {
    setRowsPerPage(parseInt(evt.target.value, 10));
    setPage(0);
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onCollapse = (item) => {
    let copy = { ...collapsible };
    copy[item.id] = !copy[item.id];

    setCollapsible(copy);
  };

  const onRedirect2Report = (id) => {
    navigate(`/report-questionnaire/${id}`);
  };

  return (
    <Table
      rows={{
        ...rows,
        body: data.filtered.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        ),
      }}
      selectedRow={() => false}
      collapsibleSubTableParamName={"questionnaires"}
      subActionBtns={(item) => (
        <>
          <IconButton
            sx={{ ...styleIconBtn, mr: 2 }}
            title={t.open(t.questionnaire)}
            onClick={() => onRedirect2Report(item?.id)}
          >
            <ArrowRightAltIcon sx={{ pointerEvents: "none" }} />
          </IconButton>
        </>
      )}
      {...{ onChangeSearchTxt, onKeyPressSearch, onClearSearchTxt }}
      searchedValue={filters?.searchedValue}
      {...{
        page,
        rowsPerPage,
        onChangePage,
        onChangeRowsPerPage,
        onCollapse,
        collapsible,
        isLoading,
      }}
    />
  );
};

export default withErrorHandling(Reports);
