import React from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";

const RadioButtonsGroup = (props) => {
  const { onChange, value, data, label, optionValue, specificDetails } = props;

  if (specificDetails?.isIndividual) {
    return (
      <Radio
        {...{ onChange }}
        checked={specificDetails?.checked}
        value={specificDetails?.value}
        name="radio-button"
      />
    );
  }

  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        {...{ value, onChange }}
      >
        {data.map(
          (item, itemIdx) =>
            !item?.deleted && (
              <FormControlLabel
                key={itemIdx}
                value={item[optionValue]}
                control={<Radio />}
                label={item[label]}
              />
            )
        )}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonsGroup;
