import { TEST_ID, BYTES, ADD_VARIABLE, DELETE_VARIABLE } from "./constants";

export const addAnswerContainer = (form, param) => {
  let copy = [...form?.[param]];
  copy.unshift({ id: `TEST_ID${copy.length + 1}` });

  return { ...form, [param]: [...copy] };
};

export const removeAnswerContainer = (form, idx, param) => {
  let copy = [...(form?.[param] ?? [])];

  if (copy?.length > 1) {
    copy.splice(idx, 1);
  } else {
    copy[idx] = { id: TEST_ID }; // To make react-beautiful-dnd work,
    // each element needs an id, so I set the id to dummy new_answer.
  }

  copy = copy.map((it, itIdx) => ({
    ...it,
    ...(!it?.id?.includes(TEST_ID)
      ? { id: it.id }
      : { id: `${TEST_ID}${itIdx + 1}` }),
  }));

  return { ...form, [param]: [...copy] };
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const dragEnd = (form, result, param) => {
  // dropped outside the list
  if (!result.destination) {
    return;
  }

  const items = reorder(
    form?.[param],
    result.source.index,
    result.destination.index
  );

  return { ...form, [param]: items };
};

export const changeTxt = (evt, form) => {
  const { name, value } = evt.target;

  return { ...form, [name]: value };
};

export const changeSubTxt = (evt, form, idx, param) => {
  const { name, value } = evt.target;
  let copy = [...form?.[param]];

  copy[idx] = { ...copy?.[idx], [name]: value };
  return { ...form, [param]: copy };
};

export const selectOption = (_, value, param, form) => {
  return { ...form, [param]: value };
};

export const selectMultipleSubOption = (_, value, name, idx, form, param) => {
  if (value !== null) {
    let copy = [...form?.[param]];

    copy[idx] = { ...copy?.[idx], [name]: value };
    return { ...form, [param]: copy };
  }
};

export const filterRecords = (result, currentFilters, filterByParams) => {
  const len = result?.length;
  let copyFilteredData = [];

  if (currentFilters.searchedValue) {
    for (let i = 0; i < len; i++) {
      const item = result[i];
      const resultBySearch = currentFilters.searchedValue
        ? filterByParams(item).some((val) =>
            val
              ?.toLowerCase()
              .includes(currentFilters.searchedValue.toLowerCase().trim())
          )
        : true;

      if (resultBySearch) {
        copyFilteredData = [...copyFilteredData, item];
      }
    }
  } else {
    copyFilteredData = [...result];
  }

  return (prevProps) => ({ ...prevProps, filtered: copyFilteredData });
};

export const string2Array = (value) => {
  return value
    ?.split(/[\n,]+/)
    .map((it) => it.trim())
    .filter((it) => it.length > 0);
};

export const convert2MB = (val) => {
  if (!!+val) {
    return parseFloat(
      (
        val / Math.pow(BYTES, Math.floor(Math.log(val) / Math.log(BYTES)))
      ).toFixed(1)
    );
  } else {
    return 0;
  }
};

export const setConfigsAndSecrets = (evt, elem) => {
  const { id } = evt.target;
  const { form, item, param } = elem;
  let cpy = {
    ...form,
    variables: [...form.variables],
    secrets: [...form.secrets],
  };

  if (id === ADD_VARIABLE) {
    cpy?.[param].unshift({});
  } else if (id === DELETE_VARIABLE) {
    cpy?.[param].splice(item?.currentItemIdx, 1);
  }

  return cpy;
};

export const configsAndSecretsShowPwd = async (e, elem) => {
  const { form, getSecret, showError, item } = elem;
  let cpy = { ...form };

  if (item?.value?.length > 0) {
    try {
      const result = await new Promise((resolve, reject) => {
        getSecret({ name: cpy.name, key: item.key }, (result, err) => {
          if (err) {
            reject(err);
          } else {
            resolve(result);
          }
        });
      });

      cpy[item.paramName][item.valIdx] = {
        ...cpy[item.paramName][item.valIdx],
        value: result,
        isPwdVisible: true,
      };
    } catch (error) {
      showError(error);
    }
  } else {
    cpy[item.paramName][item.valIdx].isPwdVisible = true;
  }

  return cpy;
};

export const setJsonVariable2Table = (elem) => {
  let cpy = { ...elem };

  try {
    const parsed = JSON.parse(cpy?.secondModal?.value);
    const parsed2KeyValue = Object.entries(parsed)?.map(([key, value]) => {
      return { key, value };
    });

    cpy[cpy?.tabVal === 0 ? "variables" : "secrets"] = parsed2KeyValue;
    cpy.secondModal = null;

    return cpy;
  } catch {
    return elem;
  }
};
