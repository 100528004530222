import React, { useState, useEffect } from "react";

import { CopyBlock, dracula } from "react-code-blocks";

import withErrorHandling from "../../HOC/ErrorHandling";
import { useSharedProps } from "../../HOC/SharedProps";

import EditIcon from "@mui/icons-material/Edit";
import { Delete as DeleteIcon } from "@mui/icons-material";
import CodeIcon from "@mui/icons-material/Code";
import HistoryIcon from "@mui/icons-material/History";

import { Box, Button, IconButton, TextField } from "@mui/material";

import t from "../../helpers/en";

import {
  DELETE_ITEM,
  JSON_ITEM,
  NEW_ITEM,
  ROWS_PARAMS_LOGS,
  PAGE_SIZE,
  EDIT_ITEM,
  JSON_ADD_VARIABLES,
} from "../../helpers/constants";
import {
  deleteConfigsAndSecrets,
  getConfigsAndSecrets,
  neweConfigsAndSecrets,
  updateConfigsAndSecrets,
  getSecret,
  getk8sLogs,
} from "../../helpers/utils";
import {
  filterRecords,
  changeTxt,
  changeSubTxt,
  configsAndSecretsShowPwd,
  setConfigsAndSecrets,
  setJsonVariable2Table,
} from "../../helpers/selectors";

import Table from "../../components/Table/Table";
import Modal from "../../components/Modal/Modal";
import SetConfigsAndSecrets from "../../components/SetConfigsAndSecrets";

import { styleIconBtn } from "../../GeneralStyles";

const AUDIT_LOGS = "AUDIT_LOGS";
const INITIAL_STATE_FORM = {
  variables: [{ key: null, value: null }],
  secrets: [{ key: null, value: null, isPwdVisible: false }],
};

const rowsParams = (data) => ({
  header: [t.name, t.configs, t.secrets],
  body: data,
  bodyCount: data,

  bodyParams: (val) => [
    { value: val?.displayName },
    {
      value: val?.configs[0]?.data && (
        <ul>
          {Object.entries(val?.configs[0]?.data)?.map(([key, value]) => (
            <li key={`configs_${key}`}>
              {key}: {value}
            </li>
          ))}
        </ul>
      ),
    },
    {
      value: val?.secrets[0]?.data && (
        <ul>
          {Object.entries(val?.secrets[0]?.data)?.map(([key]) => (
            <li key={`secrets_${key}`}>{key},</li>
          ))}
        </ul>
      ),
    },
  ],
});

const Config = (props) => {
  const { showError } = props;

  const [data, setData] = useState({
    data: [],
    filtered: [],
  });
  const [filters, setFilters] = useState({});
  const [form, setForm] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [pressedBtn, setPressedBtn] = useState(null);

  const { currentGroup } = useSharedProps();

  const fetchData = (cb, onError, setFuntion) => {
    getConfigsAndSecrets((result, err) => {
      if (!err) {
        setData({ data: result, filtered: result });

        cb && cb(result);
      } else {
        onError(err);
      }

      setFuntion && setFuntion();
    });
  };

  useEffect(() => {
    setIsLoading(true);

    const callback = (cb) => {
      fetchData(null, showError, cb);
    };

    callback(() => setIsLoading(false));
  }, [showError, currentGroup]);

  const onOpen = (evt, row) => {
    const { id } = evt.target;
    let modalData = {};

    if (id === AUDIT_LOGS) {
      new Promise((resolve) => {
        getk8sLogs(
          {
            objectName: row?.name,
            pageNumber: 0,
            pageSize: PAGE_SIZE,
          },
          (result) => {
            resolve({ ...row, logs: [...(result.content ?? [])] });
          }
        );
      }).then((res) => setForm(res));
    } else {
      modalData = {
        ...row,
        tabVal: 0,
        variables:
          row?.configs[0]?.data && Object.keys(row.configs[0]?.data)?.length > 0
            ? Object.entries(row.configs[0]?.data)?.map(([key, value]) => ({
                key,
                value,
              }))
            : INITIAL_STATE_FORM.variables,
        secrets:
          row?.secrets[0]?.data && Object.keys(row.secrets[0]?.data)?.length > 0
            ? Object.entries(row.secrets[0]?.data)?.map(([key, value]) => ({
                key,
                value,
                isPwdVisible: false,
              }))
            : INITIAL_STATE_FORM.secrets,
        name: row?.name,
        displayName: row?.displayName,
        targetID: id,
        metrics: { isLoading: true },
      };

      setForm(modalData);
    }

    setPressedBtn(id);
    setOpen(true);
  };

  const onOpenSecond = (evt, param) => {
    const { id } = evt.target;
    let cpy = { ...form };
    const envParams = form?.[param]?.reduce((acc, currentVal) => {
      return {
        ...acc,
        ...(currentVal.key ? { [currentVal.key]: currentVal.value } : {}),
      };
    }, {});

    cpy = {
      ...cpy,
      secondModal: {
        open: true,
        ...(id === JSON_ADD_VARIABLES
          ? { value: JSON.stringify(envParams, null, 2) }
          : {}),
      },
    };

    setForm(cpy);
  };

  const onHideModalSecond = () => {
    let cpy = { ...form };

    cpy.secondModal = null;

    setForm(cpy);
  };

  const onClose = () => {
    setOpen(false);
    setPressedBtn(null);
    setForm({});
  };

  const onDelete = (evt, row) => {
    deleteConfigsAndSecrets(row.name, (result, err) => {
      if (!err) {
        triggerUpdate();
      } else {
        showError(err);
      }
    });
  };

  const triggerUpdate = () => {
    fetchData((result) => {
      onFilterRecords(result, filters);
    }, showError);
    onClose();
  };

  const onChangeRowsPerPage = (evt) => {
    setRowsPerPage(parseInt(evt.target.value, 10));
    setPage(0);
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onFilterRecords = (result, currentFilters) => {
    const res = filterRecords(result, currentFilters, (item) => [
      item.metadata?.name,
    ]);

    setData(res);
  };

  const onKeyPressSearch = (evt) => {
    evt.preventDefault();

    if (evt.key === "Enter") {
      onFilterRecords(data?.data, filters);
      setPage(0);
    }
  };

  const onChangeSearchTxt = (evt) => {
    const { value } = evt.target;

    setFilters({ ...filters, searchedValue: value });
  };

  const onClearSearchTxt = () => {
    const copyFilters = { ...filters, searchedValue: null };

    setFilters(copyFilters);
    onFilterRecords(data?.data, copyFilters);
  };

  const onSave = (action) => {
    action(form, (result, err) => {
      if (!err) {
        triggerUpdate();
      } else {
        showError(err);
      }
    });
  };

  const onChangeTxt = (evt) => {
    const result = changeTxt(evt, form);

    setForm(result);
  };

  const onChangeSubTxt = (evt, val, valIdx, param) => {
    const result = changeSubTxt(evt, form, valIdx, param);

    setForm(result);
  };

  const onChangeTxtJson = (e) => {
    const value = e.target.value;

    setForm((prev) => ({
      ...prev,
      ...{ secondModal: { ...prev.secondModal, value } },
    }));
  };

  const onSetConfigsAndSecrets = (evt, elem) => {
    const result = setConfigsAndSecrets(evt, elem);

    setForm(result);
  };

  const onSetJsonVariable2Table = () => {
    const result = setJsonVariable2Table(form);

    setForm(result);
  };

  const onChangeTab = (_, val) => {
    setForm((prev) => ({ ...prev, tabVal: val }));
  };

  const onShowPwd = async (e, elem) => {
    const result = await configsAndSecretsShowPwd(e, {
      ...elem,
      showError,
      getSecret,
    });

    setForm(result);
  };

  let title, content, saveLabel, onConfirmModalAction;

  const formElement = () => (
    <Box sx={{ display: "grid", gridRowGap: "1rem" }}>
      <TextField
        id={`outlined-basic-displayName`}
        label={t.name}
        name="displayName"
        value={form?.displayName || ""}
        onChange={onChangeTxt}
        InputProps={{
          readOnly: !!form?.resourceVersion,
        }}
      />
      <SetConfigsAndSecrets
        {...{
          form,
          onChangeTab,
          onChangeSubTxt,
          onSetConfigsAndSecrets,
          onOpenSecond,
          onShowPwd,
          onChangeTxtJson,
          onHideModalSecond,
          onSetJsonVariable2Table,
        }}
      />
    </Box>
  );

  switch (pressedBtn) {
    case JSON_ITEM:
      title = t.json;
      content = (
        <Box>
          <CopyBlock
            customStyle={{ height: "550px", width: "100%", overflow: "scroll" }}
            text={JSON.stringify(form, null, 4)}
            language={"en"}
            showLineNumbers={true}
            theme={dracula}
          />
        </Box>
      );
      break;
    case NEW_ITEM:
      title = t.add(t.configsAndSecrets);
      content = formElement();
      onConfirmModalAction = () => onSave(neweConfigsAndSecrets);
      saveLabel = t.save;
      break;
    case EDIT_ITEM:
      title = t.edit(t.configsAndSecrets);
      content = formElement();
      onConfirmModalAction = () => onSave(updateConfigsAndSecrets);
      saveLabel = t.save;
      break;
    case DELETE_ITEM:
      title = t.delete(t.configsAndSecrets);
      onConfirmModalAction = (e) => onDelete(e, form);
      saveLabel = t.confirm;
      break;
    case AUDIT_LOGS:
      title = t.logs;
      content = (
        <Table
          rows={{
            ...ROWS_PARAMS_LOGS(form.logs ?? []),
            body: form?.logs ?? [],
          }}
          selectedRow={() => false}
          hideTableHeader={true}
        />
      );
      break;
    default:
  }

  return (
    <Box>
      <Button
        sx={{ mb: 2 }}
        variant="contained"
        onClick={(evt) => onOpen(evt, null)}
        id={NEW_ITEM}
        disabled={currentGroup?.id && !currentGroup?.isContributor2Group}
      >
        {t.create}
      </Button>

      <Table
        rows={{
          ...rowsParams(data?.filtered),
          body: data?.filtered?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          ),
        }}
        selectedRow={(item) => item.id === form.id}
        {...{ onChangeSearchTxt, onKeyPressSearch, onClearSearchTxt }}
        searchedValue={filters?.searchedValue}
        {...{
          page,
          rowsPerPage,
          onChangePage,
          onChangeRowsPerPage,
          isLoading,
        }}
        actionBtns={(item) => (
          <>
            {!item?.immutable && (
              <IconButton
                sx={{ ...styleIconBtn, mr: 2 }}
                title={t.edit(t.variable)}
                id={EDIT_ITEM}
                onClick={(evt) => onOpen(evt, item)}
                disabled={
                  currentGroup?.id && !currentGroup?.isContributor2Group
                }
              >
                <EditIcon sx={{ pointerEvents: "none" }} />
              </IconButton>
            )}
            <IconButton
              sx={{ ...styleIconBtn, mr: 2 }}
              title={t.json}
              id={JSON_ITEM}
              onClick={(evt) => onOpen(evt, item)}
            >
              <CodeIcon sx={{ pointerEvents: "none" }} />
            </IconButton>
            <IconButton
              sx={{ ...styleIconBtn, mr: 2 }}
              title={t.logs}
              id={AUDIT_LOGS}
              onClick={(evt) => onOpen(evt, item)}
            >
              <HistoryIcon sx={{ pointerEvents: "none" }} />
            </IconButton>
            <IconButton
              sx={styleIconBtn}
              title={t.destroy}
              id={DELETE_ITEM}
              onClick={(evt) => onOpen(evt, item)}
              disabled={currentGroup?.id && !currentGroup?.isContributor2Group}
            >
              <DeleteIcon sx={{ pointerEvents: "none" }} />
            </IconButton>
          </>
        )}
      />
      <Modal
        {...{ open, title, content, saveLabel }}
        closeLabel={t.close}
        onSave={onConfirmModalAction}
        handleClose={onClose}
      />
    </Box>
  );
};

export default withErrorHandling(Config);
