import React from "react";

import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";

import Footer from "../components/Footer/Footer";

const drawerWidth = 300;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export default function DrawerMain(props) {
  const { open, onToggleDrawer, menuList } = props;

  return (
    <Drawer sx={{ height: "100vh" }} variant="permanent" {...{ open }}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
        style={{ minHeight: "97px" }}
      >
        <img
          style={{
            ...(!open
              ? {
                  transform: "rotate(270deg)",
                  width: "60px",
                  marginRight: "-25px",
                }
              : { margin: "auto" }),
          }}
          src={process.env.PUBLIC_URL + "/logo96.png"}
          alt="logo"
        />
        <IconButton onClick={onToggleDrawer}>
          <KeyboardTabIcon sx={{ transform: open && "rotate(180deg)" }} />
        </IconButton>
      </Toolbar>
      <Divider />
      <List sx={{ overflow: "auto" }} component="nav">
        {menuList}
      </List>
      {open && <Footer style={{ left: "1rem" }} />}
    </Drawer>
  );
}
