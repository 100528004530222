import React, { useState, useCallback } from "react";

import { Snackbar, Alert } from "@mui/material";

const withErrorHandling = (Component) => {
  const Error = (props) => {
    const [error, setError] = useState(false);

    const showError = useCallback((errorMsg) => {
      setError(errorMsg);
    }, []);

    const onClose = () => {
      setError(null);
    };

    return (
      <>
        <Component {...{ ...props, showError }} />
        <Snackbar open={!!error} {...{ onClose }} autoHideDuration={6000}>
          <Alert
            {...{ onClose }}
            severity="warning"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {error}
          </Alert>
        </Snackbar>
      </>
    );
  };

  return Error;
};

export default withErrorHandling;
