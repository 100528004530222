import React, { useState } from "react";
import { Outlet } from "react-router-dom";

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";

import { SharedPropsProvider } from "../../HOC/SharedProps";

import AppBar from "../../components/AppBarMain";
import DrawerMain from "../../components/DrawerMain";
import MainListItems from "./NavItems";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";

export default function Main(props) {
  const { onLogout, currentUser, navItems } = props;

  const [open, setOpen] = useState(true);

  const onToggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <SharedPropsProvider>
      <Box sx={{ display: "flex" }}>
        <AppBar
          {...{
            onLogout,
            currentUser,
            open,
          }}
        />
        <DrawerMain
          {...{ open, onToggleDrawer }}
          menuList={<MainListItems {...{ navItems }} openedSideMenu={open} />}
        />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Breadcrumbs />
          <Container id="main" style={{ marginTop: "1rem", maxWidth: "100%" }}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <Outlet />
            </Paper>
          </Container>
        </Box>
      </Box>
    </SharedPropsProvider>
  );
}
