import t from "./en";

import CategoryIcon from "@mui/icons-material/Category";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ChecklistIcon from "@mui/icons-material/Checklist";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import HomeIcon from "@mui/icons-material/Home";
// import BuildIcon from "@mui/icons-material/Build";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";

const getApiUrl = () => {
  if (process.env.NODE_ENV === "development") {
    return process.env.REACT_APP_API_URL;
  } else {
    return process.env.REACT_APP_API_URL;
  }
};

export const API_URL = getApiUrl();

const getAuthApiUrl = () => {
  if (process.env.NODE_ENV === "development") {
    return process.env.REACT_APP_AUTH_API_URL;
  } else {
    return process.env.REACT_APP_AUTH_API_URL;
  }
};

export const AUTH_API_URL = getAuthApiUrl() + "/auth";

export const USER_API_URL = getAuthApiUrl();

const getK8sApiUrl = () => {
  if (process.env.NODE_ENV === "development") {
    return process.env.REACT_APP_K8S_API_URL;
  } else {
    return process.env.REACT_APP_K8S_API_URL;
  }
};

export const K8S_API_URL = getK8sApiUrl();
export const BYTES = 1024;

export const TEST_ID = "TEST_ID";
export const INITIAL_STATE = { id: TEST_ID }; //used for drag and drop functionality
export const INDUSTRIES = [
  {
    id: "00000000-0000-0000-0000-000000100000",
    name: "Information technology (IT)",
  },
];
export const INPUT_TYPES = [
  { id: "1", name: "SINGLE_SELECT" },
  { id: "2", name: "MULTI_SELECT" },
  { id: "3", name: "SINGLE_LINE_TEXT" },
  { id: "4", name: "MULTI_LINE_TEXT" },
  { id: "5", name: "DATE" },
];

export const USERS_ROLES = [
  { id: "1", name: "SYSTEM" },
  { id: "2", name: "ADMIN" },
  { id: "3", name: "USER" },
  { id: "4", name: "MANAGER" },
];

export const USER_STATUS = [
  { id: "1", name: "Active", enabled: true },
  { id: "2", name: "Inactive", enabled: false },
];

export const ADD_QUESTIONNAIRE = "ADD_QUESTIONNAIRE";
export const EDIT_QUESTIONNAIRE = "EDIT_QUESTIONNAIRE";
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const EDIT_QUESTIONS = "EDIT_QUESTIONS";

export const KUBERNETES_NAV = [
  /*
  {
    label: t.home,
    to: "/kubernetes",
    hasDivider: true,
  },
  {
    label: t.wizard,
    to: "/kubernetes/wizard",
    icon: <BuildIcon sx={{ pointerEvents: "none" }} />,
    hasDivider: true,
  },
  {
    label: t.extendedlWizard,
    to: "/kubernetes/build/new",
    hasDivider: true,
  },
*/
  {
    label: `${t.manageApplications}`,
    to: "/kubernetes",
    icon: <ManageSearchIcon sx={{ pointerEvents: "none" }} />,
    hasDivider: true,
  },
  {
    label: `${t.manage}`,
    icon: <ManageHistoryIcon sx={{ pointerEvents: "none" }} />,
    hasDivider: true,
    subItems: [
      {
        label: `${t.builders}`,
        to: "/kubernetes/builders",
      },
      {
        label: `${t.images}`,
        to: "/kubernetes/images",
      },
      {
        label: `${t.serviceAccounts}`,
        to: "/kubernetes/service-accounts",
      },
      {
        label: `${t.registries}`,
        to: "/kubernetes/registries",
      },
      {
        label: `${t.gitSecrets}`,
        to: "/kubernetes/git-secrets",
      },
      {
        label: `${t.clusterStacks}`,
        to: "/kubernetes/cluster-stacks",
      },
      {
        label: `${t.clusterStores}`,
        to: "/kubernetes/cluster-stores",
      },
      {
        label: `${t.services}`,
        to: "/kubernetes/services",
      },
      {
        label: `${t.configsAndSecrets}`,
        to: "/kubernetes/config",
      },
      {
        label: `${t.manage} ${t.database}`,
        to: "/kubernetes/manage-db",
      },
      {
        label: t.logs,
        to: "/kubernetes/logs",
      },
      {
        label: `${t.builds}`,
        to: "/kubernetes/builds",
      },
    ],
  },
];

export const VPN_NAV = [
  {
    label: t.home,
    to: "/vpn",
    hasDivider: true,
  },
  {
    label: `${t.create} ${t.configurations}`,
    to: "/vpn/create_configurations",
  },
  {
    label: `${t.view} ${t.configurations}`,
    to: "/vpn/view_configurations",
  },
];

export const HEALTH_CHECKS_NAV = [
  {
    label: t.home,
    icon: <HomeIcon />,
    to: "/health-checks",
    hasDivider: true,
  },
  {
    label: t.healthChecks,
    icon: <PeopleIcon />,
    to: "configure",
  },
  {
    label: t.reports,
    icon: <BarChartIcon />,
    to: "reports",
  },
  {
    label: t.admin,
    icon: <AdminPanelSettingsIcon />,
    subItems: [
      {
        label: t.categories,
        icon: <CategoryIcon />,
        to: "categories",
      },
      {
        label: t.questions,
        icon: <QuestionAnswerIcon />,
        to: "questions",
      },
      {
        label: t.templates,
        icon: <ChecklistIcon />,
        to: "templates",
      },
    ],
  },
];

export const PAGE_SIZE = 100;

export const ROWS_PARAMS_LOGS = (data) => ({
  header: [t.name, t.type, t.operation, t.createdBy],
  body: data,
  bodyCount: data,

  bodyParams: (val) => [
    { value: val?.displayName },
    { value: val?.objectType },
    { value: val?.operationType },
    {
      value: val?.createdBy?.familyName
        ? `${val?.createdBy?.familyName} ${val?.createdBy?.givenName}`
        : val?.createdBy?.username,
    },
  ],
});

export const AUDIT_LOGS = "AUDIT_LOGS";
export const EDIT_ITEM = "EDIT_ITEM";
export const DELETE_ITEM = "DELETE_ITEM";
export const JSON_ITEM = "JSON_ITEM";
export const NEW_ITEM = "NEW_ITEM";
export const PRIVATE = "PRIVATE";
export const PRIVATE_REGISTRY = "PRIVATE_REGISTRY";
export const GIT_NEW_CREDENTIALS = "GIT_NEW_CREDENTIALS";
export const REGISTRY_NEW_CREDENTIALS = "REGISTRY_NEW_CREDENTIALS";
export const EDIT_VARIABLE = "EDIT_VARIABLE";
export const DELETE_VARIABLE = "DELETE_VARIABLE";
export const ADD_VARIABLE = "ADD_VARIABLE";
export const FORCE_BUILD = "FORCE_BUILD";
export const JSON_ADD_VARIABLES = "JSON_ADD_VARIABLES";

export const HEALTH_CHECK = "HEALTH_CHECK";
export const KUBERNETES = "KUBERNETES";
export const VPN = "VPN";
export const CURRENT_APPS = [
  { id: HEALTH_CHECK, label: "Health Checks", to: "/health-checks" },
  { id: KUBERNETES, label: "Kubernetes", to: "/kubernetes" },
  { id: VPN, label: "VPN", to: "/vpn" },
];

export const TYPE_DB = [{ id: "POSTGRES" }, { id: "MYSQL" }];
export const SOURCES_DB = [
  { id: "PLATFORM_HOSTED_DB_SERVER" },
  { id: "PLATFORM_HOSTED_CLUSTER" },
];
