import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import withErrorHandling from "../../HOC/ErrorHandling";
import { useSharedProps } from "../../HOC/SharedProps";

import { Box, Button, IconButton, Tab, Tabs } from "@mui/material";

import CodeIcon from "@mui/icons-material/Code";
import HistoryIcon from "@mui/icons-material/History";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

import { CopyBlock, dracula } from "react-code-blocks";

import t from "../../helpers/en";

import {
  JSON_ITEM,
  ROWS_PARAMS_LOGS,
  PAGE_SIZE,
  AUDIT_LOGS,
  FORCE_BUILD,
} from "../../helpers/constants";
import {
  getImages,
  getMetrics,
  getk8sLogs,
  forceRebuild,
} from "../../helpers/utils";
import { filterRecords, convert2MB } from "../../helpers/selectors";

import Table from "../../components/Table/Table";
import Modal from "../../components/Modal/Modal";

import { styleIconBtn, styleEllipsis } from "../../GeneralStyles";
const INITIAL_TABLE_DATA = {
  data: [],
  filtered: [],
};
const rowsParams = (data, tab) => ({
  header:
    tab === 0
      ? [
          t.name,
          t.latestImage,
          t.git,
          t.buildCounter,
          t.latestBuildReason,
          t.status,
          t.version,
          t.createdOn,
        ]
      : [
          t.name,
          t.url,
          t.status,
          t.statusMsg,
          t.createdOn,
          "Pods",
          "CPU (%)",
          "Memory (MB)",
        ],
  body: data,
  bodyCount: data?.length ? data : [],

  bodyParams: (el) => {
    const val = tab === 0 ? el : el?.service;

    return tab === 0
      ? [
          { value: val?.metadata?.displayName },
          {
            value: val?.status?.latestImage,
            specialDetails: {
              hasTooltip: true,
              ellipsis: { maxWidth: "10em", ...styleEllipsis },
            },
          },
          {
            value: val?.spec?.source?.git?.url,
            specialDetails: {
              hasTooltip: true,
              isUrl: true,
              ellipsis: { maxWidth: "10em", ...styleEllipsis },
            },
          },
          { value: val?.status?.buildCounter },
          { value: val?.status?.latestBuildReason },
          { value: val?.status?.conditions?.[0]?.status },
          { value: val?.metadata?.resourceVersion },
          { value: val?.metadata?.creationTimestamp },
        ]
      : [
          {
            value: val?.metadata?.displayName ?? val?.metadata?.name,
          },
          {
            value: val?.status?.url,
            specialDetails: {
              hasTooltip: true,
              isUrl: true,
              ellipsis: { maxWidth: "10em", ...styleEllipsis },
            },
          },
          {
            value: val?.status?.conditions?.[0]?.status,
          },
          {
            value: val?.status?.conditions?.[0]?.message,
            specialDetails: {
              hasTooltip: true,
              ellipsis: { maxWidth: "10em", ...styleEllipsis },
            },
          },
          { value: val?.metadata?.creationTimestamp },
          { value: el?.pods },
          {
            value: el?.cpu
              ? Math.round((el?.cpu + Number.EPSILON) * 100) / 100
              : 0,
          },
          { value: el?.memory ? convert2MB(el?.memory) : 0 },
        ];
  },
});

const ManageApps = (props) => {
  const { showError } = props;

  const [data, setData] = useState(INITIAL_TABLE_DATA);
  const [filters, setFilters] = useState({});
  const [form, setForm] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [pressedBtn, setPressedBtn] = useState(null);
  const [tab, setTab] = useState(0);

  const { currentGroup } = useSharedProps();
  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    setData(INITIAL_TABLE_DATA);
    setTab(newValue);
  };

  const fetchMetrics = (cb, onError) => {
    getMetrics(null, (result, err) => {
      if (!err) {
        setData({ data: result, filtered: result });

        cb && cb(result);
      } else {
        onError(err);
      }
    });
  };

  useEffect(() => {
    const callback = (cb) => {
      if (tab === 0) {
        getImages(null, (result, err) => {
          if (!err) {
            setData({ data: result, filtered: result });

            cb && cb(result);
          } else {
            showError(err);
          }
        });
      } else if (tab === 1) {
        fetchMetrics(cb, showError);
      }
    };

    setIsLoading(true);
    callback(() => setIsLoading(false));
  }, [showError, tab, currentGroup]);

  useEffect(() => {
    let interval;

    if (tab === 1) {
      interval = setInterval(() => {
        fetchMetrics(null, showError);
      }, 5000);
    }

    return () => clearInterval(interval);
  }, [showError, tab, currentGroup]);

  const onOpen = (evt, row) => {
    const { id } = evt.target;
    let modalData = {};

    if (id === AUDIT_LOGS) {
      new Promise((resolve) => {
        getk8sLogs(
          {
            objectName: row?.metadata?.name,
            pageNumber: 0,
            pageSize: PAGE_SIZE,
          },
          (result) => {
            resolve({ ...row, logs: [...(result.content ?? [])] });
          }
        );
      }).then((res) => setForm(res));
    } else if (id === FORCE_BUILD) {
      forceRebuild(row?.metadata, (result, err) => {
        if (err) {
          showError(err);
        }
      });

      return;
    } else {
      modalData = { ...row };

      setForm(modalData);
    }

    setPressedBtn(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setForm({});
  };

  const onChangeRowsPerPage = (evt) => {
    setRowsPerPage(parseInt(evt.target.value, 10));
    setPage(0);
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onFilterRecords = (result, currentFilters) => {
    const res = filterRecords(result, currentFilters, (item) => [
      item.metadata?.displayName,
    ]);

    setData(res);
  };

  const onKeyPressSearch = (evt) => {
    evt.preventDefault();

    if (evt.key === "Enter") {
      onFilterRecords(data?.data, filters);
      setPage(0);
    }
  };

  const onChangeSearchTxt = (evt) => {
    const { value } = evt.target;

    setFilters({ ...filters, searchedValue: value });
  };

  const onClearSearchTxt = () => {
    const copyFilters = { ...filters, searchedValue: null };

    setFilters(copyFilters);
    onFilterRecords(data?.data, copyFilters);
  };

  let title, content, saveLabel, onConfirmModalAction;

  switch (pressedBtn) {
    case JSON_ITEM:
      title = t.json;
      content = (
        <Box>
          <CopyBlock
            customStyle={{ height: "550px", width: "100%", overflow: "scroll" }}
            text={JSON.stringify(form, null, 4)}
            language={"en"}
            showLineNumbers={true}
            theme={dracula}
          />
        </Box>
      );
      break;
    case AUDIT_LOGS:
      title = t.logs;
      content = (
        <Table
          rows={{
            ...ROWS_PARAMS_LOGS(form.logs ?? []),
            body: form?.logs ?? [],
          }}
          selectedRow={() => false}
          hideTableHeader={true}
        />
      );
      break;
    default:
  }

  return (
    <Box>
      <Modal
        {...{ open }}
        handleClose={handleClose}
        title={title}
        closeLabel={t.close}
        onSave={onConfirmModalAction}
        {...{ content, saveLabel }}
      />

      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: "1.5rem" }}>
        <Tabs value={tab} onChange={handleTabChange} variant="fullWidth">
          <Tab label={t.build} />
          <Tab label={t.run} />
        </Tabs>
      </Box>
      {[
        { id: 0, specificDetails: { hasBtn: true, hasForceUpdate: true } },
        { id: 1 },
      ].map((it) => {
        return (
          <div
            key={it.id}
            role="tabpanel"
            hidden={tab !== it.id}
            id={`full-width-tabpanel-${it.id}`}
            aria-labelledby={`full-width-tab-${it.id}`}
          >
            {it?.specificDetails?.hasBtn && (
              <Button
                sx={{ mb: 2 }}
                variant="contained"
                onClick={() => {
                  navigate("/kubernetes/wizard");
                }}
                disabled={
                  currentGroup?.id && !currentGroup?.isContributor2Group
                }
              >
                {t.addNew}
              </Button>
            )}
            <Table
              rows={{
                ...rowsParams(data?.filtered, tab),
                body: data?.filtered?.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ),
              }}
              selectedRow={(item) => item.id === form.id}
              {...{ onChangeSearchTxt, onKeyPressSearch, onClearSearchTxt }}
              searchedValue={filters?.searchedValue}
              {...{
                page,
                rowsPerPage,
                onChangePage,
                onChangeRowsPerPage,
                isLoading,
              }}
              actionBtns={(item) => (
                <>
                  {tab === 0 && (
                    <IconButton
                      sx={{ ...styleIconBtn, mr: 2 }}
                      title={t.open(t.buildImage)}
                      onClick={() =>
                        navigate(`/kubernetes/image/${item?.metadata?.name}`)
                      }
                    >
                      <ArrowRightAltIcon sx={{ pointerEvents: "none" }} />
                    </IconButton>
                  )}
                  {it?.specificDetails?.hasForceUpdate && (
                    <IconButton
                      sx={{ ...styleIconBtn, mr: 2 }}
                      title={t.forceBuild}
                      id={FORCE_BUILD}
                      onClick={(evt) => onOpen(evt, item)}
                      disabled={
                        currentGroup?.id && !currentGroup?.isContributor2Group
                      }
                    >
                      <AutorenewIcon sx={{ pointerEvents: "none" }} />
                    </IconButton>
                  )}
                  <IconButton
                    sx={{ ...styleIconBtn, mr: 2 }}
                    title={t.json}
                    id={JSON_ITEM}
                    onClick={(evt) => onOpen(evt, item)}
                  >
                    <CodeIcon sx={{ pointerEvents: "none" }} />
                  </IconButton>
                  <IconButton
                    sx={{ ...styleIconBtn, mr: 2 }}
                    title={t.logs}
                    id={AUDIT_LOGS}
                    onClick={(evt) => onOpen(evt, item)}
                  >
                    <HistoryIcon sx={{ pointerEvents: "none" }} />
                  </IconButton>
                </>
              )}
            />
          </div>
        );
      })}
    </Box>
  );
};

export default withErrorHandling(ManageApps);
