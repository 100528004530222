import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";

import withErrorHandling from "../HOC/ErrorHandling";
import { useSharedProps } from "../HOC/SharedProps";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Remove";
import VisibilityIcon from "@mui/icons-material/Visibility";

import {
  AppBar as MuiAppBar,
  Toolbar,
  List,
  ListItemText,
  ListItemButton,
  Avatar,
  Popper,
  ClickAwayListener,
  Typography,
  Paper,
  IconButton,
  Box,
  Button,
  Tabs,
  Tab,
  TextField,
  CircularProgress,
  Badge,
} from "@mui/material";

import t from "../helpers/en";
import { filterRecords, changeTxt } from "../helpers/selectors";
import {
  getAppGroup,
  getUsers,
  newAppGroup,
  deleteAppGroup,
  updateAppGroup,
} from "../helpers/utils";
import { CURRENT_APPS, KUBERNETES } from "../helpers/constants";

import Select from "./Inputs/Select";
import Search from "./Inputs/SearchInput";
import Modal from "./Modal/Modal";
import CheckboxGroup from "./Inputs/CheckboxGroup";
import Radio from "./Inputs/RadioGroup";

import { styleIconBtn, styleEllipsis } from "../GeneralStyles";

const drawerWidth = 300;
const drawerWidthClosed = 72;
const drawerWidthClosedSmall = 56;
const toolbarHeight = 97;

const groupStyles = {
  display: "grid",
  gap: "1rem",
  alignItems: "center",
  borderBottom: "1px solid rgba(0,0,0,.5)",
  gridTemplateColumns: "1fr 1fr 1fr repeat(2, 2em)",
  padding: "0.2rem",
};

const userStyles = {
  display: "grid",
  gap: "1rem",
  gridTemplateColumns: "1fr 5em 2em",
  borderBottom: "1px solid rgba(0,0,0,.5)",
  padding: "0.2rem",
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open && {
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${drawerWidthClosedSmall}px)`,
    },
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidthClosed}px)`,
    },
  }),
  height: toolbarHeight,
}));

const GroupBtn = ({ onOpen, modalData, currentGroup }) => {
  return (
    <Button
      variant="contained"
      sx={{
        textTransform: "none",
        ...styleEllipsis,
        maxWidth: "15em",
      }}
      onClick={onOpen}
    >
      {modalData?.isLoading ? (
        <CircularProgress
          style={{ width: "1em", height: "1em", color: "#fff" }}
        />
      ) : (
        currentGroup?.name ?? t.noAppGroup
      )}
    </Button>
  );
};

const AppBarMain = (props) => {
  const { onLogout, currentUser, open, showError } = props;

  const { currentApp, onSetGroup, onSelectApp, currentGroup } =
    useSharedProps();
  const location = useLocation();
  const navigate = useNavigate();

  const [anchorAvatarEl, setAnchorAvatarEl] = useState(null);
  const [modalData, setModalData] = useState({ open: false, tabValue: 0 });
  const [data, setData] = useState({ all: [], filtered: [] });

  const onOpenAvatarPopper = (evt) => {
    setAnchorAvatarEl(anchorAvatarEl ? null : evt.currentTarget);
  };

  const onCloseAvatarPopper = () => {
    setAnchorAvatarEl(null);
  };
  const openAvatarPopup = Boolean(anchorAvatarEl);
  const id = openAvatarPopup ? "AVATAR_POPPER" : undefined;

  const currentUserAvatar = (name) => {
    return {
      sx: {
        bgcolor: "#fff",
        color: "#2995e7fe",
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  };

  const onMove2Users = (cb) => {
    cb && cb();
    navigate("/users");
  };

  const fetchData = (cb) => {
    getAppGroup((result, errorMsg) => {
      if (result) {
        const updated = result?.map((group) => ({
          ...group,
          isContributor2Group: group?.userRights?.find(
            (user) => user?.user?.id === currentUser?.uid
          )?.isContributor,
        }));
        setData({ all: updated, filtered: updated });
      } else {
        showError(errorMsg);
      }

      cb && cb();
    });
  };

  const onOpen = (evt) => {
    setModalData({
      isLoading: true,
    });

    const data1 = new Promise((resolve) => {
      fetchData(resolve);
    });

    Promise.all([data1]).then(() => {
      setModalData({
        open: true,
        tabValue: 0,
        options: [],
        selectedUsers: [],
      });
    });
  };

  const onClose = () => {
    setModalData({ open: false });
  };

  const onChangeTab = (_, val) => {
    setModalData((prev) => ({
      ...prev,
      tabValue: val,
      id: null,
      name: null,
      selectedUsers: modalData?.id
        ? []
        : [
            {
              ...({ ...currentUser, id: currentUser?.uid } ?? {}),
              isContributor: true,
            },
          ],
    }));
  };

  const onFilterRecords = (result, currentFilters) => {
    const res = filterRecords(result, currentFilters, (item) => [item.name]);

    setData(res);
  };

  const onChangeSearchTxt = (evt) => {
    const { value } = evt.target;

    setModalData((prev) => ({ ...prev, searchedValue: value }));
  };

  const onClearSearchTxt = () => {
    const copyFilters = { ...modalData, searchedValue: null };

    setModalData(copyFilters);
    onFilterRecords(data?.all, copyFilters);
  };

  const onKeyPressSearch = (evt) => {
    evt.preventDefault();

    if (evt.key === "Enter") {
      onFilterRecords(data?.all, modalData);
    }
  };

  const onChangeSearchTxtUser = (evt) => {
    const { value } = evt.target;

    setModalData((prev) => ({ ...prev, searchedUser: value }));
  };

  const onClearSearchTxtUser = () => {
    const copyFilters = { ...modalData, searchedUser: null };

    setModalData(copyFilters);
  };

  const onKeyPressSearchUser = (evt) => {
    evt.preventDefault();

    if (evt.key === "Enter") {
      if (modalData?.searchedUser) {
        setModalData((prev) => ({
          ...prev,
          isSearchingUser: true,
        }));
        getUsers(
          (result, err) => {
            if (!err) {
              setModalData((prev) => ({
                ...prev,
                options: result
                  ? [result, ...modalData.options]
                  : modalData?.options,
                selectedUsers: [
                  ...prev.selectedUsers,
                  ...(result?.id &&
                  !prev.selectedUsers.some((it) => it.id === result?.id)
                    ? [result]
                    : []),
                ],
                searchedUser: null,
                isSearchingUser: false,
              }));
            } else {
              showError(err);
              setModalData((prev) => ({
                ...prev,
                isSearchingUser: false,
              }));
            }
          },
          { email: modalData?.searchedUser, shouldSearchByEmail: true }
        );
      }
    }
  };

  const onChangeTxt = (evt) => {
    const result = changeTxt(evt, modalData);

    setModalData(result);
  };

  const onCheckboxBtnChange = (evt, item, idx) => {
    let cpy = { ...modalData };

    cpy.selectedUsers[idx] = {
      ...cpy.selectedUsers[idx],
      isContributor: !item.isContributor,
    };

    setModalData(cpy);
  };

  const setAppGroup = () => {
    const triggerUpdate = (result, err) => {
      if (!err) {
        onClose();
      } else {
        showError(err);
      }
    };

    if (!modalData?.id) {
      newAppGroup(modalData, (result, err) => {
        triggerUpdate(result, err);
      });
    } else {
      updateAppGroup(modalData, (result, err) => {
        triggerUpdate(result, err);
      });
    }
  };

  const onEditGroup = (_, el) => {
    setModalData((prev) => ({
      ...prev,
      tabValue: 1,
      name: el.name,
      id: el.id,
      selectedUsers: el.userRights?.map((it) => ({
        ...it.user,
        isContributor: it.isContributor,
      })),
    }));
  };

  const onDeleteGroup = (el) => {
    deleteAppGroup(el, (result, err) => {
      if (!err) {
        fetchData();
        if (el?.id === currentGroup?.id) {
          onSetGroup(el);
        }
      } else {
        showError(err);
      }
    });
  };

  const onRemoveUser = (idx) => {
    const cpy = { ...modalData };

    cpy?.selectedUsers?.splice(idx, 1);
    setModalData(cpy);
  };

  return (
    <AppBar position="absolute" {...{ open }}>
      <Toolbar
        sx={{
          pr: "24px", // keep right padding when drawer closed
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
          <Typography component="h1" variant="h6">
            Clue 2 Solve
          </Typography>
          <Select
            options={CURRENT_APPS}
            onChange={(evt, val) => onSelectApp(evt, val)}
            item="label"
            value={CURRENT_APPS?.find((it) => it.id === currentApp?.id) || ""}
            specificDetails={{
              variant: "standard",
              InputProps: {
                endAdornment: null,
                disableUnderline: true,
              },
              inputProps: {
                readOnly: true,
              },
              styles: {
                autocomplete: {
                  display: "inline-flex",
                  height: "2.3em",
                  width: "9em",
                  boxShadow:
                    "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
                  borderRadius: "0.3rem",
                  margin: "0 1rem",
                  alignItems: "center",
                  padding: 0,
                },
                input: { textAlign: "center", cursor: "pointer" },
                text: {
                  color: "#fff",
                  padding: 0,
                },
              },
            }}
          />
          {currentApp?.id === KUBERNETES &&
            (currentGroup?.id ? (
              <Badge
                badgeContent={
                  currentGroup?.isContributor2Group ? (
                    <EditIcon sx={{ padding: "0.2rem" }} />
                  ) : (
                    <VisibilityIcon sx={{ padding: "0.2rem" }} />
                  )
                }
                color="info"
              >
                <GroupBtn {...{ onOpen, modalData, currentGroup }} />
              </Badge>
            ) : (
              <GroupBtn {...{ onOpen, modalData, currentGroup }} />
            ))}
        </Box>
        <Modal
          open={Boolean(modalData?.open)}
          handleClose={onClose}
          title={t.manageAppGroups}
          saveLabel={modalData?.tabValue === 1 && t.save}
          onSave={modalData?.tabValue === 1 && setAppGroup}
          closeLabel={t.close}
          content={
            <Box>
              <Tabs
                value={modalData?.tabValue}
                onChange={onChangeTab}
                aria-label="select a group"
                sx={{ mb: 2 }}
              >
                {[
                  { id: 0, label: t.selectAppGroup },
                  {
                    id: 1,
                    label: !modalData?.id ? t.newAppGroup : t.edit(t.group),
                  },
                ].map((tab, tabKey) => (
                  <Tab
                    key={tabKey}
                    label={tab.label}
                    id={`group-tab-${tab.id}`}
                    aria-controls={`group-tabpanel-${tab.id}`}
                  />
                ))}
              </Tabs>
              {modalData?.tabValue === 0 ? (
                <>
                  <Search
                    className={{
                      marginLeft: "auto",
                      width: "100%",
                      marginBottom: "1rem",
                    }}
                    searchedValue={modalData?.searchedValue}
                    {...{
                      onChangeSearchTxt,
                      onClearSearchTxt,
                      onKeyPressSearch,
                    }}
                    specificDetails={{
                      styles: {
                        searchIcon: { width: "1.5em", height: "auto" },
                      },
                    }}
                  />
                  {data.all?.length > 0 ? (
                    <>
                      <span style={groupStyles}>
                        {[t.name, t.id, t.userRights].map((it) => (
                          <span style={{ fontWeight: 500 }} key={it}>
                            {it}
                          </span>
                        ))}
                      </span>
                      {data?.filtered?.map((it) => (
                        <span key={it.id} style={groupStyles}>
                          <span
                            onClick={() => onSetGroup(it)}
                            style={{
                              cursor: "pointer",
                              alignContent: "center",
                              width: "100%",
                            }}
                          >
                            <Radio
                              onChange={() => onSetGroup(it)}
                              specificDetails={{
                                isIndividual: true,
                                checked: it.id === currentGroup?.id,
                                value: it.id,
                              }}
                            />
                            {it.name}
                          </span>
                          <span>{it.id}</span>
                          <span>
                            {it?.isContributor2Group
                              ? t.contributor
                              : t.readOnly}
                          </span>
                          <IconButton
                            sx={{
                              ...styleIconBtn,
                              width: "1.5em",
                              height: "1.5em",
                            }}
                            title={t.edit(t.appGroup)}
                            onClick={(evt) => onEditGroup(evt, it)}
                            disabled={!it?.isContributor2Group}
                          >
                            <EditIcon sx={{ pointerEvents: "none" }} />
                          </IconButton>
                          <IconButton
                            sx={{
                              ...styleIconBtn,
                              width: "1.5em",
                              height: "1.5em",
                            }}
                            title={t.delete(t.appGroup)}
                            onClick={() => onDeleteGroup(it)}
                            disabled={!it?.isContributor2Group}
                          >
                            <DeleteIcon sx={{ pointerEvents: "none" }} />
                          </IconButton>
                        </span>
                      ))}
                    </>
                  ) : (
                    <span
                      style={{
                        display: "flex",
                        marginTop: "1rem",
                        justifyContent: "center",
                      }}
                    >
                      {t.noAppGroup}
                    </span>
                  )}
                </>
              ) : (
                <span style={{ display: "grid", gap: "1rem" }}>
                  <TextField
                    id={`outlined-basic-name`}
                    label={t.appGroupN}
                    name="name"
                    value={modalData?.name || ""}
                    onChange={onChangeTxt}
                  />
                  <Search
                    searchedValue={modalData?.searchedUser}
                    onChangeSearchTxt={onChangeSearchTxtUser}
                    onClearSearchTxt={onClearSearchTxtUser}
                    onKeyPressSearch={onKeyPressSearchUser}
                    specificDetails={{
                      endIc: modalData?.isSearchingUser && <CircularProgress />,
                      placeholder: "Search User by Email",
                      styles: {
                        searchIcon: { width: "1.5em", height: "auto" },
                        paper: {
                          marginLeft: "auto",
                          width: "100%",
                          marginBottom: "1rem",
                          height: "3.5em",
                          boxShadow: "none",
                          border: "1px solid rgba(0,0,0,0.3)",
                        },
                      },
                    }}
                  />
                  {modalData?.selectedUsers?.length > 0 && (
                    <>
                      <span style={userStyles}>
                        {[t.users, "Contributor"].map((it, itIdx) => (
                          <span style={{ fontWeight: 500 }} key={itIdx}>
                            {it}
                          </span>
                        ))}
                      </span>
                      <span style={{ display: "grid", gap: "1rem" }}>
                        {modalData?.selectedUsers?.map((it, idx) => (
                          <span key={it.id} style={userStyles}>
                            {idx + 1}. {it.email}
                            <CheckboxGroup
                              specificDetails={{
                                isSingleInput: true,
                                checked: it.isContributor,
                                name: "isContributor",
                                disabled: it?.id === currentUser?.uid,
                                formControlLabelStyle: { margin: 0 },
                                checkboxStyle: {
                                  padding: 0,
                                  "& .MuiSvgIcon-root": {
                                    width: "1.5em",
                                    height: "auto",
                                  },
                                },
                              }}
                              onChange={(evt) =>
                                onCheckboxBtnChange(evt, it, idx)
                              }
                            />
                            <IconButton
                              onClick={() => {
                                onRemoveUser(idx);
                              }}
                              sx={{
                                ...styleIconBtn,
                                width: "1.5em",
                                height: "1.5em",
                              }}
                              disabled={
                                it?.id === modalData?.currentLoggedInUser?.id
                              }
                            >
                              <RemoveIcon sx={{ pointerEvents: "none" }} />
                            </IconButton>
                          </span>
                        ))}
                      </span>
                    </>
                  )}
                </span>
              )}
            </Box>
          }
        />
        <IconButton onClick={onOpenAvatarPopper}>
          <Avatar
            {...currentUserAvatar(
              `${currentUser.givenName} ${currentUser.familyName}`
            )} //USE THE REAL USER DETAILS IN THE FUTURE
            sx={{ pointerEvents: "none" }}
          />
        </IconButton>
        <Popper
          sx={{ zIndex: 10000 }}
          {...{ id }}
          anchorEl={anchorAvatarEl}
          open={openAvatarPopup}
          onClose={onCloseAvatarPopper}
          disablePortal={true}
        >
          <ClickAwayListener onClickAway={onCloseAvatarPopper}>
            <Paper>
              <List>
                {[
                  { name: t.myProfile },
                  ...(currentUser?.role === "ADMIN" ||
                  currentUser?.role === "SYSTEM"
                    ? [
                        {
                          name: t.users,
                          onClick: () => onMove2Users(onCloseAvatarPopper),
                        },
                      ]
                    : []),
                  {
                    name: t.logout,
                    onClick: () =>
                      onLogout(() => {
                        navigate("/login", { state: { from: location } });
                        onCloseAvatarPopper();
                      }),
                  },
                ].map((it, itIdx) => (
                  <ListItemButton key={itIdx} onClick={it.onClick || undefined}>
                    <ListItemText>{it.name}</ListItemText>
                  </ListItemButton>
                ))}
              </List>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </Toolbar>
    </AppBar>
  );
};

export default withErrorHandling(AppBarMain);
