import React, { useState, Fragment } from "react";

import { Box, IconButton, TextField, Tabs, Tab, Divider } from "@mui/material";

import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

import {
  INDUSTRIES,
  ADD_QUESTIONNAIRE,
  EDIT_QUESTIONNAIRE,
  ADD_CUSTOMER,
} from "../helpers/constants";
import t from "../helpers/en";

import Select from "./Inputs/Select";
import DragDrop from "./DragDrop";

import { styleIconBtn, styleInputsWrapper } from "../GeneralStyles";

const QuestionnaireForm = (props) => {
  const {
    pressedBtn,
    templates,
    form,
    onChangeTxt,
    onDragEnd,
    questions,
    categories,
    onSelectOption,
    onSelectMultipleSubOption,
    onRemoveAnswerContainer,
    onAddAnswerContainer,
  } = props;

  const [tabValue, setTabValue] = useState(0);

  const onChangeTab = (_, val) => {
    setTabValue(val);
  };

  return (
    <Box
      sx={{
        display: "grid",
        rowGap: "1rem",
      }}
    >
      {pressedBtn === ADD_QUESTIONNAIRE && (
        <Tabs
          value={tabValue}
          onChange={onChangeTab}
          aria-label="import/export csv tabs"
          sx={{ mb: 2 }}
        >
          {[
            { id: 0, label: t.standard },
            { id: 1, label: t.customized },
          ].map((tab, tabKey) => (
            <Tab
              key={tabKey}
              label={tab.label}
              id={`simple-tab-${tab.id}`}
              aria-controls={`simple-tabpanel-${tab.id}`}
            />
          ))}
        </Tabs>
      )}
      {[
        [
          {
            select: true,
            label: t.industry,
            options: INDUSTRIES,
            option: "name",
            param: "industryId",
            key: "id",
          },
          {
            select: true,
            label: t.template,
            options: templates,
            option: "name",
            param: "templateId",
            key: "id",
          },
        ],
        { label: t.comments, name: "comments", multiline: true },
      ].map((item, itemIdx) => {
        const input = (currentItem) => (
          <Fragment key={itemIdx}>
            {!currentItem.select ? (
              <TextField
                id={`outlined-basic-${currentItem.name}`}
                label={currentItem.label}
                name={currentItem.name}
                value={form[currentItem.name] || ""}
                onChange={onChangeTxt}
                multiline={currentItem.multiline}
              />
            ) : (
              <Select
                options={currentItem.options}
                onChange={(evt, val) =>
                  onSelectOption(
                    evt,
                    val?.[currentItem?.key],
                    currentItem.param,
                    val
                  )
                }
                label={currentItem.label}
                item={currentItem.option}
                value={
                  currentItem.options.find(
                    (it) => form?.[currentItem.param] === it[currentItem.key]
                  ) || ""
                }
              />
            )}
          </Fragment>
        );
        return Array.isArray(item) ? (
          <Box sx={{ ...styleInputsWrapper(item) }} key={itemIdx}>
            {item.map((item2, item2Idx) => (
              <Fragment key={item2Idx}>{input(item2)}</Fragment>
            ))}
          </Box>
        ) : (
          input(item)
        );
      })}
      {(tabValue !== 0 ||
        pressedBtn === EDIT_QUESTIONNAIRE ||
        pressedBtn === ADD_CUSTOMER) && (
        <>
          <Divider />
          <DragDrop
            {...{ onDragEnd }}
            data={form?.questions}
            content={(question, questionIdx) => (
              <Fragment key={questionIdx}>
                {[
                  {
                    select: true,
                    label: t.question,
                    options: questions,
                    option: "question",
                    param: "questionId",
                    key: "id",
                  },
                  {
                    select: true,
                    label: t.category,
                    options: categories,
                    option: "name",
                    param: "categoryId",
                    key: "id",
                  },
                ].map((item, itIdx) => (
                  <Fragment key={itIdx}>
                    <Select
                      options={item.options}
                      onChange={(evt, val) =>
                        onSelectMultipleSubOption(
                          evt,
                          val?.[item?.key],
                          item.param,
                          questionIdx,
                          val
                        )
                      }
                      label={item.label}
                      item={item.option}
                      value={
                        item.options.find(
                          (it) => question[item.param] === it[item.key]
                        ) || ""
                      }
                    />{" "}
                  </Fragment>
                ))}
                <IconButton
                  onClick={() => onRemoveAnswerContainer(questionIdx)}
                  sx={{ ...styleIconBtn }}
                >
                  <RemoveIcon sx={{ pointerEvents: "none" }} />
                </IconButton>
              </Fragment>
            )}
            droppableTopContent={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                {t.questions}:
                <IconButton
                  onClick={onAddAnswerContainer}
                  sx={{
                    ...styleIconBtn,
                    marginLeft: "auto",
                    display: "flex",
                    width: "5em",
                  }}
                >
                  <AddIcon sx={{ pointerEvents: "none" }} />
                </IconButton>
              </div>
            }
          />
        </>
      )}
    </Box>
  );
};

export default QuestionnaireForm;
