import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";

import withErrorHandling from "../HOC/ErrorHandling";

import {
  Box,
  Button,
  Stepper,
  Step,
  StepButton,
  Typography,
  TextField,
  Switch,
  FormControlLabel,
} from "@mui/material";

import {
  getQuestionnaire,
  getQuestions,
  getCategories,
  createQuestionnaireAnswers,
  getQuestionnaireAnswers,
  updateQuestionnaireAnswers,
} from "../helpers/utils";
import t from "../helpers/en";

import Radio from "../components/Inputs/RadioGroup";
import CheckboxGroup from "../components/Inputs/CheckboxGroup";
import Loading from "../components/Loading";

const Questionnaire = (props) => {
  const { showError } = props;

  const [data, setData] = useState({});
  const [answersQuestionnaire, setAnswersQuestionnaire] = useState({});
  const [shouldUpdateAnsweres, setShouldUpdateAnswers] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [switcher, setSwitcher] = useState(false);

  const { customerQuestionnaireId } = useParams();
  const currentRenderedSection = Object.values(data)?.[activeStep];

  useEffect(() => {
    setIsLoading(true);

    const data0 = new Promise((resolve, reject) => {
      getQuestionnaire(customerQuestionnaireId, (result1, err) => {
        if (!err) {
          resolve(result1);
        } else {
          reject(err);
        }
      });
    });
    const data1 = new Promise((resolve, reject) => {
      getQuestionnaireAnswers(
        customerQuestionnaireId,
        (allAnsweredQuestions, err) => {
          if (!err) {
            if (allAnsweredQuestions?.length > 0) {
              setShouldUpdateAnswers(true);
            }

            resolve(allAnsweredQuestions);
          } else {
            reject(err);
          }
        }
      );
    });
    const data2 = new Promise((resolve, reject) => {
      getCategories((result2, err) => {
        if (!err) {
          resolve(result2);
        } else {
          reject(err);
        }
      });
    });
    const data3 = new Promise((resolve, reject) => {
      getQuestions((result3, err) => {
        if (!err) {
          resolve(result3);
        } else {
          reject(err);
        }
      });
    });

    Promise.all([data0, data1, data2, data3])
      .then(([result1, allAnsweredQuestions, result2, result3]) => {
        const finalAllAnsweredQuestions = allAnsweredQuestions?.map((it) => ({
          id: it.id,
          questionId: it.questionId,
          answerId: it.answerId,
          value: it.value,
        }));
        let copyCompleted = {};
        const combinedResult = result1?.questions?.reduce((acc, item) => {
          const { categoryId } = item;
          const findItem = result3.find((it) => it.id === item.questionId);
          const findAnsweredQuestion = allAnsweredQuestions.find(
            (it) => it.questionId === item.id
          );

          if (findAnsweredQuestion) {
            if (copyCompleted[categoryId]) {
              copyCompleted[categoryId] = [
                ...copyCompleted[categoryId],
                findAnsweredQuestion,
              ];
            } else {
              copyCompleted = {
                ...copyCompleted,
                [categoryId]: [findAnsweredQuestion],
              };
            }
          }

          const findCategory = result2.find((it) => it.id === categoryId);

          if (!acc[categoryId]) {
            acc[categoryId] = {
              questions: [],
              category_name: findCategory.name,
            };
          }

          acc[categoryId].questions.push({
            ...item,
            answers: findItem?.answers,
            question: findItem?.question,
            type: findItem?.type,
          });

          return acc;
        }, {});

        setCompleted(copyCompleted);
        setAnswersQuestionnaire(finalAllAnsweredQuestions);
        setData(combinedResult);
        setIsLoading(false);
      })
      .catch((err) => {
        showError(err);
        setIsLoading(false);
      });
  }, [customerQuestionnaireId, showError]);

  const onRadioBtnChange = (evt, item) => {
    const { value } = evt.target;
    let copy = [...answersQuestionnaire];
    let copyCompleted = { ...completed };
    const activeCategoryGroupId = Object.keys(data)?.[activeStep];
    const findIndex = copy.findIndex((it) => it?.questionId === item.id);

    if (findIndex !== -1) {
      copy[findIndex] = {
        ...copy[findIndex],
        questionId: item.id,
        answerId: value,
      };
    } else {
      copy.push({
        questionId: item.id,
        answerId: value,
      });
    }

    copyCompleted[activeCategoryGroupId] = [
      ...(copyCompleted?.[activeCategoryGroupId] ?? []),
      ...(copyCompleted?.[activeCategoryGroupId]?.find(
        (it) => it.questionId === item.id
      )
        ? []
        : [{ questionId: item.id, answerId: value }]),
    ];

    setCompleted(copyCompleted);
    setAnswersQuestionnaire(copy);
  };

  const onCheckboxBtnChange = (evt, item) => {
    const { name, checked } = evt.target;
    let copy = [...answersQuestionnaire];
    let copyCompleted = { ...completed };
    const activeCategoryGroupId = Object.keys(data)[activeStep];

    if (checked) {
      copy.push({
        questionId: item.id,
        answerId: name,
      });

      const answer2QuestionExists = copyCompleted?.[
        activeCategoryGroupId
      ]?.find((it) => it.questionId === item.id);

      copyCompleted[activeCategoryGroupId] = [
        ...(copyCompleted?.[activeCategoryGroupId] ?? []),
        ...(answer2QuestionExists
          ? []
          : [{ questionId: item.id, answerId: name }]),
      ];
    } else {
      copy = copy.filter(
        (it) => !(it?.questionId === item?.id && it.answerId === name)
      );

      const answer2QuestionExists = copy.find(
        (it) => it.questionId === item.id
      );
      copyCompleted[activeCategoryGroupId] = [
        ...(answer2QuestionExists
          ? copyCompleted?.[activeCategoryGroupId] ?? []
          : copyCompleted[activeCategoryGroupId]?.filter(
              (it) => it.questionId !== item.id
            )),
      ];
    }

    setCompleted(copyCompleted);
    setAnswersQuestionnaire(copy);
  };

  const onChangeTxt = (evt, item) => {
    const { value } = evt.target;
    let copy = [...answersQuestionnaire];
    const findIndex = copy.findIndex((it) => it?.questionId === item?.id);
    let copyCompleted = { ...completed };
    const activeCategoryGroupId = Object.keys(data)[activeStep];

    if (findIndex !== -1) {
      if (value) {
        copy[findIndex] = {
          ...copy[findIndex],
          questionId: item.id,
          value,
        };
      } else {
        copy = copy.filter((it) => it.questionId !== item.id);
        copyCompleted = {
          ...copyCompleted,
          [activeCategoryGroupId]: copyCompleted[activeCategoryGroupId].filter(
            (it) => it.questionId !== item.id
          ),
        };
      }
    } else {
      const newAnswer = {
        questionId: item.id,
        answerId: item?.answers?.[0]?.id,
        value,
      };

      copyCompleted = {
        ...copyCompleted,
        [activeCategoryGroupId]: [
          ...(copyCompleted?.[activeCategoryGroupId] ?? []),
          newAnswer,
        ],
      };
      copy.push(newAnswer);
    }

    setCompleted(copyCompleted);
    setAnswersQuestionnaire(copy);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const totalSteps = () => {
    return Object.keys(data).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    if (!isLastStep()) {
      const newActiveStep = activeStep + 1;
      setActiveStep(newActiveStep);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onSave = useCallback(() => {
    const getAllAnswers = () => {
      getQuestionnaireAnswers(
        customerQuestionnaireId,
        (allAnsweredQuestions, err) => {
          if (!err) {
            const finalAllAnsweredQuestions = allAnsweredQuestions?.map(
              (it) => ({
                id: it.id,
                questionId: it.questionId,
                answerId: it.answerId,
                value: it.value,
              })
            );

            if (allAnsweredQuestions?.length > 0) {
              setShouldUpdateAnswers(true);
            }

            setAnswersQuestionnaire(finalAllAnsweredQuestions);
          } else {
            showError(err);
          }
        }
      );
    };

    if (!shouldUpdateAnsweres) {
      createQuestionnaireAnswers(
        customerQuestionnaireId,
        answersQuestionnaire,
        (result, err) => {
          if (!err) {
            getAllAnswers();
          } else {
            showError(err);
          }
        }
      );
    } else {
      updateQuestionnaireAnswers(
        customerQuestionnaireId,
        answersQuestionnaire,
        (result, err) => {
          if (!err) {
            getAllAnswers();
          } else {
            showError(err);
          }
        }
      );
    }
  }, [
    answersQuestionnaire,
    customerQuestionnaireId,
    shouldUpdateAnsweres,
    showError,
  ]);

  useEffect(() => {
    let intervalId;

    if (switcher) {
      intervalId = setInterval(() => {
        onSave();
      }, 30000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [switcher, onSave]);

  const onSwitchAutosave = (evt) => {
    const { checked } = evt.target;

    setSwitcher(checked);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box>
      <Stepper sx={{ mb: 2 }} nonLinear {...{ activeStep }}>
        {Object.entries(data).map(([key, item], itemIdx) => {
          const findIdx = Object.keys(completed).findIndex((it) => it === key);

          return (
            <Step
              key={itemIdx}
              completed={
                Object.values(completed)?.[findIdx]?.length ===
                item?.questions?.length
              }
            >
              <StepButton color="inherit" onClick={handleStep(itemIdx)}>
                {item.category_name}
                {"  "}({item.questions?.length})
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      {currentRenderedSection && (
        <Box sx={{ border: 1, p: 1, mb: 1, borderRadius: "0.2rem" }}>
          <Typography variant="h6">
            {currentRenderedSection.category_name}
          </Typography>
          <Box sx={{ p: 1 }}>
            {currentRenderedSection.questions.map((question, questionIdx) => {
              let renderAnswersInput;

              if (question.type === "SINGLE_SELECT") {
                renderAnswersInput = (
                  <Radio
                    onChange={(evt) => onRadioBtnChange(evt, question)}
                    value={
                      answersQuestionnaire?.find(
                        (val) => question?.id === val?.questionId
                      )?.answerId || ""
                    }
                    optionValue="id"
                    label="answer"
                    data={question.answers}
                  />
                );
              } else if (question.type === "MULTI_SELECT") {
                renderAnswersInput = (
                  <div style={{ display: "grid" }}>
                    <CheckboxGroup
                      onChange={(evt) => onCheckboxBtnChange(evt, question)}
                      checkedValues={answersQuestionnaire
                        .filter((val) => val.questionId === question.id)
                        .map((val) => val.answerId)}
                      optionValue="id"
                      label="answer"
                      data={question.answers}
                    />
                    {/* <TextField
                      value={
                        answersQuestionnaire.find(
                          (it) => it.questionId === question.id
                        )?.value || ""
                      }
                      onChange={(evt) => onChangeTxt(evt, question)}
                    /> */}
                  </div>
                );
              } else if (question.type === "SINGLE_LINE_TEXT") {
                renderAnswersInput = (
                  <TextField
                    value={
                      answersQuestionnaire.find(
                        (it) => it.questionId === question.id
                      )?.value || ""
                    }
                    onChange={(evt) => onChangeTxt(evt, question)}
                  />
                );
              } else if (question.type === "MULTI_LINE_TEXT") {
                renderAnswersInput = (
                  <TextField
                    value={
                      answersQuestionnaire.find(
                        (it) => it.questionId === question.id
                      )?.value || ""
                    }
                    multiline
                    onChange={(evt) => onChangeTxt(evt, question)}
                  />
                );
              } else if (question.type === "DATE") {
                renderAnswersInput = (
                  <TextField
                    value={
                      answersQuestionnaire.find(
                        (it) => it.questionId === question.id
                      )?.value || ""
                    }
                    onChange={(evt) => onChangeTxt(evt, question)}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                );
              }

              return (
                <Box sx={{ p: 1 }} key={questionIdx}>
                  <Typography>
                    {questionIdx + 1}. {question.question}
                  </Typography>
                  <Box sx={{ p: 1 }}>{renderAnswersInput}</Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button
          color="inherit"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          {t.back}
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <FormControlLabel
          control={<Switch checked={switcher} onChange={onSwitchAutosave} />}
          label={t.autosave}
        />
        <Button disabled={!!isLastStep()} onClick={handleNext} sx={{ mr: 1 }}>
          {t.next}
        </Button>
        <Button onClick={onSave}>{t.save}</Button>
      </Box>
    </Box>
  );
};

export default withErrorHandling(Questionnaire);
