import React, { Fragment } from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { Box, IconButton, TextField, Divider } from "@mui/material";

import t from "../helpers/en";
import { INPUT_TYPES } from "../helpers/constants";

import Select from "./Inputs/Select";
import DragDrop from "./DragDrop";

import { styleIconBtn, styleInputsWrapper } from "../GeneralStyles";

const QuestionForm = (props) => {
  const {
    categories,
    form,
    onChangeTxt,
    onSelectOption,
    onAddAnswerContainer,
    onDragEnd,
    onChangeSubTxt,
    onRemoveAnswerContainer,
  } = props;

  return (
    <Box
      sx={{
        display: "grid",
        rowGap: "1rem",
      }}
    >
      {[
        { label: t.question, name: "question" },
        [
          {
            select: true,
            label: t.type,
            options: INPUT_TYPES,
            option: "name",
            param: "type",
            key: "name",
          },
          ...(categories
            ? [
                {
                  select: true,
                  label: t.category,
                  options: categories,
                  option: "name",
                  param: "categoryId",
                  key: "id",
                },
              ]
            : []),
        ],
        { label: t.description, name: "description", multiline: true },
      ].map((item, itemIdx) => {
        const input = (currentItem) => (
          <Fragment key={itemIdx}>
            {!currentItem.select ? (
              <TextField
                id={`outlined-basic-${currentItem.name}`}
                label={currentItem.label}
                name={currentItem.name}
                value={form[currentItem.name] || ""}
                onChange={onChangeTxt}
                multiline={currentItem.multiline}
              />
            ) : (
              <Select
                options={currentItem.options}
                onChange={(evt, val) =>
                  onSelectOption(
                    evt,
                    val?.[currentItem?.key],
                    currentItem.param
                  )
                }
                label={currentItem.label}
                item={currentItem.option}
                value={
                  currentItem.options.find(
                    (it) => form?.[currentItem.param] === it[currentItem.key]
                  ) || ""
                }
              />
            )}
          </Fragment>
        );

        return Array.isArray(item) ? (
          <Box sx={{ ...styleInputsWrapper(item) }} key={itemIdx}>
            {item.map((item2, item2Idx) => (
              <Fragment key={item2Idx}>{input(item2)}</Fragment>
            ))}
          </Box>
        ) : (
          input(item)
        );
      })}
      {(form?.type === "SINGLE_SELECT" || form?.type === "MULTI_SELECT") && (
        <>
          <Divider />
          <DragDrop
            {...{ onDragEnd }}
            data={form?.answers}
            content={(answer, answerIdx) => (
              <>
                {[
                  { label: t.answer, name: "answer" },
                  {
                    label: t.description,
                    name: "description",
                    multiline: true,
                  },
                ].map((item, itIdx) => (
                  <Fragment key={itIdx}>
                    <TextField
                      id={`outlined-basic-${item.name}`}
                      label={item.label}
                      name={item.name}
                      value={answer[item.name] || ""}
                      onChange={(evt) => onChangeSubTxt(evt, answerIdx)}
                      multiline={item.multiline}
                    />
                  </Fragment>
                ))}
                <IconButton
                  onClick={() => onRemoveAnswerContainer(answerIdx)}
                  sx={{ ...styleIconBtn }}
                >
                  <RemoveIcon sx={{ pointerEvents: "none" }} />
                </IconButton>
              </>
            )}
            droppableTopContent={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                {t.answers}:
                <IconButton
                  onClick={onAddAnswerContainer}
                  sx={{
                    ...styleIconBtn,
                    marginLeft: "auto",
                    display: "flex",
                    width: "5em",
                  }}
                >
                  <AddIcon sx={{ pointerEvents: "none" }} />
                </IconButton>
              </div>
            }
          />
        </>
      )}
    </Box>
  );
};

export default QuestionForm;
