const en = {
  home: "Project Overview",
  healthChecks: "Health Checks",
  reports: "Reports",
  admin: "Administrator",
  categories: "Categories",
  questions: "Questions",
  templates: "Templates",
  name: "Name",
  email: "Email",
  phone: "Phone",
  comments: "Comments",
  question: "Question",
  type: "Type",
  description: "Description",
  count: "Count",
  print: "Print",
  add: (name) => `Add ${name}`,
  previous: "Previous",
  tag: "Tag",
  key: "Key",
  value: "Value",
  variables: "Variables",
  variable: "Variable",
  repository: "Repository",
  secrets: "Secrets",
  basics: "Basics",
  git: "Git",
  errorMsg:
    "We're experiencing a hiccup on our end. Please bear with us while we resolve the issue.",
  registrySecrets: "Registry Credentials",
  clusterStack: "Cluster Stack",
  clusterStore: "Cluster Store",
  server: "Server",
  separateEachElementInfoMsg:
    "Separate elements with commas or place each on a new line.",
  buildpack: "Build Pack",
  id: "ID",
  buildImage: "Build Image",
  build: "Build",
  buildNum: "Build Number",
  buildCounter: "Build Counter",
  latestBuildReason: "Build Reason",
  run: "Run",
  serviceAccount: "Service Account",
  runImage: "Run Image",
  revision: "Revision / Branch",
  healthCheck: "Health Check",
  category: "Category",
  answers: "Answers",
  answer: "Answer",
  edit: (name) => `Edit ${name}`,
  delete: (name) => `Delete ${name}`,
  open: (name) => `Open ${name}`,
  operation: "Operation",
  addNew: "Add new",
  import: "Import",
  export: "Export",
  transferData: "Transfer CSV Data",
  close: "Close",
  save: "Save",
  downloadFile: "Download File",
  downloadLogs: "Download Logs",
  users: "Users",
  user: "User",
  username: "Username",
  role: "Role",
  password: "Password",
  givenName: "Given name",
  familyName: "Family name",
  template: "Template",
  industries: "Industries",
  industry: "Industry",
  noResult: "No result",
  questionnaire: "Questionnaire",
  customized: "Customized",
  standard: "Standard",
  next: "Next",
  myProfile: "My Profile",
  logout: "Logout",
  steps: "Step by Step",
  all: "All",
  back: "Back",
  signin: "Sign in",
  contributor: "Contributor",
  readOnly: "Read-Only",
  userRights: "User Rights",
  continue2: "to continue to Clue2Solve",
  status: "Status",
  statusMsg: "Status message",
  completed: "completed",
  autosave: "Autosave",
  required: "Required",
  create: "Create",
  newCredentials: "New credentials",
  newRegistry: "New Registry",
  credentials: "Credentials",
  configurations: "Configurations",
  configs: "Configs",
  configsAndSecrets: "Configs & Secrets",
  view: "View",
  login: "Login",
  builds: "Builds",
  builds2: "Builds V2",
  logs: "Logs",
  number: "Number",
  image: "Image",
  latestImage: "Latest Image",
  namespace: "Namespace",
  succeeded: "Succeeded",
  reviewPlusCreate: "Review + create",
  builders: "Builders",
  images: "Images",
  serviceAccounts: "Service Accounts",
  gitSecrets: "Git Credentials",
  items: "Items",
  registries: "Registries",
  metrics: "Metrics",
  registry: "Registry",
  clusterStacks: "Cluster Stacks",
  clusterStores: "Cluster Stores",
  created: "Created",
  createdBy: "Created by",
  createdOn: "Created on",
  resourceVersion: "Resource Version",
  source: "Source",
  version: "Version",
  url: "URL",
  generation: "Generation",
  forceBuild: "Force Build",
  secretsCount: "secretsCount",
  imagePullSecretsCount: "imagePullSecretsCount",
  json: "JSON",
  update: "Update",
  public: "Public",
  private: "Private",
  accessType: "Access Type",
  destroy: "Delete",
  confirm: "Confirm",
  extendedlWizard: "Extended Wizard",
  wizard: "Create a new build",
  group: "Group",
  groupName: "Group Name",
  appGroup: "Application Group",
  appGroupN: "Application Group Name",
  manageAppGroups: "Manage Application Groups",
  selectAppGroup: "Select Application Group",
  newAppGroup: "New Application Group",
  noAppGroup: "No Application Group",
  manageApplications: "Manage Applications",
  manage: "Manage",
  platformHostedRegistry: "Platform Hosted Registry",
  deployAutomatically: "Deploy Automatically",
  deployNewDb: "Deploy new database",
  database: "Database",
  service: "Service",
  services: "Services",
  deployment: "Deployment",
  containerPort: "Container Port",
  port: "Port",
  host: "Host",
};

export default en;
