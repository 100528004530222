import React, { useState, useEffect } from "react";
import { CopyBlock, dracula } from "react-code-blocks";

import withErrorHandling from "../../HOC/ErrorHandling";
import { useSharedProps } from "../../HOC/SharedProps";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CodeIcon from "@mui/icons-material/Code";
import DeleteIcon from "@mui/icons-material/Delete";

import { Box, IconButton } from "@mui/material";

import t from "../../helpers/en";
import { fetchLogs, getBuilds, deleteBuild } from "../../helpers/utils";
import { AUDIT_LOGS, DELETE_ITEM, JSON_ITEM } from "../../helpers/constants";
import { filterRecords } from "../../helpers/selectors";

import Table from "../../components/Table/Table";
import Modal from "../../components/Modal/Modal";

import { styleIconBtn, styleEllipsis } from "../../GeneralStyles";

const rowsParams = (data) => ({
  header: [t.name, t.number, t.image, t.namespace, t.succeeded],
  body: data?.filtered,
  bodyCount: data?.filtered,
  bodyParams: (val) => [
    {
      value: val?.metadata?.displayName ?? val?.metadata?.name,
    },
    { value: val?.metadata?.generation },
    {
      value: val?.status?.latestImage,
      specialDetails: {
        hasTooltip: true,
        isUrl: true,
        ellipsis: { maxWidth: "10em", ...styleEllipsis },
      },
    },
    {
      value: val?.metadata?.namespace,
    },
    {
      value: val?.status?.conditions?.[0]?.status,
    },
  ],
});

const ViewBuilds = (props) => {
  const { showError } = props;

  const [data, setData] = useState({
    all: [],
    filtered: [],
  });
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const [form, setForm] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [pressedBtn, setPressedBtn] = useState(null);

  const { currentGroup } = useSharedProps();

  const fetchData = (cb, onError) => {
    getBuilds(null, (result, err) => {
      if (result) {
        setData({ all: result, filtered: result });
      } else {
        onError(err);
      }

      cb && cb(result);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData(() => setIsLoading(false), showError);
  }, [showError, currentGroup]);

  const onOpen = (evt, row) => {
    const currentTargetID = evt.target.id;

    if (currentTargetID === AUDIT_LOGS) {
      fetchLogs(
        {
          ...row,
          imageName: row?.metadata?.name,
          buildNumber: row?.metadata?.generation,
          namespace: row?.metadata?.namespace,
        },
        (result, err) => {
          if (!err) {
            setForm(result);
            setOpen(true);
          } else {
            showError(err);
          }
        }
      );
    } else {
      const modalData = { ...row };

      setForm(modalData);
      setOpen(true);
    }

    setPressedBtn(currentTargetID);
  };

  const handleClose = () => {
    setOpen(false);
    setForm({});
  };

  const onChangeRowsPerPage = (evt) => {
    setRowsPerPage(parseInt(evt.target.value, 10));
    setPage(0);
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onFilterRecords = (result, currentFilters) => {
    const res = filterRecords(result, currentFilters, (item) => {
      return [item?.metadata?.name];
    });

    setData(res);
  };

  const onKeyPressSearch = (evt) => {
    evt.preventDefault();

    if (evt.key === "Enter") {
      onFilterRecords(data?.all, filters);
      setPage(0);
    }
  };

  const onChangeSearchTxt = (evt) => {
    const { value } = evt.target;

    setFilters({ ...filters, searchedValue: value });
  };

  const onClearSearchTxt = () => {
    const copyFilters = { ...filters, searchedValue: null };

    setFilters(copyFilters);
    onFilterRecords(data?.all, copyFilters);
  };
  /*
  const onPrint = () => {
    const element = document.createElement("a");
    const file = new Blob([document.getElementById("logs").innerText], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "logs.log";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
*/
  const onDelete = (evt, row) => {
    deleteBuild(row.metadata.name, (result, err) => {
      if (!err) {
        triggerUpdate();
      } else {
        showError(err);
      }
    });
  };

  const triggerUpdate = () => {
    fetchData((result) => {
      onFilterRecords(result, filters);
    }, showError);
    handleClose();
  };

  let title, content, saveLabel, onConfirmModalAction;

  switch (pressedBtn) {
    case JSON_ITEM:
      title = t.json;
      content = (
        <Box>
          <CopyBlock
            customStyle={{
              height: "550px",
              width: "100%",
              overflow: "scroll",
            }}
            text={JSON.stringify(form, null, 4)}
            language={"en"}
            showLineNumbers={true}
            theme={dracula}
          />
        </Box>
      );
      break;
    case AUDIT_LOGS:
      title = t.logs;
      saveLabel = t.downloadLogs;
      // onConfirmModalAction = onPrint;
      content = (
        <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
          <code id="logs">{form}</code>
        </pre>
      );
      break;
    case DELETE_ITEM:
      title = t.delete(t.build);
      onConfirmModalAction = (e) => onDelete(e, form);
      saveLabel = t.confirm;
      break;
    default:
  }

  return (
    <Box>
      <Modal
        {...{ open }}
        handleClose={handleClose}
        title={title}
        closeLabel={t.close}
        onSave={onConfirmModalAction}
        {...{ content, saveLabel }}
      />
      <Table
        rows={{
          ...(rowsParams && rowsParams(data)),
          body: data.filtered.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          ),
        }}
        selectedRow={(item) => item.id === form.id}
        actionBtns={(item) => (
          <>
            <IconButton
              sx={{ ...styleIconBtn, mr: 2 }}
              title={t.json}
              id={JSON_ITEM}
              onClick={(evt) => onOpen(evt, item)}
            >
              <CodeIcon sx={{ pointerEvents: "none" }} />
            </IconButton>
            <IconButton
              sx={{ ...styleIconBtn, mr: 2 }}
              title={`${t.view} ${t.logs}`}
              id={AUDIT_LOGS}
              onClick={(evt) => onOpen(evt, item)}
            >
              <ArrowRightAltIcon sx={{ pointerEvents: "none" }} />
            </IconButton>
            <IconButton
              sx={styleIconBtn}
              title={t.destroy}
              id={DELETE_ITEM}
              onClick={(evt) => onOpen(evt, item)}
              disabled={currentGroup?.id && !currentGroup?.isContributor2Group}
            >
              <DeleteIcon sx={{ pointerEvents: "none" }} />
            </IconButton>
          </>
        )}
        {...{ onChangeSearchTxt, onKeyPressSearch, onClearSearchTxt }}
        searchedValue={filters?.searchedValue}
        {...{
          page,
          rowsPerPage,
          onChangePage,
          onChangeRowsPerPage,
          isLoading,
        }}
      />
    </Box>
  );
};

export default withErrorHandling(ViewBuilds);
