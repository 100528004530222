import React, { useState } from "react";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { Box, TextField, InputAdornment, IconButton } from "@mui/material";

import { TYPE_DB, SOURCES_DB } from "../helpers/constants";
import t from "../helpers/en";

import Select from "./Inputs/Select";

const DbFields = (props) => {
  const { onSelectOption, onChangeTxt, form, specificDetails } = props;

  const [isPwdVisible, setisPwdVisible] = useState(false);

  const onToggleShowPassword = () => {
    setisPwdVisible((prev) => !prev);
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridRowGap: "1rem",
        ...specificDetails?.styles,
      }}
    >
      <Select
        options={SOURCES_DB}
        onChange={(evt, val) => onSelectOption(evt, val, "db_source")}
        label={`${t.source}`}
        item="id"
        value={SOURCES_DB?.find((it) => it.id === form?.db_source) || ""}
      />
      <Select
        options={TYPE_DB}
        onChange={(evt, val) => onSelectOption(evt, val, "db_type")}
        label={`${t.type}`}
        item="id"
        value={TYPE_DB?.find((it) => it.id === form?.db_type) || ""}
      />
      <TextField
        id="outlined-basic-db_name"
        label={`${t.name}`}
        name="db_name"
        value={form?.db_name || ""}
        onChange={onChangeTxt}
      />
      <TextField
        id="outlined-basic-container_db_pwd"
        label={`${t.password}`}
        name="db_pwd"
        type={isPwdVisible ? "text" : "password"}
        value={form?.db_pwd || ""}
        onChange={onChangeTxt}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={onToggleShowPassword}
                edge="end"
              >
                {form?.isPwdVisible ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default DbFields;
