import React, { Fragment, useEffect, useState } from "react";

import withErrorHandling from "../../HOC/ErrorHandling";
import { useSharedProps } from "../../HOC/SharedProps";

import {
  Step,
  Stepper,
  StepButton,
  Box,
  Button,
  Typography,
  TextField,
} from "@mui/material";

import t from "../../helpers/en";
import {
  getGitSecrets,
  setBuild,
  getRegistrySecrets,
} from "../../helpers/utils";
import {
  GIT_NEW_CREDENTIALS,
  REGISTRY_NEW_CREDENTIALS,
} from "../../helpers/constants";
import { changeTxt, selectOption, string2Array } from "../../helpers/selectors";

import Select from "../../components/Inputs/Select";

const styleSteps = {
  "& .MuiStepConnector-root, .MuiStepLabel-iconContainer": {
    display: "none",
  },
  marginBottom: "1.5rem",
};
const styleStepBtn = {
  "& .MuiButtonBase-root": {
    m: 0,
    p: 0,
  },
  "&.MuiStep-root": {
    paddingLeft: "0 !important",
  },
};

const sectionLabel = {
  fontSize: "0.875rem",
  opacity: 0.7,
};

const steps = [
  t.basics,
  t.git,
  t.registrySecrets,
  t.clusterStack,
  t.clusterStore,
  t.buildpack,
  t.reviewPlusCreate,
];

const BasicsContent = ({ form, onChangeTxt }) => (
  <>
    <TextField
      id={`outlined-basic-name`}
      label={t.name}
      name="displayName"
      value={form?.displayName || ""}
      onChange={onChangeTxt}
    />
    <TextField
      id={`outlined-basic-tag`}
      label={t.tag}
      name="tag"
      value={form?.tag || ""}
      onChange={onChangeTxt}
    />
  </>
);
const GitComponent = ({
  form,
  onChangeTxt,
  onSelectOption,
  gitCredentials,
}) => (
  <>
    <TextField
      id="outlined-basic-git_repo"
      label={t.repository}
      name="git_repo"
      value={form?.git_repo || ""}
      onChange={onChangeTxt}
    />
    <TextField
      id="outlined-basic-git_revison"
      label={t.revision}
      name="git_revision"
      value={form?.git_revision || ""}
      onChange={onChangeTxt}
    />
    <Select
      options={gitCredentials}
      onChange={(evt, val) => onSelectOption(evt, val, "git_secrets")}
      label={t.gitSecrets}
      item="name"
      value={gitCredentials?.find((it) => it.id === form?.git_secrets) || ""}
      specificDetails={{
        styles: {
          elemLi: (el) =>
            (el?.default || el?.id === GIT_NEW_CREDENTIALS) && {
              borderTop: "1px solid rgba(0,0,0,0.3)",
            },
        },
      }}
    />
    {form?.git_secrets === GIT_NEW_CREDENTIALS && (
      <Box sx={{ display: "grid", gridRowGap: "1rem", ml: "1.5rem" }}>
        <TextField
          id="outlined-basic-git_secrets_username"
          label={t.username}
          name="git_cred_username"
          value={form?.git_cred_username || ""}
          onChange={onChangeTxt}
        />
        <TextField
          id="outlined-basic-git_secrets_password"
          label={t.password}
          name="git_cred_password"
          value={form?.git_cred_password || ""}
          onChange={onChangeTxt}
        />
      </Box>
    )}
  </>
);
const RegistryComponent = ({
  registryCredentials,
  onSelectOption,
  form,
  onChangeTxt,
}) => (
  <>
    <Select
      options={registryCredentials}
      onChange={(evt, val) => onSelectOption(evt, val, "registry_secrets")}
      label={t.registrySecrets}
      item="name"
      value={
        registryCredentials?.find((it) => it.id === form?.registry_secrets) ||
        ""
      }
      specificDetails={{
        styles: {
          elemLi: (el) =>
            (el?.default || el?.id === REGISTRY_NEW_CREDENTIALS) && {
              borderTop: "1px solid rgba(0,0,0,0.3)",
            },
        },
      }}
    />
    {form?.registry_secrets === REGISTRY_NEW_CREDENTIALS && (
      <Box
        sx={{
          display: "grid",
          gridRowGap: "1rem",
          border: "1px solid rgba(0,0,0,0.2)",
          borderRadius: "0.4rem",
          p: "1rem",
        }}
      >
        <TextField
          id="outlined-basic-registry_secrets_server"
          label={t.server}
          name="registry_cred_server"
          value={form?.registry_cred_server || ""}
          onChange={onChangeTxt}
        />
        <TextField
          id="outlined-basic-registry_secrets_username"
          label={t.username}
          name="registry_cred_username"
          value={form?.registry_cred_username || ""}
          onChange={onChangeTxt}
        />
        <TextField
          id="outlined-basic-registry_secrets_password"
          label={t.password}
          name="registry_cred_password"
          value={form?.registry_cred_password || ""}
          onChange={onChangeTxt}
        />
      </Box>
    )}
  </>
);
const ClusterStackContent = ({ form, onChangeTxt }) => (
  <>
    <TextField
      id={`outlined-basic-cluster_image_id`}
      label={t.id}
      name="clusterImageID"
      value={form?.clusterImageID || ""}
      onChange={onChangeTxt}
    />
    <TextField
      id={`outlined-basic-cluster_build_image`}
      label={t.buildImage}
      name="clusterBuildImage"
      value={form?.clusterBuildImage || ""}
      onChange={onChangeTxt}
    />
    <TextField
      id={`outlined-basic-cluster_run_image`}
      label={t.runImage}
      name="clusterRunImage"
      value={form?.clusterRunImage || ""}
      onChange={onChangeTxt}
    />
  </>
);
const ClusterStoreContent = ({ form, onChangeTxt }) => (
  <TextField
    id="outlined-basic-cluster_store"
    label={t.clusterStore}
    name="clusterStore"
    value={form?.clusterStore || ""}
    onChange={onChangeTxt}
    rows={2}
    multiline={true}
    helperText={t.separateEachElementInfoMsg}
  />
);
const BuildpackContent = ({ form, onChangeTxt }) => (
  <TextField
    id="outlined-basic-buildpack"
    label={t.buildpack}
    name="buildpack"
    value={form?.buildpack || ""}
    onChange={onChangeTxt}
    rows={2}
    multiline={true}
    helperText={t.separateEachElementInfoMsg}
  />
);

const ConfigureBuild = (props) => {
  const { showError } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [form, setForm] = useState({});
  const [gitCredentials, setGitCredentials] = useState([]);
  const [registryCredentials, setRegistryCredentials] = useState({});

  const { currentGroup } = useSharedProps();

  const fetchGitCregential = (onError) => {
    getGitSecrets((result, err) => {
      if (Array.isArray(result)) {
        const updatedResult = [
          ...result.map((it) => ({
            git_cred_username: it?.data?.username,
            git_cred_password: it?.data?.password,
            id: it?.metadata?.name,
            name: it?.metadata?.displayName,
            default: Boolean(it?.metadata?.labels?.default),
          })),
          { id: GIT_NEW_CREDENTIALS, name: t.newCredentials },
        ];

        return setGitCredentials(updatedResult);
      } else {
        onError(err);
      }
    });
  };
  const fetchRegistryCredential = (onError) => {
    getRegistrySecrets((result, err) => {
      if (Array.isArray(result)) {
        const updatedResult = [
          ...result.map((it) => ({
            registry_cred_server: it?.data?.server,
            registry_cred_username: it?.data?.username,
            registry_cred_password: it?.data?.password,
            id: it?.metadata?.name,
            name: it?.metadata?.displayName,
            default: Boolean(it?.metadata?.labels?.default),
          })),
          { id: REGISTRY_NEW_CREDENTIALS, name: t.newRegistry },
        ];

        return setRegistryCredentials(updatedResult);
      } else {
        onError(err);
      }
    });
  };

  useEffect(() => {
    fetchGitCregential(showError);
    fetchRegistryCredential(showError);
  }, [showError, currentGroup]);

  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const go2LastStep = () => {
    const lastStep = steps.length - 1;

    setActiveStep(lastStep);
  };

  const onChangeTxt = (evt) => {
    const result = changeTxt(evt, form);

    setForm(result);
  };

  const onSelectOption = (_, value, param) => {
    let result = selectOption(_, value?.id ?? null, param, form);

    if (param === "git_secrets") {
      result = {
        ...result,
        git_cred_username: value?.git_cred_username,
        git_cred_password: value?.git_cred_password,
        git_cred_name: value?.id,
      };
    } else if (param === "registry_secrets") {
      result = {
        ...result,
        registry_cred_username: value?.registry_cred_username,
        registry_cred_password: value?.registry_cred_password,
        registry_cred_server: value?.registry_cred_server,
        registry_cred_name: value?.id,
      };
    }

    setForm(result);
  };

  const onSave = () => {
    const clusterStoreImages = string2Array(form?.clusterStore);
    const buildpackIds = string2Array(form?.buildpack);

    setBuild({ ...form, clusterStoreImages, buildpackIds }, (result, err) => {
      if (!err) {
        setForm({});
        setActiveStep(0);
      } else {
        showError(err);
      }
    });
  };

  let content;

  if (activeStep === 0) {
    content = <BasicsContent {...{ form, onChangeTxt }} />;
  } else if (activeStep === 1) {
    content = (
      <GitComponent
        {...{ form, onChangeTxt, onSelectOption, gitCredentials }}
      />
    );
  } else if (activeStep === 2) {
    content = (
      <RegistryComponent
        {...{ registryCredentials, onSelectOption, form, onChangeTxt }}
      />
    );
  } else if (activeStep === 3) {
    content = <ClusterStackContent {...{ form, onChangeTxt }} />;
  } else if (activeStep === 4) {
    content = <ClusterStoreContent {...{ form, onChangeTxt }} />;
  } else if (activeStep === 5) {
    content = <BuildpackContent {...{ form, onChangeTxt }} />;
  } else {
    content = (
      <>
        <Typography sx={sectionLabel}>{t.basics}</Typography>
        <BasicsContent {...{ form, onChangeTxt }} />
        <Typography sx={sectionLabel}>{t.git}</Typography>
        <GitComponent
          {...{ form, onChangeTxt, onSelectOption, gitCredentials }}
        />
        <Typography sx={sectionLabel}>{t.registrySecrets}</Typography>
        <RegistryComponent
          {...{ registryCredentials, onSelectOption, form, onChangeTxt }}
        />
        <Typography sx={sectionLabel}>{t.clusterStack}</Typography>
        <ClusterStackContent {...{ form, onChangeTxt }} />
        <Typography sx={sectionLabel}>{t.clusterStore}</Typography>
        <ClusterStoreContent {...{ form, onChangeTxt }} />
        <Typography sx={sectionLabel}>{t.buildpack}</Typography>
        <BuildpackContent {...{ form, onChangeTxt }} />
      </>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper nonLinear sx={styleSteps} activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step sx={styleStepBtn} key={label}>
            <StepButton
              sx={styleStepBtn}
              color="inherit"
              onClick={handleStep(index)}
            >
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Fragment>
        <div style={{ display: "grid", gridRowGap: "1rem" }}>{content}</div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 2,
            position: "sticky",
            bottom: 0,
            background: "#fff",
            padding: "1rem 1rem 0.5rem 1rem",
            margin: "1rem -1rem 0 -1rem",
            border: "1px solid rgba(0, 0, 0, .05)",
            borderBottom: "none",
            borderRadius: ".4rem",
            zIndex: 1,
          }}
        >
          {activeStep !== 0 && (
            <Button
              variant="outlined"
              color="inherit"
              onClick={handleBack}
              sx={{ mr: 1, textTransform: "none" }}
            >
              {t.previous}
            </Button>
          )}
          {steps.length > activeStep + 1 && (
            <Button
              variant="outlined"
              color="inherit"
              onClick={handleNext}
              sx={{ mr: 1, textTransform: "none" }}
            >
              {t.next}: {steps[activeStep + 1]}
            </Button>
          )}
          <Button
            variant="contained"
            sx={{ textTransform: "none" }}
            onClick={activeStep !== steps.length - 1 ? go2LastStep : onSave}
          >
            {activeStep !== steps.length - 1 ? t.reviewPlusCreate : t.save}
          </Button>
        </Box>
      </Fragment>
    </Box>
  );
};

export default withErrorHandling(ConfigureBuild);
