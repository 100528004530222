import React, { useState, useEffect, Fragment, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, Divider, IconButton, TextField } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

import {
  getCustomers,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  getQuestionnaire,
  getTemplates,
  getCategories,
  getQuestions,
  createQuestionnaire,
  updateQuestionnaire,
  deleteQuestionnaire,
} from "../../helpers/utils";
import t from "../../helpers/en";
import {
  filterRecords,
  changeTxt,
  selectOption,
  selectMultipleSubOption,
  addAnswerContainer,
  removeAnswerContainer,
  dragEnd,
} from "../../helpers/selectors";
import {
  ADD_QUESTIONNAIRE,
  EDIT_QUESTIONNAIRE,
  ADD_CUSTOMER,
  INITIAL_STATE,
} from "../../helpers/constants";
import withErrorHandling from "../../HOC/ErrorHandling";

import Table from "../../components/Table/Table";
import Modal from "../../components/Modal/Modal";
import QuestionnaireForm from "../../components/QuestionnaireForm";

import { styleIconBtn, styleInputsWrapper } from "../../GeneralStyles";

const EDIT_CUSTOMER = "EDIT_CUSTOMER";
const DELETE_CUSTOMER = "DELETE_CUSTOMER";
const DELETE_QUESTIONNAIRE = "DELETE_QUESTIONNAIRE";
const REDIRECT_2_QUESTIONNAIRE = "REDIRECT_2_QUESTIONNAIRE";

const HealthChecks = (props) => {
  const { showError } = props;

  const [data, setData] = useState({ all: [], filtered: [] });
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState({});
  const [pressedBtn, setPressedBtn] = useState(null);
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [templates, setTemplates] = useState([]);
  const [categories, setCategories] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [collapsible, setCollapsible] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const onOpen = (evt, row) => {
    const { id } = evt.target;
    let modalData = {};

    if (id === EDIT_CUSTOMER || id === DELETE_CUSTOMER) {
      modalData = {
        ...row,
        name: row.contactPersonName,
        email: row.contactPersonEmail,
        phone: row.contactPersonPhoneNumber,
      };
    } else if (id === ADD_QUESTIONNAIRE) {
      modalData = {
        customerId: row.id,
        questions: [INITIAL_STATE],
      };
    } else if (id === EDIT_QUESTIONNAIRE || id === DELETE_QUESTIONNAIRE) {
      modalData = {
        ...row,
      };
    } else if (id === ADD_CUSTOMER) {
      modalData = {
        questions: [INITIAL_STATE],
      };
    }

    setForm(modalData);
    setPressedBtn(id);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setForm({});
  };

  const onChangeTxt = (evt) => {
    const result = changeTxt(evt, form);

    setForm(result);
  };

  const fetchData = useCallback((cb) => {
    const data1 = new Promise((resolve, reject) => {
      getCustomers((result1, errorMsg) => {
        if (!errorMsg) {
          resolve(result1);
        } else {
          reject(errorMsg);
        }
      });
    });
    const data2 = new Promise((resolve, reject) => {
      getQuestionnaire(null, (result2, errorMsg) => {
        if (!errorMsg) {
          resolve(result2);
        } else {
          reject(errorMsg);
        }
      });
    });

    Promise.all([data1, data2])
      .then(([result1, result2]) => {
        let finalCollapsible = {};
        const combinedResult = result1
          ? result1?.map((item) => {
              const findQuestionnaire = result2?.filter(
                (it) => it.customerId === item.id
              );
              finalCollapsible = {
                ...finalCollapsible,
                ...(findQuestionnaire?.length > 0
                  ? { [item.id]: true }
                  : { findQuestionnaire }),
              };

              return {
                ...item,
                questionnaires: [...findQuestionnaire],
              };
            })
          : [];

        setData({ all: combinedResult, filtered: combinedResult });
        setCollapsible(finalCollapsible);
        cb && cb(combinedResult);
      })
      .catch((err) => {
        cb(null, err);
      });
  }, []);

  const onFilterRecords = (result, currentFilters) => {
    const res = filterRecords(result, currentFilters, (item) => [
      item.contactPersonName,
      item.contactPersonEmail,
      item.contactPersonPhoneNumber,
    ]);

    setData(res);
  };

  useEffect(() => {
    setIsLoading(true);
    const data1 = new Promise((resolve, reject) => {
      getCategories((result, errorMsg) => {
        if (!errorMsg) {
          setCategories(result);
          resolve(result);
        } else {
          reject(errorMsg);
        }
      });
    });
    const data2 = new Promise((resolve, reject) => {
      getQuestions((result, errorMsg) => {
        if (!errorMsg) {
          setQuestions(result);
          resolve(result);
        } else {
          reject(errorMsg);
        }
      });
    });
    const data3 = new Promise((resolve, reject) => {
      getTemplates((result, errorMsg) => {
        if (!errorMsg) {
          setTemplates(result);
          resolve(result);
        } else {
          reject(errorMsg);
        }
      });
    });
    const data4 = new Promise((resolve, reject) => {
      fetchData((result, errorMsg) => {
        if (!errorMsg) {
          resolve(result);
        } else {
          reject(errorMsg);
        }
      });
    });

    Promise.all([data1, data2, data3, data4])
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        showError(err);
        setIsLoading(false);
      });
  }, [fetchData, showError]);

  const rows = {
    header: [t.name, t.email, t.phone, t.comments, t.count],
    body: data?.filtered,
    bodyCount: data?.filtered,
    bodyParams: (val) => [
      { value: val.contactPersonName },
      { value: val.contactPersonEmail },
      { value: val.contactPersonPhoneNumber },
      { value: val.comments },
      { value: val?.questionnaires?.length, isCollapsible: true },
    ],
    subheader: [t.healthCheck],
    subBody: (val) => val?.questionnaires,
    subbodyParams: (val) => [
      templates.find((it) => val.templateId === it.id)?.name,
    ],
  };

  const triggerUpdate = (currentResult, errorMsg) => {
    if (!errorMsg) {
      fetchData((result) => {
        onFilterRecords(result, filters);
      });
      setOpen(false);
      setForm({});
    } else {
      showError(errorMsg);
    }
  };

  const onSave = (evt) => {
    evt.preventDefault();

    if (form.id) {
      if (pressedBtn === EDIT_CUSTOMER) {
        updateCustomer(form, (_, errorMsg) => {
          triggerUpdate(_, errorMsg);
        });
      } else if (pressedBtn === DELETE_CUSTOMER) {
        deleteCustomer(form, (_, errorMsg) => {
          triggerUpdate(_, errorMsg);
        });
      }
    } else {
      new Promise((resolve, reject) => {
        createCustomer(form, (result, errorMsg) => {
          if (!errorMsg) {
            resolve(result);
          } else {
            reject(errorMsg);
          }
        });
      })
        .then((res) => {
          if (form.questions?.length > 0) {
            createQuestionnaire(
              { ...form, customerId: res.id },
              (result, err) => {
                triggerUpdate(result, err);
              }
            );
          }
        })
        .catch((err) => showError(err));
    }
  };

  const onSaveQuestionnnaire = (evt) => {
    evt.preventDefault();

    if (form.id) {
      if (pressedBtn === EDIT_QUESTIONNAIRE) {
        updateQuestionnaire(form, (_, errorMsg) => {
          triggerUpdate(_, errorMsg);
        });
      } else if (pressedBtn === DELETE_QUESTIONNAIRE) {
        deleteQuestionnaire(form, (_, errorMsg) => {
          triggerUpdate(_, errorMsg);
        });
      }
    } else {
      createQuestionnaire(form, (_, errorMsg) => {
        triggerUpdate(_, errorMsg);
      });
    }
  };

  const onChangeSearchTxt = (evt) => {
    const { value } = evt.target;

    setFilters({ ...filters, searchedValue: value });
  };

  const onClearSearchTxt = () => {
    const copyFilters = { ...filters, searchedValue: null };

    setFilters(copyFilters);
    onFilterRecords(data?.all, copyFilters);
  };

  const onKeyPressSearch = (evt) => {
    evt.preventDefault();

    if (evt.key === "Enter") {
      onFilterRecords(data?.all, filters);
      setPage(0);
    }
  };

  const onChangeRowsPerPage = (evt) => {
    setRowsPerPage(parseInt(evt.target.value, 10));
    setPage(0);
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onSelectOption = (_, value, param, wholeItem) => {
    let result = selectOption(_, value, param, form);

    if (param === "templateId") {
      //Assign the questions from the template to the form array based on the templateId
      result = {
        ...result,
        questions: wholeItem?.questions ?? [INITIAL_STATE],
      };
    }

    setForm(result);
  };

  const onSelectMultipleSubOption = (_, value, param, idx, item) => {
    let result = selectMultipleSubOption(
      _,
      value,
      param,
      idx,
      form,
      "questions"
    );
    if (param === "questionId") {
      result.questions[idx] = {
        ...result.questions[idx],
        categoryId: item?.categoryId,
      };
    }
    setForm(result);
  };

  const onAddAnswerContainer = () => {
    const result = addAnswerContainer(form, "questions");
    setForm(result);
  };

  const onRemoveAnswerContainer = (idx) => {
    const result = removeAnswerContainer(form, idx, "questions");
    setForm(result);
  };

  function onDragEnd(result) {
    const res = dragEnd(form, result, "questions");

    if (res) {
      setForm(res);
    }
  }

  const onCollapse = (item) => {
    let copy = { ...collapsible };
    copy[item.id] = !copy[item.id];

    setCollapsible(copy);
  };

  const onRedirect2Questionnaire = (id) => {
    navigate(`/questionnaire/${id}`);
  };

  let title, content;
  let onConfirmModal = (evt) => onSave(evt);

  if (pressedBtn === ADD_CUSTOMER) {
    title = t.add(t.healthCheck);
  } else if (pressedBtn === EDIT_CUSTOMER) {
    title = t.edit(t.healthCheck);
  } else if (pressedBtn === DELETE_CUSTOMER) {
    title = t.delete(t.healthCheck);
  } else if (pressedBtn === ADD_QUESTIONNAIRE) {
    title = t.add(t.questionnaire);
  } else if (pressedBtn === EDIT_QUESTIONNAIRE) {
    title = t.edit(t.questionnaire);
  } else if (pressedBtn === DELETE_QUESTIONNAIRE) {
    title = t.delete(t.questionnaire);
  }

  if (pressedBtn === ADD_QUESTIONNAIRE || pressedBtn === EDIT_QUESTIONNAIRE) {
    content = (
      <QuestionnaireForm
        {...{
          pressedBtn,
          templates,
          form,
          onChangeTxt,
          onDragEnd,
          questions,
          categories,
          onSelectOption,
          onSelectMultipleSubOption,
          onRemoveAnswerContainer,
          onAddAnswerContainer,
        }}
      />
    );
  }

  if (
    pressedBtn === ADD_QUESTIONNAIRE ||
    pressedBtn === EDIT_QUESTIONNAIRE ||
    pressedBtn === DELETE_QUESTIONNAIRE
  ) {
    onConfirmModal = (e) => onSaveQuestionnnaire(e);
  }

  if (pressedBtn === EDIT_CUSTOMER || pressedBtn === ADD_CUSTOMER) {
    content = (
      <Box
        sx={{
          display: "grid",
          rowGap: "1rem",
        }}
      >
        {[
          { label: t.name, name: "name" },
          [
            { label: t.email, name: "email" },
            { label: t.phone, name: "phone" },
          ],
          { label: t.comments, name: "comments", multiline: true },
        ].map((item, itemIdx) => {
          const input = (currentItem) => (
            <Fragment key={currentItem.name}>
              <TextField
                id={`outlined-basic-${currentItem.name}`}
                label={currentItem.label}
                name={currentItem.name}
                value={form[currentItem.name] || ""}
                onChange={onChangeTxt}
                multiline={currentItem.multiline}
              />
            </Fragment>
          );
          return Array.isArray(item) ? (
            <Box sx={{ ...styleInputsWrapper(item) }} key={itemIdx}>
              {item.map((item2, item2Idx) => (
                <Fragment key={item2Idx}>{input(item2)}</Fragment>
              ))}
            </Box>
          ) : (
            input(item)
          );
        })}
        {pressedBtn === ADD_CUSTOMER && (
          <Box>
            <Divider />
            <h4> {t.questionnaire}</h4>
            <QuestionnaireForm
              {...{
                pressedBtn,
                templates,
                form,
                onChangeTxt,
                onDragEnd,
                questions,
                categories,
                onSelectOption,
                onSelectMultipleSubOption,
                onRemoveAnswerContainer,
                onAddAnswerContainer,
              }}
            />
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Box>
      <Modal
        {...{ open }}
        onSave={onConfirmModal}
        handleClose={onClose}
        {...{ title }}
        closeLabel={t.close}
        saveLabel={t.save}
        {...{ content }}
      />
      <Button
        id={ADD_CUSTOMER}
        sx={{ mb: 2 }}
        variant="contained"
        onClick={onOpen}
      >
        {t.addNew}
      </Button>
      <Table
        rows={{
          ...rows,
          body: data.filtered.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          ),
        }}
        selectedRow={(item) => item.id === form.id}
        collapsibleSubTableParamName={"questionnaires"}
        actionBtns={(item) => (
          <>
            <IconButton
              sx={{ ...styleIconBtn, mr: 2 }}
              title={t.edit(t.healthCheck)}
              id={EDIT_CUSTOMER}
              onClick={(evt) => onOpen(evt, item)}
            >
              <EditIcon sx={{ pointerEvents: "none" }} />
            </IconButton>
            <IconButton
              sx={{ ...styleIconBtn, mr: 2 }}
              title={t.add(t.questionnaire)}
              id={ADD_QUESTIONNAIRE}
              onClick={(evt) => onOpen(evt, item)}
            >
              <AddIcon sx={{ pointerEvents: "none " }} />
            </IconButton>
            <IconButton
              sx={styleIconBtn}
              title={t.delete(t.healthCheck)}
              id={DELETE_CUSTOMER}
              onClick={(evt) => onOpen(evt, item)}
            >
              <DeleteIcon sx={{ pointerEvents: "none" }} />
            </IconButton>
          </>
        )}
        subActionBtns={(item) => (
          <>
            <IconButton
              sx={{ ...styleIconBtn, mr: 2 }}
              title={t.edit(t.questionnaire)}
              id={EDIT_QUESTIONNAIRE}
              onClick={(evt) => onOpen(evt, item)}
            >
              <EditIcon sx={{ pointerEvents: "none" }} />
            </IconButton>
            <IconButton
              sx={{ ...styleIconBtn, mr: 2 }}
              title={t.open(t.questionnaire)}
              id={REDIRECT_2_QUESTIONNAIRE}
              onClick={() => onRedirect2Questionnaire(item?.id)}
            >
              <ArrowRightAltIcon sx={{ pointerEvents: "none" }} />
            </IconButton>
            <IconButton
              sx={styleIconBtn}
              title={t.delete(t.questionnaire)}
              id={DELETE_QUESTIONNAIRE}
              onClick={(evt) => onOpen(evt, item)}
            >
              <DeleteIcon sx={{ pointerEvents: "none" }} />
            </IconButton>
          </>
        )}
        {...{ onChangeSearchTxt, onKeyPressSearch, onClearSearchTxt }}
        searchedValue={filters?.searchedValue}
        {...{
          page,
          rowsPerPage,
          onChangePage,
          onChangeRowsPerPage,
          onCollapse,
          collapsible,
          isLoading,
        }}
      />
    </Box>
  );
};

export default withErrorHandling(HealthChecks);
