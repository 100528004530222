import React from "react";

import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

const CheckboxGroup = (props) => {
  const { onChange, data, label, optionValue, checkedValues, specificDetails } =
    props;

  if (specificDetails?.isSingleInput) {
    return (
      <FormControlLabel
        style={{ ...specificDetails?.formControlLabelStyle }}
        control={
          <Checkbox
            sx={{
              ...specificDetails?.checkboxStyle,
            }}
            checked={specificDetails?.checked}
            {...{ onChange }}
            name={specificDetails?.name}
            disabled={specificDetails?.disabled}
          />
        }
      />
    );
  }

  return (
    <FormControl component="fieldset" variant="standard">
      <FormGroup>
        {data.map(
          (item, itemIdx) =>
            !item?.deleted && (
              <FormControlLabel
                key={itemIdx}
                control={
                  <Checkbox
                    checked={checkedValues.includes(item[optionValue])}
                    {...{ onChange }}
                    name={item[optionValue]}
                  />
                }
                label={item[label]}
              />
            )
        )}
      </FormGroup>
    </FormControl>
  );
};

export default CheckboxGroup;
