import React, { useEffect, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useParams } from "react-router-dom";

import { Box, Button } from "@mui/material";

import {
  getQuestionnaire,
  getQuestions,
  getCategories,
  getQuestionnaireAnswers,
} from "../helpers/utils";
import t from "../helpers/en";

import Loading from "../components/Loading";

const ReportDetails = () => {
  const [data, setData] = useState({});
  const [answersQuestionnaire, setAnswersQuestionnaire] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { customerQuestionnaireId } = useParams();

  useEffect(() => {
    setIsLoading(true);
    getQuestionnaire(customerQuestionnaireId, (result1) => {
      getQuestionnaireAnswers(
        customerQuestionnaireId,
        (allAnsweredQuestions) => {
          const finalAllAnsweredQuestions = allAnsweredQuestions?.map((it) => ({
            id: it.id,
            questionId: it.questionId,
            answerId: it.answerId,
            value: it.value,
          }));

          getCategories((result2) => {
            getQuestions((result3) => {
              let copyCompleted = {};
              const combinedResult = result1?.questions?.reduce((acc, item) => {
                const { categoryId } = item;
                const findItem = result3.find(
                  (it) => it.id === item.questionId
                );
                const findAnsweredQuestion = allAnsweredQuestions.find(
                  (it) => it.questionId === item.id
                );

                if (findAnsweredQuestion) {
                  if (copyCompleted[categoryId]) {
                    copyCompleted[categoryId] = [
                      ...copyCompleted[categoryId],
                      findAnsweredQuestion,
                    ];
                  } else {
                    copyCompleted = {
                      ...copyCompleted,
                      [categoryId]: [findAnsweredQuestion],
                    };
                  }
                }

                const findCategory = result2.find((it) => it.id === categoryId);

                if (!acc[categoryId]) {
                  acc[categoryId] = {
                    questions: [],
                    category_name: findCategory.name,
                  };
                }

                acc[categoryId].questions.push({
                  ...item,
                  answers: findItem?.answers,
                  question: findItem?.question,
                  type: findItem?.type,
                });

                return acc;
              }, {});

              setAnswersQuestionnaire(finalAllAnsweredQuestions);
              setData(combinedResult);
              setIsLoading(false);
            });
          });
        }
      );
    });
  }, [customerQuestionnaireId]);

  const onPrint = () => {
    const elem = document.getElementById("component2Print");

    html2canvas(elem).then((canvas) => {
      const pageHeight = 297; // A4 page height in mm
      const imgWidth = 208;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight - pageHeight;
      let position = 0;
      const doc = new jsPDF();

      doc.addImage(canvas, "PNG", 0, position, imgWidth, imgHeight);
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(canvas, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save("report.pdf");
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box>
      <Button style={{ marginBottom: "1.5rem" }} onClick={onPrint}>
        {t.print}
      </Button>
      <div style={{ padding: ".5rem" }} id="component2Print">
        {Object.entries(data).map(([key, item], itemIdx) => {
          return (
            <Box key={itemIdx} style={{ marginBottom: "1.5rem" }}>
              <span
                style={{
                  display: "flex",
                  fontWeight: 700,
                  marginBottom: "1rem",
                }}
              >
                {[
                  `${t.category} ${itemIdx + 1}:`,
                  item.category_name,
                  `(${item.questions?.length})`,
                ].join(" ")}
              </span>
              {item?.questions.map((it, itIdx) => {
                const findAnswers = answersQuestionnaire.filter(
                  (val) => val.questionId === it.id
                );

                return (
                  <span key={itIdx}>
                    {[`${itIdx + 1}. `, it?.question].join(" ")}
                    <ul>
                      {findAnswers?.map((answer, answerIdx) => {
                        const findElem = it?.answers?.find(
                          (currentVal) => currentVal.id === answer.answerId
                        );

                        return (
                          findElem && (
                            <li key={answerIdx}>
                              {answer?.value || findElem?.answer}
                            </li>
                          )
                        );
                      })}
                    </ul>
                  </span>
                );
              })}
            </Box>
          );
        })}
      </div>
    </Box>
  );
};

export default ReportDetails;
