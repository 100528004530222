import React from "react";
import { useLocation, Outlet, Navigate } from "react-router-dom";

const ProtectedRoutes = () => {
  const location = useLocation();

  return sessionStorage.getItem("token") ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};

export default ProtectedRoutes;
