import React, { useState, useEffect, Fragment } from "react";

import withErrorHandling from "../HOC/ErrorHandling";

import {
  Box,
  Button,
  IconButton,
  TextField,
  Divider,
  Tabs,
  Tab,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import {
  getCategories,
  createCategory,
  updateCategory,
  deleteCategory,
  createQuestion,
  updateQuestion,
  getQuestions,
} from "../helpers/utils";
import t from "../helpers/en";
import { INITIAL_STATE, TEST_ID, INPUT_TYPES } from "../helpers/constants";
import { reorder, changeTxt, filterRecords } from "../helpers/selectors";

import Table from "../components/Table/Table";
import Modal from "../components/Modal/Modal";
import Select from "../components/Inputs/Select";
import DragDrop from "../components/DragDrop";

import { styleIconBtn, styleInputsWrapper } from "../GeneralStyles";

const EDIT_CATEGORY = "EDIT_CATEGORY";
const DELETE_CATEGORY = "DELETE_CATEGORY";
const ADD_CATEGORY = "ADD_CATEGORY";
const EDIT_CATEGORY_QUESTIONS = "EDIT_CATEGORY_QUESTIONS";

const Categories = (props) => {
  const { showError } = props;

  const [data, setData] = useState({ all: [], filtered: [] });
  const [questions, setQuestions] = useState([]);
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState({});
  const [pressedBtn, setPressedBtn] = useState(null);
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [collapsible, setCollapsible] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);

  const onOpen = (evt, row) => {
    const { id } = evt.target;
    let modalData = {};

    if (
      id === EDIT_CATEGORY ||
      id === DELETE_CATEGORY ||
      id === EDIT_CATEGORY_QUESTIONS
    ) {
      modalData = { ...row };
    }

    setForm(modalData);
    setPressedBtn(id);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setForm({});
  };

  const onChangeTxt = (evt) => {
    const result = changeTxt(evt, form);

    setForm(result);
  };

  const onChangeQuestionTxt = (evt, idx) => {
    const { name, value } = evt.target;
    const cpy = [...form.questions];
    cpy[idx] = { ...cpy[idx], [name]: value };

    setForm({ ...form, questions: cpy });
  };

  const onChangeAnswerSubTxt = (evt, questionIdx, answerIdx) => {
    const { name, value } = evt.target;
    let cpy = [...form.questions];

    cpy[questionIdx].answers[answerIdx] = {
      ...cpy[questionIdx].answers[answerIdx],
      [name]: value,
    };

    setForm({ ...form, questions: cpy });
  };

  const onSelectQuestionOption = (_, value, param, questionIdx) => {
    let cpy = [...form.questions];

    cpy[questionIdx] = { ...cpy[questionIdx], [param]: value };

    setForm({ ...form, questions: cpy });
  };

  const onSelectQuestionOptionStandard = (_, value, questionIdx) => {
    let cpy = [...form.questions];

    cpy[questionIdx] = { ...value, categoryId: form.id };

    setForm({ ...form, questions: cpy });
  };

  const fetchData = (cb, onError) => {
    const data1 = new Promise((resolve) => {
      getCategories((result, err) => {
        if (!err) {
          resolve(result);
        } else {
          onError(err);
        }
      });
    });
    const data2 = new Promise((resolve) => {
      getQuestions((result2, err) => {
        if (!err) {
          resolve(result2);
        } else {
          onError(err);
        }
      });
    });

    Promise.all([data1, data2]).then(([result1, result2]) => {
      const combinedResult = result1.reduce((acc, category) => {
        const questions = result2.filter(
          (question) => question.categoryId === category.id
        );

        acc[category.id] = { ...category, questions };

        return acc;
      }, {});
      const combinedResultVal = Object.values(combinedResult);

      setData({
        all: combinedResultVal,
        filtered: combinedResultVal,
      });
      setQuestions(result2);

      cb && cb(combinedResultVal);
    });
  };

  const onFilterRecords = (result, currentFilters) => {
    const res = filterRecords(result, currentFilters, (item) => [item.name]);

    setData(res);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData(() => setIsLoading(false), showError);
  }, [showError]);

  const rows = {
    header: [t.name, t.description, t.questions],
    body: data?.filtered,
    bodyCount: data?.filtered,
    bodyParams: (val) => [
      { value: val.name },
      { value: val.description },
      { value: val?.questions?.length, isCollapsible: true },
    ],
    subheader: [t.question],
    subBody: (val) => val?.questions,
    subbodyParams: (val) => [val?.question],
  };

  const onSave = (evt) => {
    evt.preventDefault();

    const onUpdateResult = (res, err) => {
      if (!err) {
        fetchData((result) => {
          onFilterRecords(result, filters);
        }, showError);
        setOpen(false);
        setForm({});
      } else {
        showError(err);
      }
    };

    if (form.id) {
      if (
        pressedBtn === EDIT_CATEGORY ||
        pressedBtn === EDIT_CATEGORY_QUESTIONS
      ) {
        const data1 =
          pressedBtn === EDIT_CATEGORY &&
          new Promise((resolve, reject) => {
            updateCategory(form, (result, err) => {
              if (!err) {
                resolve(true);
              } else {
                reject(err);
              }
            });
          });
        const data2 = Promise.all([
          ...form?.questions?.map((it) => {
            return new Promise((resolve, reject) => {
              if (it.id) {
                updateQuestion(it, (result, err) => {
                  if (!err) {
                    resolve(true);
                  } else {
                    reject(err);
                  }
                });
              } else {
                createQuestion(it, (result, err) => {
                  if (!err) {
                    resolve(true);
                  } else {
                    reject(err);
                  }
                });
              }
            });
          }),
        ]);

        Promise.all([data1, data2])
          .then(() => {
            onUpdateResult();
          })
          .catch((err) => {
            showError(err);
          });
      } else if (pressedBtn === DELETE_CATEGORY) {
        deleteCategory(form, (result, err) => {
          onUpdateResult(result, err);
        });
      }
    } else {
      createCategory(form, (result, err) => {
        onUpdateResult(result, err);
      });
    }
  };

  const onChangeSearchTxt = (evt) => {
    const { value } = evt.target;

    setFilters({ ...filters, searchedValue: value });
  };

  const onClearSearchTxt = () => {
    const copyFilters = { ...filters, searchedValue: null };

    setFilters(copyFilters);
    onFilterRecords(data?.all, copyFilters);
  };

  const onKeyPressSearch = (evt) => {
    evt.preventDefault();

    if (evt.key === "Enter") {
      onFilterRecords(data?.all, filters);
      setPage(0);
    }
  };

  const onChangeRowsPerPage = (evt) => {
    setRowsPerPage(parseInt(evt.target.value, 10));
    setPage(0);
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onAddQuestionContainer = () => {
    let cpy = [...form.questions];
    cpy.unshift({ categoryId: form.id, answers: [INITIAL_STATE] });

    setForm({ ...form, questions: cpy });
  };

  const onRemoveQuestionContainer = (questionIdx) => {
    let cpy = [...form.questions];

    cpy[questionIdx] = { ...cpy[questionIdx], categoryId: null, deleted: true };

    setForm({ ...form, questions: cpy });
  };

  const onAddAnswerContainer = (questionIdx) => {
    let cpy = [...form.questions];
    cpy[questionIdx].answers.unshift({
      id: TEST_ID + (cpy[questionIdx].answers.length + 1),
    });

    setForm({ ...form, questions: cpy });
  };

  const onRemoveAnswerContainer = (questionIdx, answerIdx) => {
    let cpy = [...form.questions];

    if (cpy[questionIdx].answers?.length > 1) {
      cpy[questionIdx].answers.splice(answerIdx, 1);
    } else {
      cpy[questionIdx].answers[answerIdx] = { ...INITIAL_STATE };
    }

    setForm({ ...form, questions: cpy });
  };

  function onDragEnd(result, questionIdx) {
    let cpy = [...form.questions];
    if (!result.destination) {
      return;
    }

    const items = reorder(
      cpy[questionIdx].answers,
      result.source.index,
      result.destination.index
    );

    cpy[questionIdx].answers = [...items];

    setForm({ ...form, questions: cpy });
  }

  const onCollapse = (item) => {
    let cpy = { ...collapsible };
    cpy[item.id] = !cpy[item.id];

    setCollapsible(cpy);
  };

  const onChangeTab = (_, val) => {
    setTabValue(val);
  };

  let title, content;

  if (pressedBtn === ADD_CATEGORY) {
    title = t.add(t.category);
  } else if (pressedBtn === EDIT_CATEGORY) {
    title = t.edit(t.category);
  } else if (pressedBtn === DELETE_CATEGORY) {
    title = t.delete(t.category);
  } else if (pressedBtn === EDIT_CATEGORY_QUESTIONS) {
    title = t.edit(t.questions);
  }

  if (
    pressedBtn === ADD_CATEGORY ||
    pressedBtn === EDIT_CATEGORY ||
    pressedBtn === EDIT_CATEGORY_QUESTIONS
  ) {
    content = (
      <Box
        sx={{
          display: "grid",
          rowGap: "1rem",
        }}
      >
        {pressedBtn !== EDIT_CATEGORY_QUESTIONS &&
          [
            { label: t.name, name: "name" },
            { label: t.description, name: "description", multiline: true },
          ].map((item) => (
            <Fragment key={item.name}>
              <TextField
                id={`outlined-basic-${item.name}`}
                label={item.label}
                name={item.name}
                value={form[item.name] || ""}
                onChange={onChangeTxt}
                multiline={item.multiline}
              />
            </Fragment>
          ))}

        {(pressedBtn === EDIT_CATEGORY ||
          pressedBtn === EDIT_CATEGORY_QUESTIONS) && (
          <>
            {pressedBtn !== EDIT_CATEGORY_QUESTIONS && <Divider />}
            <div style={{ display: "grid", alignItems: "center" }}>
              <h4> {t.questions}:</h4>
              <Tabs
                value={tabValue}
                onChange={onChangeTab}
                aria-label="categories_questions tabs"
                sx={{ mb: 2 }}
              >
                {[
                  { id: 0, label: t.standard },
                  { id: 1, label: t.customized },
                ].map((tab, tabKey) => (
                  <Tab
                    key={tabKey}
                    label={tab.label}
                    id={`simple-tab-${tab.id}`}
                    aria-controls={`simple-tabpanel-${tab.id}`}
                  />
                ))}
              </Tabs>
              <IconButton
                onClick={onAddQuestionContainer}
                sx={{
                  ...styleIconBtn,
                  display: "flex",
                  marginLeft: ".5rem",
                  width: "fit-content",
                }}
              >
                <AddIcon sx={{ pointerEvents: "none" }} />
              </IconButton>
            </div>
            <Box
              sx={{
                display: "grid",
                rowGap: "1rem",
              }}
            >
              {form?.questions?.map(
                (currentQuestion, currentQuestionIdx) =>
                  !currentQuestion.deleted && (
                    <Box
                      key={currentQuestionIdx}
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2.5em",
                        gridColumnGap: "1rem",
                        border: "1px solid rgba(0, 0, 0, 0.12)",
                        padding: "0.5rem",
                      }}
                    >
                      {tabValue === 1 ? (
                        <Box
                          sx={{
                            display: "grid",
                            rowGap: "1rem",
                          }}
                        >
                          {[
                            { label: t.question, name: "question" },
                            {
                              select: true,
                              label: t.type,
                              options: INPUT_TYPES,
                              option: "name",
                              param: "type",
                              key: "name",
                            },
                            {
                              label: t.description,
                              name: "description",
                              multiline: true,
                            },
                          ].map((item, itemIdx) => {
                            const input = (currentItem) => (
                              <Fragment key={itemIdx}>
                                {!currentItem.select ? (
                                  <TextField
                                    id={`outlined-basic-${currentItem.name}`}
                                    label={currentItem.label}
                                    name={currentItem.name}
                                    value={
                                      currentQuestion?.[currentItem.name] || ""
                                    }
                                    onChange={(evt) =>
                                      onChangeQuestionTxt(
                                        evt,
                                        currentQuestionIdx
                                      )
                                    }
                                    multiline={currentItem.multiline}
                                  />
                                ) : (
                                  <Select
                                    options={currentItem.options}
                                    onChange={(evt, val) =>
                                      onSelectQuestionOption(
                                        evt,
                                        val?.[currentItem?.key],
                                        currentItem.param,
                                        currentQuestionIdx
                                      )
                                    }
                                    label={currentItem.label}
                                    item={currentItem.option}
                                    value={
                                      currentItem.options.find(
                                        (it) =>
                                          currentQuestion?.[
                                            currentItem.param
                                          ] === it[currentItem.key]
                                      ) || ""
                                    }
                                  />
                                )}
                              </Fragment>
                            );

                            return Array.isArray(item) ? (
                              <Box
                                sx={{ ...styleInputsWrapper(item) }}
                                key={itemIdx}
                              >
                                {item.map((item2, item2Idx) => (
                                  <Fragment key={item2Idx}>
                                    {input(item2)}
                                  </Fragment>
                                ))}
                              </Box>
                            ) : (
                              input(item)
                            );
                          })}
                          {(currentQuestion?.type === "SINGLE_SELECT" ||
                            currentQuestion?.type === "MULTI_SELECT") && (
                            <>
                              <Divider />
                              <DragDrop
                                droppableId={`categories_${currentQuestionIdx}`}
                                onDragEnd={(res) =>
                                  onDragEnd(res, currentQuestionIdx)
                                }
                                data={currentQuestion?.answers}
                                content={(answer, answerIdx) => (
                                  <>
                                    {[
                                      { label: t.answer, name: "answer" },
                                      {
                                        label: t.description,
                                        name: "description",
                                        multiline: true,
                                      },
                                    ].map((item, itIdx) => (
                                      <Fragment key={itIdx}>
                                        <TextField
                                          id={`outlined-basic-${item.name}`}
                                          label={item.label}
                                          name={item.name}
                                          value={answer[item.name] || ""}
                                          onChange={(evt) =>
                                            onChangeAnswerSubTxt(
                                              evt,
                                              currentQuestionIdx,
                                              answerIdx
                                            )
                                          }
                                          multiline={item.multiline}
                                        />
                                      </Fragment>
                                    ))}
                                    <IconButton
                                      onClick={() =>
                                        onRemoveAnswerContainer(
                                          currentQuestionIdx,
                                          answerIdx
                                        )
                                      }
                                      sx={{ ...styleIconBtn }}
                                    >
                                      <RemoveIcon
                                        sx={{ pointerEvents: "none" }}
                                      />
                                    </IconButton>
                                  </>
                                )}
                                droppableTopContent={
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginBottom: "1rem",
                                    }}
                                  >
                                    {t.answers}:
                                    <IconButton
                                      onClick={() =>
                                        onAddAnswerContainer(currentQuestionIdx)
                                      }
                                      sx={{
                                        ...styleIconBtn,
                                        marginLeft: "auto",
                                        display: "flex",
                                        width: "5em",
                                      }}
                                    >
                                      <AddIcon sx={{ pointerEvents: "none" }} />
                                    </IconButton>
                                  </div>
                                }
                              />
                            </>
                          )}
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "grid",
                            rowGap: "1rem",
                          }}
                        >
                          {[
                            {
                              select: true,
                              label: t.question,
                              options: questions,
                              option: "question",
                            },
                          ].map((item, itemIdx) => {
                            const input = (currentItem) => (
                              <Fragment key={itemIdx}>
                                <Select
                                  options={currentItem.options}
                                  onChange={(evt, val) =>
                                    onSelectQuestionOptionStandard(
                                      evt,
                                      val,
                                      currentQuestionIdx
                                    )
                                  }
                                  label={currentItem.label}
                                  item={currentItem.option}
                                  value={
                                    currentItem.options.find(
                                      (it) => currentQuestion?.id === it.id
                                    ) || ""
                                  }
                                />
                              </Fragment>
                            );

                            return input(item);
                          })}
                        </Box>
                      )}
                      <IconButton
                        onClick={() =>
                          onRemoveQuestionContainer(currentQuestionIdx)
                        }
                        sx={{ ...styleIconBtn, height: "fit-content" }}
                      >
                        <RemoveIcon sx={{ pointerEvents: "none" }} />
                      </IconButton>
                    </Box>
                  )
              )}
            </Box>
          </>
        )}
      </Box>
    );
  }

  return (
    <Box>
      <Modal
        {...{ open, title }}
        onSave={onSave}
        handleClose={onClose}
        closeLabel={t.close}
        saveLabel={t.save}
        {...{ content }}
      />

      <Button
        id={ADD_CATEGORY}
        sx={{ mb: 2 }}
        variant="contained"
        onClick={onOpen}
      >
        {t.addNew}
      </Button>
      <Table
        rows={{
          ...rows,
          body: data.filtered.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          ),
        }}
        selectedRow={(item) => item.id === form.id}
        actionBtns={(item) => (
          <>
            <IconButton
              sx={{ ...styleIconBtn, mr: 2 }}
              title={t.edit(t.category)}
              id={EDIT_CATEGORY}
              onClick={(evt) => onOpen(evt, item)}
            >
              <EditIcon sx={{ pointerEvents: "none " }} />
            </IconButton>
            <IconButton
              sx={{ ...styleIconBtn, mr: 2 }}
              title={t.edit(t.questions)}
              id={EDIT_CATEGORY_QUESTIONS}
              onClick={(evt) => onOpen(evt, item)}
            >
              <AddIcon sx={{ pointerEvents: "none " }} />
            </IconButton>
            <IconButton
              sx={styleIconBtn}
              title={t.delete(t.category)}
              id={DELETE_CATEGORY}
              onClick={(evt) => onOpen(evt, item)}
            >
              <DeleteIcon sx={{ pointerEvents: "none " }} />
            </IconButton>
          </>
        )}
        {...{ onChangeSearchTxt, onKeyPressSearch, onClearSearchTxt }}
        searchedValue={filters?.searchedValue}
        {...{
          page,
          rowsPerPage,
          onChangePage,
          onChangeRowsPerPage,
          collapsible,
          onCollapse,
          isLoading,
        }}
      />
    </Box>
  );
};

export default withErrorHandling(Categories);
