import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import withErrorHandling from "../../HOC/ErrorHandling";
import { useSharedProps } from "../../HOC/SharedProps";

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";

import t from "../../helpers/en";
import {
  getGitSecrets,
  setWizard1,
  setWizard2,
  setWizard3,
  getSecret,
  getConfigsAndSecrets,
  getRegistrySecrets,
} from "../../helpers/utils";
import {
  PRIVATE,
  PRIVATE_REGISTRY,
  GIT_NEW_CREDENTIALS,
  REGISTRY_NEW_CREDENTIALS,
  JSON_ADD_VARIABLES,
} from "../../helpers/constants";
import {
  changeTxt,
  selectOption,
  configsAndSecretsShowPwd,
  setConfigsAndSecrets,
  changeSubTxt,
  setJsonVariable2Table,
} from "../../helpers/selectors";

import Select from "../../components/Inputs/Select";
import SetConfigsAndSecrets from "../../components/SetConfigsAndSecrets";
import DbFields from "../../components/DbFileds";

const PUBLIC = "PUBLIC";
const PLATFORM_REGISTRY = "PLATFORM_REGISTRY";
const ACCESS_TYPE = [
  { id: PUBLIC, name: t.public },
  { id: PRIVATE, name: t.private },
];
const REGISTRY_ACCESS_TYPE = [
  { id: PLATFORM_REGISTRY, name: t.platformHostedRegistry },
  { id: PRIVATE_REGISTRY, name: t.private },
];

const INITIAL_STATE_FORM = {
  git_accessType: PUBLIC,
  registry_accessType: PLATFORM_REGISTRY,
  name: "system-default",
  deployAutomatically: true,
  db_deploy: false,
  variables: [{ key: null, value: null }],
  secrets: [{ key: null, value: null, isPwdVisible: false }],
  configs_values: [],
  tabVal: 0,
};

const sectionLabel = {
  fontSize: "0.875rem",
  opacity: 0.7,
};

const BasicsContent = ({ form, onChangeTxt }) => (
  <TextField
    id={`outlined-basic-name`}
    label={`${t.build} ${t.name}`}
    name="displayName"
    value={form?.displayName || ""}
    onChange={onChangeTxt}
  />
);

const GitComponent = ({
  form,
  onChangeTxt,
  onSelectOption,
  gitCredentials,
  showCredentials = true,
}) => (
  <>
    <TextField
      id="outlined-basic-git_repo"
      label={t.git + " " + t.repository}
      name="git_repo"
      value={form?.git_repo || ""}
      onChange={onChangeTxt}
    />
    <TextField
      id="outlined-basic-git_revison"
      label={t.git + " " + t.revision}
      name="git_revision"
      value={form?.git_revision || ""}
      onChange={onChangeTxt}
    />
    {showCredentials && (
      <Select
        options={ACCESS_TYPE}
        onChange={(evt, val) => onSelectOption(evt, val, "git_accessType")}
        label={t.git + " " + t.accessType}
        item="name"
        value={ACCESS_TYPE?.find((it) => it.id === form?.git_accessType) || ""}
      />
    )}

    {form?.git_accessType === PRIVATE && (
      <Select
        options={gitCredentials}
        onChange={(evt, val) => onSelectOption(evt, val, "git_secrets")}
        label={t.gitSecrets}
        item="name"
        value={gitCredentials?.find((it) => it.id === form?.git_secrets) || ""}
        specificDetails={{
          styles: {
            elemLi: (el) =>
              (el?.default || el?.id === GIT_NEW_CREDENTIALS) && {
                borderTop: "1px solid rgba(0,0,0,0.3)",
              },
          },
        }}
      />
    )}
    {form?.git_secrets === GIT_NEW_CREDENTIALS && (
      <Box sx={{ display: "grid", gridRowGap: "1rem", ml: "1.5rem" }}>
        <TextField
          id="outlined-basic-git_secrets_username"
          label={t.username}
          name="git_cred_username"
          value={form?.git_cred_username || ""}
          onChange={onChangeTxt}
        />
        <TextField
          id="outlined-basic-git_secrets_password"
          label={t.password}
          name="git_cred_password"
          value={form?.git_cred_password || ""}
          onChange={onChangeTxt}
        />
      </Box>
    )}
  </>
);

const RegistryComponent = ({
  registryCredentials,
  onSelectOption,
  form,
  onChangeTxt,
}) => (
  <>
    <Select
      options={REGISTRY_ACCESS_TYPE}
      onChange={(evt, val) => onSelectOption(evt, val, "registry_accessType")}
      label={`${t.registry} ${t.accessType}`}
      item="name"
      value={
        REGISTRY_ACCESS_TYPE?.find(
          (it) => it.id === form?.registry_accessType
        ) || ""
      }
    />
    {form?.registry_accessType === PRIVATE_REGISTRY && (
      <Select
        options={registryCredentials}
        onChange={(evt, val) => onSelectOption(evt, val, "registry_secrets")}
        label={t.registry}
        item="name"
        value={
          registryCredentials?.find((it) => it.id === form?.registry_secrets) ||
          ""
        }
        specificDetails={{
          styles: {
            elemLi: (el) =>
              (el?.default || el?.id === REGISTRY_NEW_CREDENTIALS) && {
                borderTop: "1px solid rgba(0,0,0,.3)",
              },
          },
        }}
      />
    )}
    {form?.registry_secrets === REGISTRY_NEW_CREDENTIALS && (
      <Box
        sx={{
          display: "grid",
          gridRowGap: "1rem",
          border: "1px solid rgba(0,0,0,0.2)",
          borderRadius: "0.4rem",
          p: "1rem",
        }}
      >
        <TextField
          id={`outlined-registry_name`}
          label={`${t.registry} ${t.name}`}
          name="registry_name"
          value={form?.registry_name || ""}
          onChange={onChangeTxt}
        />
        <TextField
          id="outlined-basic-registry_secrets_server"
          label={t.server}
          name="registry_cred_server"
          value={form?.registry_cred_server || ""}
          onChange={onChangeTxt}
        />
        <TextField
          id="outlined-basic-registry_secrets_username"
          label={t.username}
          name="registry_cred_username"
          value={form?.registry_cred_username || ""}
          onChange={onChangeTxt}
        />
        <TextField
          id="outlined-basic-registry_secrets_password"
          label={t.password}
          name="registry_cred_password"
          value={form?.registry_cred_password || ""}
          onChange={onChangeTxt}
        />
      </Box>
    )}
  </>
);

const ServiceComponent = ({ form, onChangeTxt, onCheckboxChange }) => (
  <>
    <FormControlLabel
      label={t.deployAutomatically}
      control={
        <Checkbox
          checked={form.deployAutomatically}
          onChange={onCheckboxChange}
          name="deployAutomatically"
        />
      }
    />
    {form.deployAutomatically && (
      <Box
        sx={{
          display: "grid",
          gridRowGap: "1rem",
          border: "1px solid rgba(0,0,0,0.2)",
          borderRadius: "0.4rem",
          p: "1rem",
        }}
      >
        <TextField
          id="outlined-basic-name"
          label={t.service + " " + t.name}
          name="service_name"
          value={form?.service_name || ""}
          onChange={onChangeTxt}
        />
        <TextField
          id="outlined-basic-container_port"
          label={t.containerPort}
          name="container_port"
          value={form?.container_port || ""}
          onChange={onChangeTxt}
        />
      </Box>
    )}
  </>
);

const Wizard = (props) => {
  const { showError } = props;

  const [form, setForm] = useState({
    ...INITIAL_STATE_FORM,
  });
  const [gitCredentials, setGitCredentials] = useState([]);
  const [registryCredentials, setRegistryCredentials] = useState({});
  const [configs, setConfigs] = useState([]);

  const { currentGroup } = useSharedProps();
  const { id } = useParams();
  const navigate = useNavigate();

  const fetchGitCredentials = (onError) => {
    getGitSecrets((result, errorMsg) => {
      if (result) {
        const updatedResult = [
          ...(Array.isArray(result)
            ? result.map((it) => ({
                git_cred_username: it?.data?.username,
                git_cred_password: it?.data?.password,
                id: it?.metadata?.name,
                name: it?.metadata?.displayName,
                default: Boolean(it?.metadata?.labels?.default),
              }))
            : []),
          { id: GIT_NEW_CREDENTIALS, name: t.newCredentials },
        ];

        return setGitCredentials(updatedResult);
      } else {
        onError(errorMsg ?? t.errorMsg);
      }
    });
  };

  const fetchRegistryCredential = (onError) => {
    getRegistrySecrets((result, errorMsg) => {
      if (result) {
        const updatedResult = [
          ...(Array.isArray(result)
            ? result.map((it) => ({
                registry_cred_server: it?.data?.server,
                registry_cred_username: it?.data?.username,
                registry_cred_password: it?.data?.password,
                id: it?.metadata?.name,
                name: it?.metadata?.displayName,
                default: Boolean(it?.metadata?.labels?.default),
              }))
            : []),
          { id: REGISTRY_NEW_CREDENTIALS, name: t.newRegistry },
        ];

        return setRegistryCredentials(updatedResult);
      } else {
        onError(errorMsg ?? t.errorMsg);
      }
    });
  };

  useEffect(() => {
    fetchGitCredentials(showError);
    fetchRegistryCredential(showError);
    getConfigsAndSecrets((result, err) => {
      if (Array.isArray(result)) {
        const updateResult = result.map((it) => {
          return {
            displayName: it?.displayName,
            name: it?.name,
          };
        });

        setConfigs(updateResult);
      } else {
        showError(err);
      }
    });
  }, [id, showError, currentGroup]);

  const onChangeTxt = (evt) => {
    const result = changeTxt(evt, form);

    setForm(result);
  };

  const onCheckboxChange = (evt) => {
    const { name, checked } = evt.target;

    setForm({ ...form, [name]: checked });
  };

  const onSelectOption = (_, value, param) => {
    let result = selectOption(_, value?.id ?? null, param, form);

    if (param === "git_secrets") {
      result = {
        ...result,
        git_cred_username: value?.git_cred_username,
        git_cred_password: value?.git_cred_password,
        git_cred_name: value?.id,
      };
    } else if (param === "registry_secrets") {
      result = {
        ...result,
        registry_cred_username: value?.registry_cred_username,
        registry_cred_password: value?.registry_cred_password,
        registry_cred_server: value?.registry_cred_server,
        registry_cred_name: value?.id,
      };
    }

    setForm(result);
  };

  const onSave = () => {
    const triggerUpdate = (result, errorMsg, cb) => {
      if (result) {
        setForm({ ...INITIAL_STATE_FORM });
        cb && cb();
      } else {
        showError(errorMsg ?? t.errorMsg);
      }
    };

    if (+id === 1) {
      setWizard1(form, (result, errorMsg) => {
        triggerUpdate(result, errorMsg);
      });
    } else if (+id === 2) {
      setWizard2(form, (result, errorMsg) => {
        triggerUpdate(result, errorMsg);
      });
    } else {
      setWizard3(form, (result, errorMsg) => {
        triggerUpdate(result, errorMsg, () => navigate("/kubernetes"));
      });
    }
  };

  const onChangeSubTxt = (evt, val, valIdx, param) => {
    const result = changeSubTxt(evt, form, valIdx, param);

    setForm(result);
  };

  const onOpenSecond = (evt, param) => {
    const { id } = evt.target;
    let cpy = { ...form };
    const envParams = form?.[param]?.reduce((acc, currentVal) => {
      return {
        ...acc,
        ...(currentVal.key ? { [currentVal.key]: currentVal.value } : {}),
      };
    }, {});

    cpy = {
      ...cpy,
      secondModal: {
        open: true,
        ...(id === JSON_ADD_VARIABLES
          ? { value: JSON.stringify(envParams, null, 2) }
          : {}),
      },
    };

    setForm(cpy);
  };

  const onHideModalSecond = () => {
    let cpy = { ...form };

    cpy.secondModal = null;

    setForm(cpy);
  };

  const onChangeTab = (_, val) => {
    setForm((prev) => ({ ...prev, tabVal: val }));
  };

  const onShowPwd = async (e, elem) => {
    const result = await configsAndSecretsShowPwd(e, {
      ...elem,
      showError,
      getSecret,
    });

    setForm(result);
  };

  const onSetConfigsAndSecrets = (evt, elem) => {
    const result = setConfigsAndSecrets(evt, elem);

    setForm(result);
  };

  const onChangeTxtJson = (e) => {
    const value = e.target.value;

    setForm((prev) => ({
      ...prev,
      ...{ secondModal: { ...prev.secondModal, value } },
    }));
  };

  const onSetJsonVariable2Table = () => {
    const result = setJsonVariable2Table(form);

    setForm(result);
  };

  const onSelectMultipleOptions = (event, values, param) => {
    let cpy = [...(form?.[param] ?? [])];
    cpy = values;
    setForm({ ...form, [param]: cpy });
  };

  let content;

  if (+id === 1) {
    content = (
      <>
        <Typography sx={sectionLabel}>{t.git}</Typography>
        <GitComponent
          {...{
            form,
            onChangeTxt,
            onSelectOption,
            gitCredentials,
            showCredentials: false,
          }}
        />
      </>
    );
  } else if (+id === 2) {
    content = (
      <>
        <Typography sx={sectionLabel}>{t.basics}</Typography>
        <BasicsContent {...{ form, onChangeTxt }} />
        <Typography sx={sectionLabel}>{t.git}</Typography>
        <GitComponent
          {...{ form, onChangeTxt, onSelectOption, gitCredentials }}
        />
      </>
    );
  } else if (+id === 3) {
    content = (
      <>
        <Typography sx={sectionLabel}>{t.basics}</Typography>
        <BasicsContent {...{ form, onChangeTxt }} />
        <Typography sx={sectionLabel}>{t.git}</Typography>
        <GitComponent
          {...{ form, onChangeTxt, onSelectOption, gitCredentials }}
        />
        <Typography sx={sectionLabel}>{t.registry}</Typography>
        <RegistryComponent
          {...{ registryCredentials, onSelectOption, form, onChangeTxt }}
        />
      </>
    );
  } else {
    content = (
      <>
        <Typography sx={sectionLabel}>{t.basics}</Typography>
        <BasicsContent {...{ form, onChangeTxt }} />
        <Typography sx={sectionLabel}>{t.git}</Typography>
        <GitComponent
          {...{ form, onChangeTxt, onSelectOption, gitCredentials }}
        />
        <Typography sx={sectionLabel}>{t.registry}</Typography>
        <RegistryComponent
          {...{ registryCredentials, onSelectOption, form, onChangeTxt }}
        />

        <Typography sx={sectionLabel}>
          {`${t.database} ${t.deployment}`}
        </Typography>
        <FormControlLabel
          label={t.deployNewDb}
          control={
            <Checkbox
              checked={form.db_deploy}
              onChange={onCheckboxChange}
              name="db_deploy"
            />
          }
        />
        {form.db_deploy && (
          <DbFields
            {...{ onSelectOption, onChangeTxt, form }}
            specificDetails={{
              styles: {
                border: "1px solid rgba(0,0,0,0.2)",
                borderRadius: "0.4rem",
                p: "1rem",
              },
            }}
          />
        )}
        <Typography sx={sectionLabel}>
          {t.service + " " + t.deployment}
        </Typography>
        <ServiceComponent {...{ form, onChangeTxt, onCheckboxChange }} />
        <Select // CONFIGS & SECRETS TOGETHER
          multiple
          options={configs}
          onChange={(evt, val) =>
            onSelectMultipleOptions(evt, val, "configs_values")
          }
          label={t.configsAndSecrets}
          item="displayName"
          value={form?.configs_values}
        />
        <SetConfigsAndSecrets
          {...{
            form,
            onChangeTab,
            onChangeSubTxt,
            onSetConfigsAndSecrets,
            onOpenSecond,
            onShowPwd,
            onChangeTxtJson,
            onSetJsonVariable2Table,
            onHideModalSecond,
          }}
        />
      </>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <div style={{ display: "grid", gridRowGap: "1rem" }}>{content}</div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          pt: 2,
          position: "sticky",
          bottom: 0,
          background: "#fff",
          padding: "1rem 1rem 0.5rem 1rem",
          margin: "1rem -1rem 0 -1rem",
          border: "1px solid rgba(0, 0, 0, .05)",
          borderBottom: "none",
          borderRadius: ".4rem",
          zIndex: 1,
        }}
      >
        <Button
          variant="contained"
          sx={{ textTransform: "none" }}
          onClick={onSave}
        >
          {t.save}
        </Button>
      </Box>
    </Box>
  );
};

export default withErrorHandling(Wizard);
