import React, { useState } from "react";

import { ReactComponent as Copy } from "../../assets/imgs/copy.svg";
import { ReactComponent as Copied } from "../../assets/imgs/copied.svg";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, IconButton, TablePagination, Tooltip } from "@mui/material";

import t from "../../helpers/en";

import Search from "../Inputs/SearchInput";
import CollapsibleTable from "./CollapsibleTable";
import Loading from "../Loading";

export default function BasicTable(props) {
  const {
    rows,
    actionBtns,
    subActionBtns,
    selectedRow,
    searchedValue,
    onChangeSearchTxt,
    onClearSearchTxt,
    onKeyPressSearch,
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    collapsible,
    onCollapse,
    isLoading,
    hideTableHeader,
    specificDetails,
  } = props;

  const [copied, setCopied] = useState({ status: false });

  const copy2Clipboard = async (el) => {
    try {
      await navigator.clipboard.writeText(el.value);
      setCopied((prevState) => ({
        ...prevState,
        ...el,
        status: true,
      }));
    } catch {}
  };

  return (
    <TableContainer component={Paper}>
      {!hideTableHeader && (
        <Box sx={{ display: "flex" }}>
          <TablePagination
            component="div"
            count={rows?.totalElementsForPagination ?? rows.bodyCount?.length}
            {...{ page, rowsPerPage }}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
          />
          {onChangeSearchTxt && (
            <Search
              className={{ marginLeft: "auto" }}
              {...{
                searchedValue,
                onChangeSearchTxt,
                onClearSearchTxt,
                onKeyPressSearch,
              }}
            />
          )}
        </Box>
      )}
      {specificDetails?.topElem}
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {rows.header.map((row, rowIdx) => (
              <TableCell sx={{ fontWeight: 600 }} key={rowIdx}>
                {row}
              </TableCell>
            ))}
            {actionBtns && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.body.length > 0 ? (
            rows.body?.map((row, rowIdx) => (
              <React.Fragment key={rowIdx}>
                <TableRow
                  selected={selectedRow(row)}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {rows?.bodyParams(row, rowIdx)?.map((it, itIdx) => {
                    let cellValue = it?.specialDetails?.hasTooltip ? (
                      <Tooltip title={it?.value} arrow>
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <span
                            style={{ ...(it?.specialDetails?.ellipsis ?? {}) }}
                          >
                            {it?.specialDetails?.isUrl ? (
                              <a
                                href={it?.value}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {it?.value}
                              </a>
                            ) : (
                              it?.value
                            )}
                          </span>
                          {it?.specialDetails?.ellipsis && it?.value && (
                            <IconButton
                              onClick={() =>
                                copy2Clipboard({
                                  value: it?.value,
                                  row,
                                  columnIdx: itIdx,
                                })
                              }
                            >
                              {copied.status &&
                              JSON.stringify(copied.row) ===
                                JSON.stringify(row) &&
                              itIdx === copied.columnIdx ? (
                                <Copied />
                              ) : (
                                <Copy />
                              )}
                            </IconButton>
                          )}
                        </span>
                      </Tooltip>
                    ) : (
                      it?.value
                    );

                    if (it?.isCollapsible) {
                      cellValue = (
                        <span style={{ display: "flex", alignItems: "center" }}>
                          {cellValue}
                          {cellValue ? (
                            <IconButton onClick={() => onCollapse(row)}>
                              {collapsible[row.id] ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          ) : (
                            ""
                          )}
                        </span>
                      );
                    }

                    return (
                      <TableCell
                        style={{
                          ...(it?.style ?? {}),
                        }}
                        key={itIdx}
                        component="th"
                        scope="row"
                      >
                        {cellValue}
                      </TableCell>
                    );
                  })}
                  {actionBtns && (
                    <TableCell>{actionBtns(row, rowIdx)}</TableCell>
                  )}
                </TableRow>
                {rows?.subBody && rows.subBody(row) && (
                  <CollapsibleTable
                    open={collapsible[row.id]}
                    data={rows.subBody(row)}
                    subheader={rows.subheader}
                    subbody={rows.subbodyParams}
                    {...{ subActionBtns }}
                  />
                )}
              </React.Fragment>
            ))
          ) : (
            <TableRow>
              <TableCell
                sx={{ textAlign: "center" }}
                colSpan={rows?.header?.length}
              >
                {!isLoading ? t.noResult : <Loading />}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
