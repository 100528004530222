import React, { useState, useEffect } from "react";

import withErrorHandling from "../../HOC/ErrorHandling";
import { useSharedProps } from "../../HOC/SharedProps";

import { Box } from "@mui/material";

import t from "../../helpers/en";
import { getk8sLogs } from "../../helpers/utils";

import Table from "../../components/Table/Table";

const rowsDetails = (data, totalElements) => ({
  header: [t.name, t.type, t.operation, t.createdBy, t.createdOn],
  body: data?.content,
  bodyCount: data?.content,
  totalElementsForPagination: data?.totalElements,
  bodyParams: (val) => [
    { value: val?.displayName },
    { value: val?.objectType },
    { value: val?.operationType },
    {
      value: val?.createdBy?.familyName
        ? `${val?.createdBy?.familyName} ${val?.createdBy?.givenName}`
        : val?.createdBy?.username,
    },
    { value: val?.createdOn },
  ],
});

const Logs = (props) => {
  const { showError } = props;

  const [data, setData] = useState({ content: [] });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);

  const { currentGroup } = useSharedProps();

  const fetchData = (pageNumber, pageSize, cb, onError) => {
    getk8sLogs({ pageNumber, pageSize }, (result, err) => {
      if (result) {
        setData({ ...result });
        cb && cb(result);
      } else {
        onError(err);
      }
    });
  };

  useEffect(() => {
    const callback = (cb) => {
      fetchData(page, rowsPerPage, cb, showError);
    };

    setIsLoading(true);
    callback(() => setIsLoading(false));
  }, [page, rowsPerPage, showError, currentGroup]);

  const onChangeRowsPerPage = (evt) => {
    setRowsPerPage(parseInt(evt.target.value, 10));
    setPage(0);
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  return (
    <Box>
      <Table
        rows={{
          ...rowsDetails(data),
        }}
        selectedRow={() => false}
        {...{
          page,
          rowsPerPage,
          onChangePage,
          onChangeRowsPerPage,
          isLoading,
        }}
      />
    </Box>
  );
};

export default withErrorHandling(Logs);
