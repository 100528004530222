import * as React from "react";
import { Outlet, useNavigate } from "react-router-dom";

import {
  Box,
  Paper,
  Toolbar,
  Container,
  AppBar,
  Typography,
} from "@mui/material";

export default function MinimalLayout(props) {
  const { className } = props;
  const navigate = useNavigate();

  const move2Home = () => {
    navigate("/health-checks");
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="absolute">
        <Toolbar>
          <Typography
            onClick={move2Home}
            component="h1"
            variant="h6"
            sx={{ flexGrow: 1, cursor: "pointer" }}
          >
            Clue 2 Solve
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container id="main" sx={{ ...className, mt: 4, mb: 4 }}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Outlet />
          </Paper>
        </Container>
      </Box>
    </Box>
  );
}
