export const styleIconBtn = {
  background: (theme) => theme.palette.primary.main,
  borderRadius: "0.5rem",
  color: "#fff",
  "&:hover": {
    background: (theme) => theme.palette.primary.main,
    opacity: 0.7,
  },
};

export const styleInputsWrapper = (items) => ({
  display: { xs: "contents", md: "grid" },
  gridTemplateColumns: "" + items?.map((it) => "1fr").join(" ") + "",
  gridColumnGap: "0.5rem",
});

export const styleEllipsis = {
  display: "block",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};
