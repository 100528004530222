import React from "react";
import { useDropzone } from "react-dropzone";

const dropzoneStyles = {
  border: "1px solid #cccccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
  marginTop: "1rem",
};

function Dropzone(props) {
  const { selectedFile, onDrop } = props;

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".csv",
    onDrop,
  });

  return (
    <div>
      <div {...getRootProps()} style={dropzoneStyles}>
        <input {...getInputProps()} />
        {selectedFile ? (
          <p>
            {selectedFile.name} - {selectedFile.size} bytes
          </p>
        ) : (
          <p>Drag 'n' drop a .csv file here, or click to select a .csv file</p>
        )}
      </div>
    </div>
  );
}

export default Dropzone;
