import React, { useState, useEffect } from "react";

import withErrorHandling from "../../HOC/ErrorHandling";
import { useSharedProps } from "../../HOC/SharedProps";

import { Box, Button, IconButton, TextField } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CodeIcon from "@mui/icons-material/Code";
import HistoryIcon from "@mui/icons-material/History";
import AutorenewIcon from "@mui/icons-material/Autorenew";

import { CopyBlock, dracula } from "react-code-blocks";

import t from "../../helpers/en";

import {
  EDIT_ITEM,
  DELETE_ITEM,
  JSON_ITEM,
  NEW_ITEM,
  ROWS_PARAMS_LOGS,
  PAGE_SIZE,
} from "../../helpers/constants";
import {
  deleteImage,
  getImages,
  newImage,
  updateImage,
  getServiceAccounts,
  getBuilders,
  getk8sLogs,
  forceRebuild,
} from "../../helpers/utils";
import {
  filterRecords,
  changeTxt,
  selectOption,
} from "../../helpers/selectors";

import Select from "../../components/Inputs/Select";
import Table from "../../components/Table/Table";
import Modal from "../../components/Modal/Modal";

import { styleIconBtn, styleEllipsis } from "../../GeneralStyles";

const AUDIT_LOGS = "AUDIT_LOGS";
const FORCE_BUILD = "FORCE_BUILD";

const rowsParams = (data) => ({
  header: [
    t.name,
    t.latestImage,
    t.generation,
    t.status,
    t.resourceVersion,
    t.createdOn,
  ],
  body: data,
  bodyCount: data,

  bodyParams: (val) => [
    { value: val?.metadata?.displayName },
    {
      value: val?.status?.latestImage,
      specialDetails: {
        hasTooltip: true,
        isUrl: true,
        ellipsis: { maxWidth: "10em", ...styleEllipsis },
      },
    },
    { value: val?.metadata?.generation },
    { value: val?.status?.conditions?.[0]?.status },
    { value: val?.metadata?.resourceVersion },
    { value: val?.metadata?.creationTimestamp },
  ],
});

const Images = (props) => {
  const { showError } = props;

  const [data, setData] = useState({
    data: [],
    filtered: [],
  });
  const [filters, setFilters] = useState({});
  const [form, setForm] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [buildersList, setBuildersList] = useState([]);
  const [serviceAccounts, setServiceAccounts] = useState([]);
  const [pressedBtn, setPressedBtn] = useState(null);

  const { currentGroup } = useSharedProps();

  const fetchData = (cb, onError) => {
    getImages(null, (result, err) => {
      if (!err) {
        setData({ data: result, filtered: result });

        cb && cb(result);
      } else {
        onError(err);
      }
    });
  };

  useEffect(() => {
    const callback = (cb) => {
      fetchData(cb, showError);

      getServiceAccounts((result, err) => {
        if (Array.isArray(result)) {
          const updateResult = result.map((it) => ({
            displayName: it?.metadata?.displayName,
            name: it?.metadata?.name,
          }));
          setServiceAccounts(updateResult);
        } else {
          showError(err);
        }
      });

      getBuilders((result, err) => {
        if (Array.isArray(result)) {
          const updateResult = result.map((it) => ({
            displayName: it?.metadata?.displayName,
            name: it?.metadata?.name,
          }));
          setBuildersList(updateResult);
        } else {
          showError(err);
        }
      });
    };

    setIsLoading(true);
    callback(() => setIsLoading(false));
  }, [showError, currentGroup]);

  const onOpen = (evt, row) => {
    const { id } = evt.target;
    let modalData = {};

    if (id === AUDIT_LOGS) {
      new Promise((resolve) => {
        getk8sLogs(
          {
            objectName: row?.metadata?.name,
            pageNumber: 0,
            pageSize: PAGE_SIZE,
          },
          (result) => {
            resolve({ ...row, logs: [...(result.content ?? [])] });
          }
        );
      }).then((res) => setForm(res));
    } else if (id === FORCE_BUILD) {
      forceRebuild(row?.metadata, (result, err) => {
        if (err) {
          showError(err);
        }
      });

      return;
    } else {
      modalData = {
        ...row,
        ...(id === EDIT_ITEM
          ? {
              displayName: row?.metadata?.displayName,
              name: row?.metadata?.name,
              tag: row?.spec?.tag,
              resourceVersion: row?.metadata?.resourceVersion,
              serviceAccountName: row?.spec?.serviceAccountName,
              builderName: row?.spec?.builder?.name,
              gitRepo: row?.spec?.source?.git?.url,
              gitRevision: row?.spec?.source?.git?.revision,
            }
          : {}),
      };

      setForm(modalData);
    }

    setPressedBtn(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setForm({});
  };

  const onDelete = (evt, row) => {
    deleteImage(row.metadata.name, (result, err) => {
      if (!err) {
        triggerUpdate();
      } else {
        showError(err);
      }
    });
  };

  const triggerUpdate = () => {
    fetchData((result) => {
      onFilterRecords(result, filters);
    }, showError);
    handleClose();
  };

  const onChangeRowsPerPage = (evt) => {
    setRowsPerPage(parseInt(evt.target.value, 10));
    setPage(0);
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onFilterRecords = (result, currentFilters) => {
    const res = filterRecords(result, currentFilters, (item) => [
      item.metadata?.displayName,
    ]);

    setData(res);
  };

  const onKeyPressSearch = (evt) => {
    evt.preventDefault();

    if (evt.key === "Enter") {
      onFilterRecords(data?.data, filters);
      setPage(0);
    }
  };

  const onChangeSearchTxt = (evt) => {
    const { value } = evt.target;

    setFilters({ ...filters, searchedValue: value });
  };

  const onClearSearchTxt = () => {
    const copyFilters = { ...filters, searchedValue: null };

    setFilters(copyFilters);
    onFilterRecords(data?.data, copyFilters);
  };

  const onChangeTxt = (evt) => {
    const result = changeTxt(evt, form);

    setForm(result);
  };

  const onSelectOption = (_, value, param) => {
    let result = selectOption(_, value, param, form);

    setForm(result);
  };

  const onSave = (action) => {
    action(form, (result, err) => {
      if (!err) {
        triggerUpdate();
      } else {
        showError(err);
      }
    });
  };

  let title, content, saveLabel, onConfirmModalAction;

  const formElement = () => (
    <Box sx={{ display: "grid", gridRowGap: "1rem" }}>
      <TextField
        id={`outlined-basic-displayName`}
        label={t.name}
        name="displayName"
        value={form?.displayName || ""}
        onChange={onChangeTxt}
        InputProps={{
          readOnly: !!form?.resourceVersion,
        }}
      />
      <TextField
        id={`outlined-basic-tag`}
        label={t.tag}
        name="tag"
        value={form?.tag || ""}
        onChange={onChangeTxt}
      />
      <Select
        options={serviceAccounts}
        onChange={(evt, val) =>
          onSelectOption(evt, val?.name, "serviceAccountName")
        }
        label={t.serviceAccount}
        item="displayName"
        value={
          serviceAccounts?.find((it) => it.name === form?.serviceAccountName) ||
          ""
        }
      />
      <Select
        options={buildersList}
        onChange={(evt, val) => onSelectOption(evt, val?.name, "builderName")}
        label={t.build}
        item="displayName"
        value={buildersList?.find((it) => it.name === form?.builderName) || ""}
      />
      <TextField
        id="outlined-basic-gitRepo"
        label={t.repository}
        name="gitRepo"
        value={form?.gitRepo || ""}
        onChange={onChangeTxt}
      />
      <TextField
        id="outlined-basic-gitRevision"
        label={t.revision}
        name="gitRevision"
        value={form?.gitRevision || ""}
        onChange={onChangeTxt}
      />
    </Box>
  );

  switch (pressedBtn) {
    case JSON_ITEM:
      title = t.json;
      content = (
        <Box>
          <CopyBlock
            customStyle={{ height: "550px", width: "100%", overflow: "scroll" }}
            text={JSON.stringify(form, null, 4)}
            language={"en"}
            showLineNumbers={true}
            theme={dracula}
          />
        </Box>
      );
      break;
    case NEW_ITEM:
      title = t.create;
      content = formElement();
      onConfirmModalAction = () => onSave(newImage);
      saveLabel = t.save;
      break;
    case EDIT_ITEM:
      title = t.update;
      content = formElement();
      onConfirmModalAction = () => onSave(updateImage);
      saveLabel = t.save;
      break;
    case DELETE_ITEM:
      title = t.delete(t.image);
      onConfirmModalAction = (e) => onDelete(e, form);
      saveLabel = t.confirm;
      break;
    case AUDIT_LOGS:
      title = t.logs;
      content = (
        <Table
          rows={{
            ...ROWS_PARAMS_LOGS(form.logs ?? []),
            body: form?.logs ?? [],
          }}
          selectedRow={() => false}
          hideTableHeader={true}
        />
      );
      break;
    default:
  }

  return (
    <Box>
      <Button
        sx={{ mb: 2 }}
        variant="contained"
        onClick={(evt) => onOpen(evt, null)}
        id={NEW_ITEM}
        disabled={currentGroup?.id && !currentGroup?.isContributor2Group}
      >
        {t.create}
      </Button>

      <Table
        rows={{
          ...rowsParams(data?.filtered),
          body: data?.filtered?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          ),
        }}
        selectedRow={(item) => item.id === form.id}
        {...{ onChangeSearchTxt, onKeyPressSearch, onClearSearchTxt }}
        searchedValue={filters?.searchedValue}
        {...{
          page,
          rowsPerPage,
          onChangePage,
          onChangeRowsPerPage,
          isLoading,
        }}
        actionBtns={(item) => (
          <>
            <IconButton
              sx={{ ...styleIconBtn, mr: 2 }}
              title={t.forceBuild}
              id={FORCE_BUILD}
              onClick={(evt) => onOpen(evt, item)}
              disabled={currentGroup?.id && !currentGroup?.isContributor2Group}
            >
              <AutorenewIcon sx={{ pointerEvents: "none" }} />
            </IconButton>
            <IconButton
              sx={{ ...styleIconBtn, mr: 2 }}
              title={t.json}
              id={JSON_ITEM}
              onClick={(evt) => onOpen(evt, item)}
            >
              <CodeIcon sx={{ pointerEvents: "none" }} />
            </IconButton>
            <IconButton
              sx={{ ...styleIconBtn, mr: 2 }}
              title={t.logs}
              id={AUDIT_LOGS}
              onClick={(evt) => onOpen(evt, item)}
            >
              <HistoryIcon sx={{ pointerEvents: "none" }} />
            </IconButton>
            {!item?.metadata?.labels?.default && (
              <>
                <IconButton
                  sx={{ ...styleIconBtn, mr: 2 }}
                  title={t.update}
                  id={EDIT_ITEM}
                  onClick={(evt) => onOpen(evt, item)}
                  disabled={
                    currentGroup?.id && !currentGroup?.isContributor2Group
                  }
                >
                  <EditIcon sx={{ pointerEvents: "none" }} />
                </IconButton>
                <IconButton
                  sx={styleIconBtn}
                  title={t.destroy}
                  id={DELETE_ITEM}
                  onClick={(evt) => onOpen(evt, item)}
                  disabled={
                    currentGroup?.id && !currentGroup?.isContributor2Group
                  }
                >
                  <DeleteIcon sx={{ pointerEvents: "none" }} />
                </IconButton>
              </>
            )}
          </>
        )}
      />

      <Modal
        {...{ open }}
        handleClose={handleClose}
        title={title}
        closeLabel={t.close}
        onSave={onConfirmModalAction}
        {...{ content, saveLabel }}
      />
    </Box>
  );
};

export default withErrorHandling(Images);
